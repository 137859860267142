import Cookies from "universal-cookie";
import {baseApi} from "services/base-api";
import {FaqUpdateParams, QuestionsAnswers} from "services/company/faq/faq.types";


const cookies = new Cookies();

const getToken = (): string => {
    const token = cookies.get('jwt_authorization');
    if (!token) {
        throw new Error('Токен авторизации не найден в куки');
    }
    return token;
}

export const getCompanyId = (): string => {
    const companyId = cookies.get('company_id');
    if (!companyId) {
        throw new Error('companyId не найден в куки');
    }
    return companyId;
}

export const faqService = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        faq: builder.query<QuestionsAnswers, void>({
            providesTags: ['Faq'],
            query: () => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    method: 'GET',
                    url: `/company/${companyId}/faq/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        faqAdd: builder.mutation<any, any>({
            invalidatesTags: ['Faq'],
            query: (body) => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    body,
                    method: 'POST',
                    url: `/company/${companyId}/faq/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        faqUpdate: builder.mutation<any, FaqUpdateParams>({
            invalidatesTags: ['Faq'],
            query: ({faqId, body}) => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    body,
                    method: 'PUT',
                    url: `/company/${companyId}/faq/${faqId}/`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),

        faqDelete: builder.mutation<any, { faqId: number, }>({
            invalidatesTags: ['Faq'],
            query: ({faqId}) => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    method: 'DELETE',
                    url: `/company/${companyId}/faq/${faqId}/`,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),

    }),
});

export const {
    useFaqQuery,
    useFaqAddMutation,
    useFaqUpdateMutation,
    useFaqDeleteMutation,
} = faqService;

