import React, {FC} from 'react';
import {
    DivText,
    DivWrapper,
    FormContainer,
    FormWrapper,
    StyledDivTitle
} from "../../../../../../account/login/account-form/form-login/style-form";
import {ReactComponent as СrossIcon} from '../../../../../../../../assets/main/form/cross.svg';
import {AdvertisementButton} from "../../../../../../account/advertisement/style-advertisement";
import {
    CloseButton
} from "../../../../../../feedback/feedback-block/feedback-information/delete-popup/form-delete/form";


interface PropsType {
    handleClosePopups: () => void;
}


export const DeleteBookedTerm : FC<PropsType> = ({handleClosePopups}) => {
    return (
        <FormWrapper>
            <DivWrapper>
                <CloseButton type="button" onClick={handleClosePopups}>
                    <СrossIcon/>
                </CloseButton>
                <StyledDivTitle>Usunąć zarezerwowane termin?</StyledDivTitle>
                <FormContainer>
                    <DivText>Ta czynność spowoduje usunięcie wszystkich danych</DivText>
                    <AdvertisementButton>POTWIERDZAĆ</AdvertisementButton>
                </FormContainer>
            </DivWrapper>
        </FormWrapper>
    );
};

