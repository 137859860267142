import React from 'react';
import {Container, LeftBlock, RightBlock} from "./style-footer-bottom";


export const FooterBottom = () => {
    return (
        <Container>
            <LeftBlock>
                <p>© 2022 AXEL MEDIA </p>
                <span>Regulamin | Polityka prywatności</span>
            </LeftBlock>

            <RightBlock>
                <ul>
                    <li><a href="#">Facebook</a></li>
                    <li><a href="#">Instagram</a></li>
                    <li><a href="#">Pinterest</a></li>
                    <li><a href="#">Google</a></li>
                </ul>
            </RightBlock>

        </Container>
    );
};
