import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 50px;
  @media (max-width: 768.98px) {
    gap: 30px;
    margin-bottom: 30px;
  }
`;

export const CalendarText = styled.div`
  color: #2E3B52;
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 120%;
  max-width: 690px;

  @media (max-width: 768.98px) {
    font-size: calc(20px + 2 * ((100vw - 480px) / 288));
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

export const CalendarDescription = styled.div`
  border-radius: 5px;
  background: #F5F9FF;
`;

export const Description = styled.div`
  display: flex;
  gap: 15px 45px;
  flex-wrap: wrap;
  padding: 25px 0 21px 30px;
  @media (max-width: 710.98px) {
    justify-content: space-between;
    gap: 15px 10px;
    padding: 10px;
  }
  @media (max-width: 548.98px) {
    justify-content: center;
    padding: 10px;
  }
`;

export const DescriptionItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 710.98px) {
    width: 226px;
  }

  svg {
    width: 40px;
    height: 40px;
  }

  p {
    font-family: Gilroy, sans-serif;
    color: #0C1215;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;