import React from 'react';
import {FooterTop} from "./footer-top/Footer-top";
import {FooterBottom} from "./footer-bottom/footer-bottom";
import {FooterWrap} from "../style-layout";

export const Footer = () => {
    return (
        <FooterWrap>
            <FooterTop/>
            <FooterBottom/>
        </FooterWrap>
    );
};

