import React from 'react';
import { Marker } from "@react-google-maps/api";
import LocationIcon from '../../../../../../assets/main/presentation/map/location.svg';

interface ICurrentLocationMarker {
    position: any;
}

export const CurrentLocationMarker = ({ position }: ICurrentLocationMarker) => {
    return <Marker position={position} icon={{ url: LocationIcon }} />;
};