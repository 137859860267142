import React from 'react';
import './App.css';
import {Router} from "router";


function App() {
    return <Router/>;
}

export default App;





