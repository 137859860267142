import styled from "styled-components";

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  
  svg {
    position: relative;
    top: -5px;
    left: 0;
  }
`;

export const TooltipContent = styled.div<{ isVisible: boolean }>`
  position: absolute;
  right: -138px;
  bottom: 30px;
  background: #FFF;
  width: 277px;
  border-radius: 20px;
  box-shadow: 0px 2px 6px 0px rgba(74, 74, 74, 0.15);
  color: rgba(12, 18, 21, 0.5);
  text-align: center;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.12px;
  padding: 10px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  pointer-events: ${props => (props.isVisible ? 'auto' : 'none')};
  transition: opacity 0.2s ease-in-out;
`;