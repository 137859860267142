import styled from "styled-components";


export const FullWrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`

export const WrapperPassword = styled.section`
    margin-bottom: 202px;
`

export const StyleWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: clip;
  position: relative;
`

export const MainWrapper = styled.main`
  flex: 1 1 auto;
  background-color: #ffffff;
  @media (max-width: 1000.98px) {
  }
`

export const OutletWrapper = styled.div<{ background: string, padding:string }>`
  padding-top: ${(props) => (props.padding ? `${props.padding}` : "120px")};;
  flex-grow: 1;
  background-color: ${(props) => (props.background ? `${props.background}` : "#fff")};;
  @media (max-width: 1000.98px) {
    background-color: #fff;
  }

  @media (max-width: 1000.98px) {
    width: 100%;
  }
`
export const OutletContainer = styled.div`
  padding-left: 30px;
  @media (max-width: 1000.98px) {
    padding-left: 0;
    background-color: white;
    
  }
`



export const StyledContainer = styled.div`
  max-width: 1470px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 0 15px;
`

export const NavigateMainWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1000.98px) {
    column-gap: 0;
  }
`
export const AdvertisentContent = styled.div`
    margin-right: 30px;
  @media (max-width: 1000.98px) {
    margin-right: 0;
  }
  
`
export const FooterWrap = styled.footer`
  @media (max-width: 1000.98px) {
    margin: 0 -15px;
  }

`
