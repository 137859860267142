import React from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderTitle } from './HeaderTitle';
import { HeaderTitleDown } from "./HeaderTitleDown";

type PageTitles = Record<string, string>;

const pageTitles: PageTitles = {
    '/': 'Twoje konto',
    '/advertising': 'Plan taryfowy i reklama',
    '/statistics': 'Statystyki',
    '/calendar': 'Kalendarz',
    '/feedback': 'Opinie',
    '/presentation': 'Prezentacja',
    '/ads': 'Moje ogłoszenia',
    '/banners': 'Banery i certyfikaty',
    '/information': 'Twoje informacje',
    '/adsplace': 'Moje ogłoszenia',
    '/password-change': 'Zmień hasło',
    '/confirmation': 'Zmień hasło',
};

const tabTitles: Record<string, string> = {
    '0': 'Statystyki',
    '1': 'Statystyki',
    '2': 'Statystyki',
    '3': 'Statystyki',
    '4': 'Statystyki',
    '5': 'Statystyki',
    '6': 'Statystyki',
    '7': 'Statystyki',
};

export const PageHeader = () => {
    const location = useLocation();
    const { pathname, search } = location;
    const fullPath = search ? `${pathname}${search}` : pathname;

    const tabParam = new URLSearchParams(search).get('tab');
    const title = pageTitles[fullPath] || (tabParam ? tabTitles[tabParam] : null) || 'Default Title';

    return <HeaderTitle title={title}/>;
};

export const PageHeaderDown = () => {
    const location = useLocation();
    const { pathname, search } = location;
    const fullPath = search ? `${pathname}${search}` : pathname;

    const tabParam = new URLSearchParams(search).get('tab');
    const title = pageTitles[fullPath] || (tabParam ? tabTitles[tabParam] : null) || 'Default Title';

    return <HeaderTitleDown title={title}/>;
};
