import React, {useEffect, useState} from 'react';
import {CloseOutlined} from '@ant-design/icons';
import type {UploadProps} from 'antd';
import {Flex, message, Upload} from 'antd';
import {
    FileIconContainer,
    FileUploadIcon,
    FileUploadText,
    LoaderBlock
} from "components/pages/presentation/form-presentation/upload-big-img/style-upload-big-img";
import {ReactComponent as CameraIcon} from 'assets/main/form/camera.svg';
import {Loader} from "components/pages/account/login/account-form/form-login/style-form";
import {
    ContactAvatar,
    ContactAvatarContainer,
    StyledAvatarFileContainer,
    StyledContactFileImg
} from "components/pages/informations/informations-forms/contact-person/contact-person-form/style-contact-person-form";


type FileType = Parameters<NonNullable<UploadProps['beforeUpload']>>[0];

const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

interface MultimediaContactProps {
    data: any;
    onImageChange?: (file: File) => void;
    loader:boolean;
}

export const MultimediaContact = ({data, onImageChange,loader}: MultimediaContactProps) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();


    console.log(imageUrl,'imageUrl')


    useEffect(() => {
        if (data?.profile_picture) {
            console.log('Setting image URL:', data.profile_picture); // Добавьте это
            setImageUrl(data?.profile_picture)
        }
    }, [data]);

    const handleChange: UploadProps['onChange'] = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
            const fileUrl = URL.createObjectURL(info.file.originFileObj as FileType);
            setImageUrl(fileUrl);
            if (onImageChange) {
                onImageChange(info.file.originFileObj as File);
            }
        }
    };


    const handleDelete = async () => {
        setImageUrl('')
    };


    return (
        <ContactAvatarContainer>
            {!imageUrl ? (
                <ContactAvatar>
                    <Flex gap="middle" wrap="wrap">
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            customRequest={({file, onSuccess}) => {
                                onSuccess?.({}, file as any);
                            }}
                        >
                        </Upload>
                    </Flex>
                    <FileIconContainer>
                        <FileUploadIcon>+</FileUploadIcon>
                        <FileUploadText>Dodaj zdjęcie</FileUploadText>
                    </FileIconContainer>
                    <CameraIcon/>
                </ContactAvatar>
            ) : (
                <StyledAvatarFileContainer>
                    {loading ? (
                        <LoaderBlock>
                            <Loader/>
                        </LoaderBlock>
                    ) : (
                        (data?.profile_picture || imageUrl) && (
                            <>
                                <StyledContactFileImg imageUrl={imageUrl}/>
                                <CloseOutlined
                                    onClick={handleDelete}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        zIndex: 3,
                                        cursor: 'pointer',
                                    }}
                                />
                            </>
                        )
                    )}
                </StyledAvatarFileContainer>
            )}
        </ContactAvatarContainer>
    );
};


//
// import React, {useEffect, useState} from 'react';
// import {CloseOutlined} from '@ant-design/icons';
// import type {UploadProps} from 'antd';
// import {Flex, message, Upload} from 'antd';
// import {
//     FileIconContainer,
//     FileUploadIcon,
//     FileUploadText,
//     LoaderBlock
// } from "components/pages/presentation/form-presentation/upload-big-img/style-upload-big-img";
// import {ReactComponent as CameraIcon} from 'assets/main/form/camera.svg';
// import {Loader} from "components/pages/account/login/account-form/form-login/style-form";
// import {
//     ContactAvatar,
//     ContactAvatarContainer,
//     StyledAvatarFileContainer,
//     StyledContactFileImg
// } from "components/pages/informations/informations-forms/contact-person/contact-person-form/style-contact-person-form";
//
//
// type FileType = Parameters<NonNullable<UploadProps['beforeUpload']>>[0];
//
// const beforeUpload = (file: FileType) => {
//     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//     if (!isJpgOrPng) {
//         message.error('You can only upload JPG/PNG file!');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//         message.error('Image must be smaller than 2MB!');
//     }
//     return isJpgOrPng && isLt2M;
// };
//
// interface MultimediaContactProps {
//     data: any;
//     onImageChange?: (file: File) => void;
//     isLoading: boolean
// }
//
// export const MultimediaContact = ({data, onImageChange}: MultimediaContactProps) => {
//     const [loading, setLoading] = useState(false);
//     const [imageUrl, setImageUrl] = useState<string>();
//
//
//     console.log(imageUrl,'imageUrl')
//
//
//     useEffect(() => {
//         if (data?.profile_picture) {
//             setImageUrl(data?.profile_picture)
//         }
//     }, [data]);
//
//     const handleChange: UploadProps['onChange'] = (info) => {
//         if (info.file.status === 'uploading') {
//             setLoading(true);
//             return;
//         }
//         if (info.file.status === 'done') {
//             setLoading(false);
//             const fileUrl = URL.createObjectURL(info.file.originFileObj as FileType);
//             setImageUrl(fileUrl);
//             if (onImageChange) {
//                 onImageChange(info.file.originFileObj as File);
//             }
//         }
//     };
//
//
//     const handleDelete = async () => {
//         setImageUrl('')
//     };
//
//
//     return (
//         <ContactAvatarContainer>
//             {!imageUrl ? (
//                 <ContactAvatar>
//                     <Flex gap="middle" wrap="wrap">
//                         <Upload
//                             name="avatar"
//                             listType="picture-card"
//                             className="avatar-uploader"
//                             showUploadList={false}
//                             beforeUpload={beforeUpload}
//                             onChange={handleChange}
//                             customRequest={({file, onSuccess}) => {
//                                 onSuccess?.({}, file as any);
//                             }}
//                         >
//                         </Upload>
//                     </Flex>
//                     <FileIconContainer>
//                         <FileUploadIcon>+</FileUploadIcon>
//                         <FileUploadText>Dodaj zdjęcie</FileUploadText>
//                     </FileIconContainer>
//                     <CameraIcon/>
//                 </ContactAvatar>
//             ) : (
//                 <StyledAvatarFileContainer>
//                     {loading ? (
//                         <LoaderBlock>
//                             <Loader/>
//                         </LoaderBlock>
//                     ) : (
//                         (data?.profile_picture || imageUrl) && (
//                             <>
//                                 <StyledContactFileImg imageUrl={imageUrl}/>
//                                 <CloseOutlined
//                                     onClick={handleDelete}
//                                     style={{
//                                         position: 'absolute',
//                                         top: 0,
//                                         right: 0,
//                                         zIndex: 3,
//                                         cursor: 'pointer',
//                                     }}
//                                 />
//                             </>
//                         )
//                     )}
//                 </StyledAvatarFileContainer>
//             )}
//         </ContactAvatarContainer>
//     );
// };
