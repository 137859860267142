import styled from "styled-components";

export const ArchiveWrap = styled.div``

export const ArchiveItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`
export const ArchiveBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    color: rgba(12, 18, 21, 0.50);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
  }

  span {
    color: #0C1215;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
