import styled from "styled-components";

export const PresentationNavigationWrap = styled.div`
  //background-color: #F5F9FF;
  //margin-bottom: 40px;
  position: relative;
  width: 100%;


  &:before {
    content: "";
    position: absolute;
    inset: 0 0 0px -30px;
    background-color: #F5F9FF;
    @media (max-width: 768.98px) {
      inset: 0 -30px 0px -30px;
    }
  }
`;

export const NavigationContainer = styled.div`
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;

  ul {
    display: flex;
    gap: 40px;
    padding: 15px 10px 21px 0;

    @media (max-width: 1001.98px) {
      justify-content: space-between;
    }
    
    li {
      color: #0C1215;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      z-index: 1;
      transition: all 0.3s;

      a {
        border-bottom: 1px dashed rgba(12, 18, 21, 0.60);
        transition: border-bottom 0.3s ease;
        display: inline-block;
        white-space: nowrap; 

        &:hover {
          border-bottom: none
        }
      }
    }
  }
`;
