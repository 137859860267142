import styled from "styled-components";
import {AddFreeDateForm} from "./AddFreeDateForm";

export const FreeDateButtonBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
`;

export const FreeDateLeftButton = styled.button`
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(210, 218, 227, 0.8);
  border-radius: 200px 100px 100px 0;
  font-family: 'Gilroy',sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
  padding: 12px 53.5px;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  @media (hover: hover) and (min-width: 1023.98px) {
    &:hover {
      background: rgba(255, 255, 255, 1);
      border-color: rgba(210, 218, 227, 1);
    }
  }
`;

export const FreeDateRightButton = styled.button`
  width: 100%;
  background: var(--blueButton);
  border-radius: 100px 200px 0 100px;
  font-family: 'Gilroy',sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 53.5px;
  color:var(--white);
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  @media (hover: hover) and (min-width: 1023.98px) {
    &:hover {
      background: var(--blueButtonHover);
    }
  }

  @media (max-width: 438.98px) {
    padding: 12px 45px;
  }
`;


export const FreeDateIcon = styled.div`
display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`


export const DeleteBlock = styled.button`

    p {
        color: #1056B2;
        font-family: Gilroy;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
        text-align: left;
    }
`
