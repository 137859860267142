import React from 'react';
import {
    CertificateBlock,
    CertificatesBlock, CertificatesEditButton,
    CertificatesImage,
    CertificatesItem, CertificatesItemIcons, CertificatesItemIconsBlock, CertificatesItemTitle,
    CertificatesPagesWrap, ItemIconsText
} from "./style-certificates-pages";
import {BannersPagesBlock, BannersPagesText, BannersPagesTitle} from "../banners-pages/style-banners-pages";
import Certyfikat1 from '../../../../assets/main/banners/Certyfikat1.png';
import Certyfikat2 from '../../../../assets/main/banners/Certyfikat2.png';
import Certyfikat3 from '../../../../assets/main/banners/Certyfikat3.png';
import {ReactComponent as EditIcon} from '../../../../assets/main/presentation/edit.svg';
import {ReactComponent as DownloadIcon} from '../../../../assets/main/banners/download.svg';
import {ReactComponent as PrinterIcon} from '../../../../assets/main/banners/printer.svg';



export const CertificatesPages = () => {
    return (
        <CertificatesPagesWrap>
            <BannersPagesTitle>Certyfikaty</BannersPagesTitle>
            <BannersPagesBlock>
                <BannersPagesText>
                    Redakcja GdzieWesele.pl ma dla Państwa nagrodę w postaci certyfikatu polecającego Państwa usługi
                    przez nasz serwis. Taki certyfikat to wizualne potwierdzenie jakości wykonywanych przez Państwa
                    usług. Przeznaczony jest dla firm, które świadczą najlepsze i godne polecenia usługi weselne.
                    Zachęcamy do pobrania certyfikatu i umieszczenia go na Państwa stronie www oraz social mediach —
                    dzięki temu promocja firmy będzie jeszcze bardziej wzmocniona.
                </BannersPagesText>
            </BannersPagesBlock>
            <CertificatesBlock>

                <CertificatesItem>
                    <CertificatesImage>
                        <img src={Certyfikat1} alt="Certyfikat 1"/>
                    </CertificatesImage>
                    <CertificatesItemTitle>
                        Certyfikat 1
                    </CertificatesItemTitle>
                    <CertificatesItemIconsBlock>
                        <CertificatesItemIcons>
                            <DownloadIcon/>
                            <ItemIconsText>
                                Pobierz SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                        <CertificatesItemIcons>
                            <PrinterIcon/>
                            <ItemIconsText>
                                Wydrukować
                            </ItemIconsText>
                        </CertificatesItemIcons>
                    </CertificatesItemIconsBlock>
                    <CertificateBlock>
                        <CertificatesEditButton> <EditIcon/><span>Edytuj</span></CertificatesEditButton>
                    </CertificateBlock>

                </CertificatesItem>

                <CertificatesItem>

                    <CertificatesImage>
                        <img src={Certyfikat2} alt="Certyfikat 2"/>
                    </CertificatesImage>
                    <CertificatesItemTitle>
                        Certyfikat 2
                    </CertificatesItemTitle>
                    <CertificatesItemIconsBlock>
                        <CertificatesItemIcons>
                            <DownloadIcon/>
                            <ItemIconsText>
                                Pobierz SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                        <CertificatesItemIcons>
                            <PrinterIcon/>
                            <ItemIconsText>
                                Wydrukować
                            </ItemIconsText>
                        </CertificatesItemIcons>
                    </CertificatesItemIconsBlock>
                    <CertificateBlock>
                        <CertificatesEditButton> <EditIcon/><span>Edytuj</span></CertificatesEditButton>
                    </CertificateBlock>

                </CertificatesItem>

                <CertificatesItem>
                    <CertificatesImage>
                        <img src={Certyfikat3} alt="Certyfikat 3"/>
                    </CertificatesImage>
                    <CertificatesItemTitle>
                        Certyfikat 3
                    </CertificatesItemTitle>
                    <CertificatesItemIconsBlock>

                        <CertificatesItemIcons>
                            <DownloadIcon/>
                            <ItemIconsText>
                                Pobierz SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                        <CertificatesItemIcons>
                            <PrinterIcon/>
                            <ItemIconsText>
                                Wydrukować
                            </ItemIconsText>
                        </CertificatesItemIcons>

                    </CertificatesItemIconsBlock>

                    <CertificateBlock>
                        <CertificatesEditButton> <EditIcon/><span>Edytuj</span></CertificatesEditButton>
                    </CertificateBlock>

                </CertificatesItem>

            </CertificatesBlock>

        </CertificatesPagesWrap>
    );
};

