import styled from "styled-components";

export const AutocompleteWrap = styled.div`

    input {
        width: 100%;
        height: 48px;
        border-radius: 5px;
        border: 0.6px solid rgba(12, 18, 21, 0.2);
        background: rgb(255, 255, 255);
        color: rgb(12, 18, 21);
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.8;
        padding: 0px 12px;
        transition: width 0.3s ease 0s;
        margin-bottom: 18px;
    }
`;
