import styled from "styled-components";

export const FormDateWrap = styled.div`
  border-radius: 5px;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-flow: dense;
  justify-content: center;
  margin-top: 30px;
  position: relative;
  padding: 15px 20px 30px 20px;
  
`