import React, {useState} from 'react';

import {AdsPinterPopup} from "./ads-prints-popups/AdsPrintsPopups";
import {PointsWrap, StyledPointsIcon} from "../style-ads";



interface IExpensesId {
    expensesId: string;
}

export const AdsPoints = ({ expensesId }: IExpensesId) => {
    const [isOpen, setIsOpen] = useState(false); // State to manage popup visibility

    const handleTogglePopup = () => {
        setIsOpen(!isOpen); // Toggle the popup visibility
    };

    return (
        <PointsWrap>
            <StyledPointsIcon onClick={handleTogglePopup} />
            {isOpen && <AdsPinterPopup expensesId={expensesId} />}
        </PointsWrap>
    );
};