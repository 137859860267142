import React, {useState} from "react";
import {TooltipContent, TooltipWrapper} from "./style-tooltips";
import { ReactComponent as TooltipsIcon } from '../../assets/main/form/tooltips.svg';

interface TooltipProps {
    title: string;
    children: React.ReactNode;
}

export const Tooltips: React.FC<TooltipProps> = ({ title, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleMouseEnter = () => {
        setIsVisible(true);
    };

    const handleMouseLeave = () => {
        setIsVisible(false);
    };

    return (
        <TooltipWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            <TooltipContent isVisible={isVisible}>{title}</TooltipContent>
        </TooltipWrapper>
    );
};