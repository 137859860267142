import React from "react";
import usePopup from "../../../../../../common/hook/use-popup";





interface IExpensesId {
    expensesId: string;
    show:boolean
}

export const AdsEdit = ({expensesId,show}: IExpensesId) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    return (
        <>
            {/*<PointsTitle onClick={onClickHandler}>{!show && 'Edytuj' }</PointsTitle>*/}
            {/*/!*{showPopup && <FormFavorite handleClosePopup={handleClosePopup}/>}*!/*/}
        </>
    );
};

