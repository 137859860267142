import styled from "styled-components";



export const FormPresentationWrap = styled.form`
    display: flex;
    flex-direction: column;
    //gap: 80px;
    //
    //@media (max-width: 768.98px) {
    //    gap: 60px;
    //}

`;
export const FormMultimediaWrap = styled.form`
    //padding-top: 50px;
    //
    //@media (max-width: 768.98px) {
    //    padding-top: 30px;
    //}

`;

export const BlockWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    max-width: 852px;
    .ant-input {
        color: rgb(12, 18, 21);
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.8;
        padding: 12px 12px;
        transition: width 0.3s ease 0s;
    }

    .ant-input:hover,
    .ant-input:focus {
        border-color: rgba(12, 18, 21, 0.20);
        box-shadow: none; /* Отменяем тень при фокусе */
    }

    /* Отменяем стили при hover и focus для компонента TextArea из Ant Design */

    .ant-input-textarea:hover,
    .ant-input-textarea:focus {
        border-color: rgba(12, 18, 21, 0.20);
        box-shadow: none; 
    }
`;


export const FormBlock = styled.section`
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 80px;
    @media (max-width: 768.98px) {
         padding-top: 65px;
    }
`;

export const FormTitle = styled.div`
    font-family: Gilroy, sans-serif;
    //font-weight: 400;
    font-weight: 500;
    font-size: 22px;
    line-height: 26.4px;
    color: #0C1215;
    margin-bottom: 2px;
`;
export const PhotoBlock = styled.div`
    display: flex;
    gap: 50px;

    @media (max-width: 1144.98px) {
        gap: 30px;
    }
    @media (max-width: 876.98px) {
        width: 100%;
        flex-direction: column;
        justify-content: center;
    }

`;

export const UploadImgWrapLeft = styled.div`
    width: 100%;
    max-width: 513px;
    @media (max-width: 425.98px) {
        max-width: 395px;
    }

`;
export const UploadImgWrapRight = styled.div`
    width: 100%;
    max-width: 280px;
    @media (max-width: 425.98px) {
        max-width: 395px;
    }
`;


export const CheckboxBlock = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    max-width: 505px;
    width: 100%;
`;

export const CheckboxBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;

    .ant-checkbox-wrapper .ant-checkbox-inner {
        color: var(--black, #0C1215);
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
        left: 0.4em;
        top: 3px;
        width: 0.25em;
        height: 0.5em;
        border: solid #1056B2;
        border-width: 0 0.15em 0.15em 0;
        transform: rotate(45deg);
    }


    .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background-color: #ffffff;
        border-color: transparent;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ffffff;
    }

    .ant-checkbox+span {
        font-family: Gilroy,sans-serif;
        font-weight:400;
        font-size: 16px;
        line-height: 22.4px;
        color:#0C1215;
        opacity: 80%;
    ;
    }
`;

export const InputBlock = styled.div`
    display: flex;
    gap: 20px;

    @media (max-width: 480.98px) {
        flex-direction: column;
        gap: 18px;
    }  
    //@media (max-width: 425.98px) {
    //    flex-direction: column;
    //    gap: 18px;
    //}
`;
export const InputBlockWrap = styled.div`
    width: 100%;
    max-width: 416px;
    display: flex;
    flex-direction: column;
    gap: 18px;

    @media (max-width: 480.98px) {
        max-width: 100%;
    }
    
    @media (max-width: 480.98px) {
        flex-direction: column;
    }
`;

export const InputItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 416px;


    @media (max-width: 480.98px) {
        max-width: 100%;
    }

    &&& {
        .ant-select-dropdown {
            background-color: red;
        }
    }

    //input {
    //    width: 100%;
    //    height: 48px;
    //    border-radius: 5px;
    //    border: 0.6px solid rgba(12, 18, 21, 0.20);
    //    background: #fff;
    //    color: #0c1215;
    //    font-family: Gilroy;s
    //    font-size: 16px;
    //    font-style: normal;s
    //    font-weight: 500;
    //    line-height: normal;
    //    opacity: 0.8;
    //    padding: 0 12px;
    //    transition: width 0.3s ease;
    //}

    //label {
    //    color: var(--black, #0c1215);
    //    font-family: Gilroy;
    //    font-size: 14px;
    //    font-style: normal;
    //    font-weight: 500;
    //    line-height: normal;
    //    opacity: 0.5;
    //}


    .ant-select-selector {
        height: 48px !important;
        border-radius: 5px;
        border: 0.6px solid rgba(12, 18, 21, 0.20);
        background: #fff;
        color: #0c1215;
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.8;
        padding: 0 12px;
        transition: width 0.3s ease;


        & .ant-select-selection-item,
        & .ant-select-selection-placeholder {
            padding: 0;
            line-height: 48px;
            transition: all 0.3s, visibility 0s;
        }


        & .ant-select-arrow {
            top: calc(50% - 7px);
        }

    }

    .rc-virtual-list-holder-inner {
        border-radius: 5px;
        border: none;
        background: rgba(16, 86, 178, .95);
    }


    @media (max-width: 1131.98px) {
        input {
            max-width: 350px;
        }
    }


    @media screen and (max-width: 1002px) {
        input {
            width: 100%;
            max-width: 100%;
        }
    }


`;


export const PhotoLabel = styled.label`
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #1056b2;
    text-decoration-line: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
        color: #0e408f;
        text-decoration-line: none;
    }
`;


export const QuestionBlock = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 18px;
    width: 100%;

    @media (max-width: 468.98px) {
        flex-direction: column;
    }
`;

export const QuestionItem = styled.div`
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 5px;
    background: #f5f9ff;

    @media (max-width: 468.98px) {
        padding: 20px 0;
    }





`;

export const CloseIconWrapper = styled.div`
    margin-left: auto;
    cursor: pointer;
`;

export const QuestionTextArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 395px;

    textarea {
        width: 100%;
        min-width: 0;
        border-radius: 5px;
        border: 1px solid #d2dae3;
        background: #fff;
        resize: vertical;
        transition: width 0.3s ease;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    label {
        color: var(--black, #0c1215);
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`;
export const QuestionButton = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 463.98px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const QuestionCancel = styled.button`
    display: flex;
    width: 200px;
    padding: 12px 30px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 200px 100px 100px 0px;
    border: 1px solid #d2dae3;
    opacity: 0.8;
    background: var(--white, #fff);
    color: var(--black, #0c1215);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    transition: border-color 0.3s ease, opacity 0.3s ease;
    @media (max-width: 463.98px) {
        width: 100%;
    }

    &:hover {
        border-color: #a5adbb;
        opacity: 1;
    }
`;


export const SubmitWrap = styled.div`
    padding-top: 50px;
    @media (max-width: 451.98px) {

    }
`;

export const SubmitBlock = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
    border-top: 1px solid #D2DAE3;
    margin-bottom: 30px;
    @media (max-width: 768.98px) {
        margin-bottom: 20px;
    }
    
    //margin-bottom: 120px;
    //@media (max-width: 768.98px) {
    //    margin-bottom: 20px;
    //}
    @media (max-width: 451.98px) {
        flex-direction: column-reverse;
        gap: 20px;
    }
`;


export const ReturnButton = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;

    svg {
        width: 17px;
        height: 17px;
    }

    span {
        color: var(--black, #0C1215);
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    }

    @media (max-width: 451.98px) {
        align-self: flex-start;
    }
`;

export const QuestionSubmit = styled.button`
    display: flex;
    width: 200px;
    padding: 12px 30px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 200px 100px 100px 0px;
    background: #1056B2;
    color: var(--white, #FFF);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    @media (max-width: 449.98px) {
        width: 100%;
    }

    &:hover {
        background: #0e467f;
    }
`;
