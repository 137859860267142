import styled from "styled-components";
import {ReactComponent as Points} from '../../../assets/main/abs/points.svg';
import {Input} from "antd";


interface CalendarTextProps {
    transform?: boolean;
}

export const PointsWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #0C1215;
`
export const StyledPointsIcon = styled(Points)`
  width: 20px;
  opacity: 1;
  transition: all 0.3s ease-out;
  cursor: pointer;


  &:hover {
    opacity: 0.5;
  }
`;

export const AdsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const AdsButton = styled.div`
margin-bottom: 100px;
`


export const CalendarButton = styled.button`
  margin-top: 30px;
  width: 100%;
  background:  #1056B2;
  border-radius: 208px 100px 100px 0;
  padding: 12px 30px;
  gap: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  cursor: pointer;
  max-width: 292px;
  @media (max-width: 425.98px) {
    max-width: 100%;
  }
  &:hover {
    background: linear-gradient(90deg, #1056B2 0%, #2779E4 100%);
  }
`;

export const CalendarText = styled.span<CalendarTextProps>`
    font-family: 'Gilroy', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18.56px;
    text-transform: ${props => props.transform ? 'uppercase' : 'none'};
    letter-spacing: 1%;
`;

export const InspirationsLink = styled.div`
  display: flex;
  justify-content: right;
  padding-bottom: 30px;
`

export const InfoContentBlock = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  max-width: 230px;
  border-bottom: 1px dashed rgba(16, 86, 178, 0.6);

  &:hover {
    border-bottom: none; 
    padding-bottom: 1px;

  }

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    color: #1056B2;

    @media (max-width: 469.98px) {
      font-size: 14px;
    }
  }
`;

export const StyledAdsTitle = styled.p`
  color:  #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.22px;
  padding-top: 60px;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    padding: 0 15px;
  }
`;

export const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0c1215;
  opacity: 0.5;
`;

export const StyledTextArea = styled(Input.TextArea)`
  font-size: 16px;
  line-height: 46px;
  color: rgba(12, 18, 21, 0.8);
  height: 48px;
  padding: 8px 20px;
  background: #fff;
  border: 1px solid rgba(12, 18, 21, 0.2);
  border-radius: 5px;



  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    width: 416px;
  }

  //&:hover {
  //  border-color: #007aff;
  //}
  //
  //&:focus {
  //  border-color: #007aff;
  //  outline: none;
  //  box-shadow: 0 0 3px #007aff;
  //}
`;


export const PointsAdsPopupWrap = styled.div`
  position: absolute;
  right: -43px;
  top: -63px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 40, 99, 0.15);
  border-radius: 10px;
  padding: 10px;
  z-index: 5;

  p {
    cursor: pointer;
  }
`
