import React, {FC, useEffect, useMemo} from 'react';
import {BlockWrap, InputItem, QuestionSubmit} from "../../../style-form-presentation";
import {useFaqAddMutation, useFaqDeleteMutation, useFaqUpdateMutation} from "services/company/faq/faq.service";
import {SubmitHandler, useForm} from "react-hook-form";
import {DeleteBlock} from "components/pages/account/forms/add-free-date-form/style-add-free-date-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {FaqFormValues, faqSchema} from "common/validation/faq/faqShema";
import {useAutoResizeTextarea} from "common/hook/useAutoResizeTextarea";
import {FaqFormData, FaqItem} from "services/company/faq/faq.types";
import {EditButton} from "../../style-faq";
import {
    CloseButton,
    FormContainer,
    StyledTitle,
    TextAreaBlock,
    WrapButtonBlock
} from "../../../../../feedback/feedback-block/feedback-information/delete-popup/form-delete/form";
import {
    ErrorText,
    FormWrapper,
    Loader,
    StyledLabel,
    StyledTextArea
} from "../../../../../account/login/account-form/form-login/style-form";

import {ReactComponent as CancellationIcon} from '../../../../../../../assets/main/form/сancellation.svg';
import {ReactComponent as CheckIcon} from '../../../../../../../assets/main/presentation/check.svg';
import {ReactComponent as CloseIcon} from '../../../../../../../assets/main/feedback/crose.svg';




type PropsType = {
    handleClosePopups: () => void;
    faqItem?: FaqItem | null;
};


export const FormFaq: FC<PropsType> = ({handleClosePopups, faqItem}) => {
    const [faqAdd, {isLoading: faqAddIsLoading}] = useFaqAddMutation();
    const [faqUpdate, {isLoading: faqUpdateIsLoading}] = useFaqUpdateMutation();
    const [faqDelete] = useFaqDeleteMutation();
    const isLoading = useMemo(() => faqUpdateIsLoading || faqAddIsLoading, [faqUpdateIsLoading, faqAddIsLoading]);

    useAutoResizeTextarea();

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
        reset,
        setError,
    } = useForm<FaqFormValues>({
        resolver: zodResolver(faqSchema),
        defaultValues: {
            question: '',
            answer: '',
        },
    });

    useEffect(() => {
        if (faqItem) {
            setValue('question', faqItem.question);
            setValue('answer', faqItem.answer);
        } else {
            setValue('question', '');
            setValue('answer', '');
        }
    }, [faqItem]);

    const handleDelete  = async () => {
        if (faqItem) {
            try {
                const faqId = faqItem.id;
                await faqDelete({faqId});
                handleClosePopups()
            } catch (error) {
                console.error('Error deleting FAQ:', error);
            }
        }
    };


    useEffect(() => {
        if (errors.answer || errors.question) {
            setError("answer", {
                type: "manual",
                message: ""
            });
            setError("question", {
                type: "manual",
                message: ""
            });
        }
    }, [handleClosePopups]);


    const handleFormSubmit: SubmitHandler<FaqFormData> = async (data: FaqFormData) => {
        try {
            if (faqItem) {
                const faqId = faqItem.id;
                await faqUpdate({faqId, body: data});
            } else {
                await faqAdd(data);
            }
            reset();
            handleClosePopups();
        } catch (error) {
            console.error('Error:', error);
        }
    };




    return (
        <FormWrapper onClick={(e) => e.stopPropagation()}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <CloseButton
                    type="button"
                    onClick={() =>
                        handleClosePopups()}
                >
                    <CloseIcon/>
                </CloseButton>
                <StyledTitle>{faqItem ? 'Edytuj pytanie' : 'Dodaj pytanie'}</StyledTitle>
                <FormContainer>
                    <BlockWrap>
                        <TextAreaBlock>
                            <InputItem>
                                <StyledLabel
                                    error={errors.question?.message}
                                    htmlFor="question"
                                >Popularne pytanie</StyledLabel>
                                <StyledTextArea
                                    error={errors.question?.message}
                                    id="question"
                                    {...register('question')}
                                />
                                {(errors.question?.message) && (
                                    <ErrorText>{errors.question?.message}</ErrorText>
                                )}
                            </InputItem>
                            <InputItem>
                                <StyledLabel
                                    error={errors.answer?.message}
                                    htmlFor="answer"
                                >Odpowiedź</StyledLabel>
                                <StyledTextArea
                                    error={errors.answer?.message}
                                    id="answer"
                                    {...register('answer')}
                                />
                                {(errors.answer?.message) && (
                                    <ErrorText>{errors.answer?.message}</ErrorText>
                                )}
                            </InputItem>
                        </TextAreaBlock>
                    </BlockWrap>
                    <WrapButtonBlock>
                        <EditButton
                            type='button'
                            onClick={() => handleClosePopups()}
                        >
                            <CancellationIcon/>
                            <span>Anuluj</span>
                        </EditButton>
                        <QuestionSubmit
                            disabled={isLoading}
                            type="submit"
                        >
                            {isLoading ? <Loader/> : <CheckIcon/>}
                            <span>Zapisz</span>
                        </QuestionSubmit>
                    </WrapButtonBlock>
                    {faqItem && (
                        <DeleteBlock onClick={handleDelete}>
                            <p>Usuń</p>
                        </DeleteBlock>
                    )}
                </FormContainer>
            </form>
        </FormWrapper>
    );
};

