import React, {useState} from 'react';
import {
    BlockInfo,
    Checkbox,
    CompleteContainer,
    CompleteIcons,
    CompleteInfo,
    CompleteItem,
    CompleteItemHeader, CompleteItemRight,
    CompleteItems,
    CompletePrise, Container, DropdownMenu, MenuItem,
    PaymentWrap, PlusButton,
    Table,
    TableActive,
    TableBlock, TableBlocks,
    TableCell,
    TableContainer,
    TableHeader,
    TableName,
    TableRow,
} from "./style-payment";
import visa from '../../../../assets/main/advertising/Visa.png';
import mastercard from '../../../../assets/main/advertising/Mastercard.png';
import applePay from '../../../../assets/main/advertising/ApplePay.png';
import googlePay from '../../../../assets/main/advertising/GooglePay.png';
import {AdvertisementButton} from "../../account/advertisement/style-advertisement";
import {Selector} from "./selector/Selector";
import {options} from "./selector/options";
import {ReactComponent as Info} from '../../../../assets/main/form/info.svg';

interface TableData {
    id: number;
    checked: boolean;
    name: string;
    active?: string;
    info?: string;
    netPrice: string;
    vat: number;
    grossPrice: number;
    total: number;
}


export const Payment = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [tableData, setTableData] = useState<TableData[]>([
        {
            id: 1,
            checked: true,
            name: 'Roczny abonament',
            active: 'Reklama będzie aktywna od 02.01.2022 do 01.01.2023',
            netPrice: '399 PLN / rok',
            vat: 23,
            grossPrice: 490.77,
            total: 490.77
        },
        {
            id: 2,
            checked: false,
            name: 'Wyżej na Liście',
            active: 'Reklama będzie aktywna od 02.01.2022 do 01.02.2022',
            info: 'Państwa firma będzie pojawiać się losowo w centralnej części strony głównej pod napisem „Szczególnie Polecamy” Maksymalna ilość miejsc w tej sekcji to 8. There should appraer 4 slides randdom as we have righ now limit of banners are 8.',
            netPrice: '69 PLN / miesiąc',
            vat: 23,
            grossPrice: 84.87,
            total: 254.61
        },
        {
            id: 3,
            checked: true,
            name: 'Wyjątkowe Firmy',
            active: '',
            netPrice: '99 PLN / miesiąc',
            vat: 23,
            grossPrice: 121.77,
            total: 254.61
        },
        {
            id: 4,
            checked: true,
            name: 'Szczególnie Polecamy',
            active: '',
            netPrice: '199 PLN / miesiąc',
            vat: 23,
            grossPrice: 244.77,
            total: 254.61
        },
    ]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (index: number) => {
        const newData = [...tableData];
        newData[index].checked = !newData[index].checked;
        setTableData(newData);
    };

    return (
        <PaymentWrap>
            <TableContainer>
                <Table>
                    <thead>
                    <TableRow>
                        <TableHeader>Nazwa</TableHeader>
                        <TableHeader>Okres</TableHeader>
                        <TableHeader>Cena netto</TableHeader>
                        <TableHeader style={{paddingRight:'50px'}}>VAT</TableHeader>
                        <TableHeader>Cena brutto</TableHeader>
                        <TableHeader style={{textAlign: 'center'}}>Suma</TableHeader>
                    </TableRow>
                    </thead>
                    <tbody>
                    {tableData.map((row, index) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                <TableBlocks>
                                    <Checkbox
                                        type="checkbox"
                                        checked={row.checked}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                    <TableBlock>
                                        <BlockInfo style={{minWidth: '222px'}}>
                                            <TableName>{row.name}</TableName>
                                            {row.info &&
                                                <Container>
                                                    <PlusButton onClick={handleToggle}>
                                                        <Info/>
                                                    </PlusButton>
                                                    <DropdownMenu isOpen={isOpen}>
                                                        <MenuItem>{row.info}</MenuItem>
                                                    </DropdownMenu>
                                                </Container>
                                            }
                                        </BlockInfo>
                                        <TableActive>{row.active}</TableActive>
                                    </TableBlock>
                                </TableBlocks>
                            </TableCell>
                            <TableCell>
                                <Selector name="mySelectBox" options={options}/>
                            </TableCell>
                            <TableCell style={{minWidth: '160px'}}>{row.netPrice}</TableCell>
                            <TableCell>{row.vat}%</TableCell>
                            <TableCell>{row.grossPrice}</TableCell>
                            <TableCell style={{minWidth: '125px'}}>{row.total} PLN</TableCell>
                        </TableRow>
                    ))}
                    </tbody>
                </Table>
            </TableContainer>


            <CompleteContainer>
                <CompleteItems>
                    <CompleteItem>
                        <CompleteItemHeader>Сałkowity</CompleteItemHeader>
                        <CompleteInfo>Roczny abonament 1 rok, WYŻEJ NA LIŚCIE 3 miesiące</CompleteInfo>
                    </CompleteItem>
                    <CompleteItemRight>
                        <CompleteItemHeader>Razem do zapłaty</CompleteItemHeader>
                        <CompletePrise>745,38 PLN</CompletePrise>
                    </CompleteItemRight>
                </CompleteItems>
                <CompleteItems>
                    <CompleteItem>
                        <CompleteIcons>
                            <a href="#" title="Visa">
                                <img src={visa} alt="visa" />
                            </a>
                            <a href="#" title="Mastercard">
                                <img src={mastercard} alt="mastercard" />
                            </a>
                            <a href="#" title="Apple Pay">
                                <img src={applePay} alt="applePay" />
                            </a>
                            <a href="#" title="Google Pay">
                                <img src={googlePay} alt="googlePay" />
                            </a>
                        </CompleteIcons>
                    </CompleteItem>
                    <CompleteItemRight>
                        <AdvertisementButton> Przejdź do płatności</AdvertisementButton>
                    </CompleteItemRight>
                </CompleteItems>

            </CompleteContainer>
        </PaymentWrap>
    )
        ;
};