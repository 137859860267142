import {baseApi} from "../base-api";
import Cookies from "universal-cookie";
import {CompanyData} from "services/company/company.types";


const cookies = new Cookies();

const getToken = () => {
    const token = cookies.get('jwt_authorization');
    if (!token) {
        throw new Error('Токен авторизации не найден в куки');
    }
    return token;
}


export const getCompanyId = () => {
    const companyId = cookies.get('company_id');
    if (!companyId) {
        throw new Error('companyId не найден в куки');
    }
    return companyId;
}


export const companyService = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        company: builder.query<CompanyData, void>({
            providesTags: ['Company'],
            query: () => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    method: 'GET',
                    url: `/company/${companyId}/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        companyEdit: builder.mutation<any, any>({
            invalidatesTags: ['Company'],
            query: (body) => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    body,
                    method: 'PUT',
                    url: `/company/${companyId}/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
    }),
});

export const {
    useCompanyQuery,
    useCompanyEditMutation,
} = companyService;

