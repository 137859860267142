import React, {useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/locale/pl';
import dayLocaleData from 'dayjs/plugin/localeData';
import {Calendar, Popover} from 'antd';
import {CalendarMode} from 'antd/es/calendar/generateCalendar';
import {
    CalendarGrid,
    CurrentDayStyle,
    CustomDateCell,
    MonthBlock,
    StyledCalendarWrapper,
    WhiteDot,
    Wrapper
} from '../calendar-info/style-calendar';
import locale from 'antd/lib/date-picker/locale/pl_PL';
import {CalendarHeaderBlock} from './calendar-header/CalendarHeader';
import usePopup from "../../../../common/hook/use-popup";
import {Popups} from "../../../../common/popups/Popups";
import {FormDate} from "../../account/forms/date-form/FormDate";


dayjs.extend(dayLocaleData);

const renderDot = (color?: string) => (
    <WhiteDot xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
        <circle cx="3" cy="3" r="3" fill={color}/>
    </WhiteDot>
);

export const CalendarBlock: React.FC = () => {
    const [currentYear, setCurrentYear] = useState(dayjs().year());  // Создаем состояние для текущего года
    const {
        showPopup: showCalendarPopup,
        onClickHandler: onClickCalendarHandler,
        handleClosePopup: handleCloseCalendarPopup,
        onClickClosest: onClickClosestCalendar,
    } = usePopup();

    const highlightedDates = ['2023-08-06', '2023-08-10'];  // Задаем массив выделенных дат
    const availableDates = ['2023-08-18', '2023-08-29'];  // Задаем массив доступных дат
    const pastTerms = ['2023-07-18', '2023-07-29'];  // Задаем массив прошедших дат


    // const renderDateTooltipContent = () => {
    //     // Функция для отображения содержимого всплывающего окна
    //     return <span>Wybór świadków</span>;
    // };

    const handlePrevYear = () => {
        // Обработчик для уменьшения текущего года на 1
        setCurrentYear(currentYear - 1);
    };

    const handleNextYear = () => {
        // Обработчик для увеличения текущего года на 1
        setCurrentYear(currentYear + 1);
    };

    const onPanelChange = (value: Dayjs, mode: CalendarMode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };

    const handleSelect = (date: Dayjs) => {
        // Обработчик для выбора даты, можно добавить здесь отображение всплывающего окна или выполнение других действий
        console.log('Selected date:', date.format('YYYY-MM-DD'));
        // Здесь можно показать всплывающее окно или выполнить другие действия при выборе даты
    };

    const calendars = [];

    for (let i = 0; i < 12; i++) {
        const currentMonth = dayjs().year(currentYear).month(i);  // Создаем объект текущего месяца

        const calendar = (
            <StyledCalendarWrapper key={i}>
                <Calendar
                    locale={locale}  // Устанавливаем локализацию для компонента
                    fullscreen={false}
                    value={currentMonth}
                    fullCellRender={(date) => {
                        const isCurrentMonth = date.month() === currentMonth.month();  // Проверяем, текущий ли месяц
                        const isCurrentDay = date.isSame(dayjs(), 'day');  // Проверяем, текущий ли день
                        const formattedDate = date.format('YYYY-MM-DD');  // Форматируем дату
                        const isHighlighted = highlightedDates.includes(formattedDate);  // Проверяем, есть ли дата в массиве выделенных дат

                        return (
                            <CustomDateCell
                                isHighlighted={isHighlighted}
                                isAvailable={availableDates.includes(formattedDate)}
                                isPast={pastTerms.includes(formattedDate)}
                                style={isCurrentMonth && isCurrentDay ? {background: 'none'} : undefined}
                                onClick={onClickCalendarHandler}
                            >
                                {isCurrentDay ? (
                                    // <Popover content={renderDateTooltipContent}>
                                    <Popover >
                                        <CurrentDayStyle>{date.date()}</CurrentDayStyle>
                                        {isHighlighted
                                            && renderDot('white')}
                                        {availableDates.includes(formattedDate) && renderDot('#1056B2')}
                                        {pastTerms.includes(formattedDate) && renderDot(isCurrentMonth ? '#D2DAE3' : 'red')}
                                        {highlightedDates.includes(formattedDate) && renderDot('blue')}
                                    </Popover>
                                ) : (
                                    // <Popover content={renderDateTooltipContent}>
                                    <Popover >
                                        <span>{date.date()}</span>
                                        {isHighlighted && renderDot('white')}
                                        {availableDates.includes(formattedDate) && renderDot('#1056B2')}
                                        {pastTerms.includes(formattedDate) && renderDot(isCurrentMonth ? '#D2DAE3' : 'red')}
                                        {highlightedDates.includes(formattedDate) && renderDot('white')}
                                    </Popover>
                                )}
                            </CustomDateCell>
                        );
                    }}
                    headerRender={({value, type, onChange}) => {
                        const year = value.year();
                        const month = value.month();

                        dayjs.locale('pl');  // Устанавливаем локализацию для месяца
                        const monthName = dayjs().month(month).format('MMMM');  // Получаем название месяца

                        return (
                            <MonthBlock>
                                <span>{monthName}</span>
                            </MonthBlock>
                        );
                    }}
                    // onPanelChange={onPanelChange}
                    // onSelect={handleSelect}  // Добавляем обработчик выбора даты
                />
            </StyledCalendarWrapper>
        );

        calendars.push(calendar);
    }

    return (
        <Wrapper>
            <CalendarHeaderBlock
                currentYear={currentYear}
                handlePrevYear={handlePrevYear}
                handleNextYear={handleNextYear}
            />
            <CalendarGrid>
                {calendars.map((calendar, index) => (
                    <div key={index}>{calendar}</div>
                ))}
            </CalendarGrid>
            <Popups
                show={showCalendarPopup}
                handleClosePopups={handleCloseCalendarPopup}
                onClickClosest={onClickClosestCalendar}
                formComponent={<FormDate showCalendarPopup={showCalendarPopup} handleCalendarClosePopups={handleCloseCalendarPopup} />}
            />
        </Wrapper>
    );
};





