import styled from "styled-components";

interface ToggleGroupButtonProps {
    selected?: boolean;
}

export const ToggleGroupButtonWrap = styled.div`
    display: flex;
    position: relative;
    border: 0.6px solid rgba(12, 18, 21, 0.2);
    border-radius: 5px;
    width: 258px;
    margin-bottom: 8px;
    gap: 10px;
`;

export const ToggleGroupButton = styled.button<ToggleGroupButtonProps>`
    padding: 5px 20px;
    display: flex;
    svg {
        width: 14px;
        height: 14px;
    }
    background-color: transparent; 
    &:hover {
        background-color: #f0f0f0;
        border-radius: 4px;
        transition: background-color 0.3s ease;
    }
    ${props => props.selected && `
        background-color: #f0f0f0;
    `}
`;




export const ToggleGroupTextButton = styled.button`
    width: 272px;
    color: rgb(12, 18, 21);
    font-family: Gilroy,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.8;
    padding: 5px 0;
    &:hover {
        background-color: #f0f0f0;
        border-radius: 4px;
        transition: background-color 0.3s ease;
    }
`;

export const DropdownMenu = styled.div`
    position: absolute;
    top: 100%;
    left: 176px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
`;

export const ArraySvg = styled.div`
    display: flex;
    padding-left: 5px;
    align-self: end;
svg {
    width: 10px;
    height: 10px;
}
`;
