import React, {useEffect} from 'react';
import {PhotoLabel} from "components/pages/presentation/form-presentation/style-form-presentation";
import {Skeleton, Upload} from "antd";
import {
    useCompanyAddFileMutation,
    useCompanyDeleteFileMutation,
    useCompanyFileQuery
} from "services/company/file/companyFile.service";
import {DeleteOutlined} from '@ant-design/icons';
import {WrapperPresentationFile} from "common/elements/PresentationFile/style-presentationFile";
import {Link} from "react-router-dom";
import {errorDeleteMessage, errorUploadMessage, successDeleteMessage} from "common/elements/PresentationFile/messaage";


type FileType = {
    filename: string;
    link: string;
};


export const PresentationFile = () => {
    const {data, error, isError, isLoading, isFetching} = useCompanyFileQuery();
    const [addFile, {
        isLoading: AddFileIsLoading,
        isError: AddFileIsError,
        error: AddFileError
    }] = useCompanyAddFileMutation();
    const [deleteFile, {isLoading: DeleteLoading, isError: isErrorDelete}] = useCompanyDeleteFileMutation();

    const loading = isLoading || AddFileIsLoading || DeleteLoading || isFetching;

    useEffect(() => {
        if (AddFileIsError && AddFileError) {
            const err = AddFileError as any;
            const message = err?.data?.message || 'Nie udało się dodać pliku';
            errorUploadMessage(message)
        }
    }, [AddFileIsError, AddFileError]);


    const deleteFileHandler = async () => {
        try {
            await deleteFile({});
            successDeleteMessage();
        } catch (error) {
            console.error("Error deleting file:", error);
            errorDeleteMessage(error);
        }
    };

    return (
        <Upload
            customRequest={async ({file}) => {
                const formData = new FormData();
                formData.append('brochure_file', file);
                try {
                    await addFile(formData);
                } catch (error) {
                    const err = AddFileError as any;
                    const message = err?.data?.message || 'Nie udało się dodać pliku';
                    errorUploadMessage(message);
                }
            }}
            showUploadList={false}
        >
            {loading ? (
                <Skeleton.Input active size={'small'}/>
            ) : isError ? (
                <div>Ошибка загрузки данных</div>
            ) : (!data || data.length === 0) ? (
                <PhotoLabel>+ Dodaj broszurę</PhotoLabel>
            ) : (
                <div style={{width: '100%'}}>
                    {data && (
                        Object.entries<FileType>(data).map(([key, fileData]) => (
                            <WrapperPresentationFile key={key}>
                                <Link
                                    to={fileData.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {fileData.filename}
                                </Link>
                                <button onClick={(e) => {
                                    e.stopPropagation();
                                    deleteFileHandler();
                                }}>
                                    <DeleteOutlined/>
                                </button>
                            </WrapperPresentationFile>
                        ))
                    )}
                </div>
            )}
        </Upload>
    );
};

