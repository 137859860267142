import styled from 'styled-components';
import backgroundImage from '../../../../assets/main/account/hall/bg.png';

export const Container = styled.div`
  display: flex;
  gap: 20px;
  flex: 1;
  margin-bottom: 80px;
  margin-right: 30px;
  justify-content: space-between;
  @media (max-width: 1138.98px) {
    flex-direction: column;
    gap: 10px;
  }
  @media (max-width: 1000.98px) {
    margin-bottom: 40px;
    margin-right: 0;
    //padding-top: 70px;
  }

`;


export const LeftBlock = styled.div`
  position: relative;
  border-radius: 5px;
  width: 100%;
  z-index: 1;
  background-image: url(${backgroundImage});
  background-size: cover;
  padding: 40px 29px;
  overflow: hidden;
  max-width: 571px;
  @media (max-width: 1139.98px) {
    max-width: 100%;
  }
  @media (max-width: 773.98px) {
    padding: 40px 20px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: -1;
    background-color:rgba(0, 0, 0, .5);
  }
`;


export const RightBlock = styled.div`
  font-family: 'Gilroy', sans-serif;
  background:var(--white);
  border-radius: 5px;
  width: 100%;
  padding: 40px 29px;
  max-width: 571px;
  box-shadow: 0 4px 30px rgba(11, 62, 138, .08);
  @media (max-width: 1139.98px) {
    max-width: 100%;
  } 

  @media (max-width: 773.98px) {
    padding: 40px 20px ;
  }

`;

export const Title = styled.p<{ isLeft?: boolean }>`
  font-family: 'Gilroy', sans-serif;
  color: ${(props) => (props.isLeft ? 'var(--black);' : ' var(--white)')};
  font-weight: 600;
  //font-size: 34px;
  line-height: 105%;
  letter-spacing: 0.04em;
  font-size: calc(19px + 15 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(19px + (19 + 15 * 0.7) * ((100vw - 320px) / 1480));
  }
`;

export const Subtitle = styled.p<{ isLeft?: boolean }>`
  font-family: 'Gilroy', sans-serif;
  color: ${(props) => (props.isLeft ? 'var(--black);' : 'var(--white)')};
  font-weight: 500;
  //font-size: 17px;
  line-height: 20px;
  text-transform: uppercase;
  padding-top: 12px;
  margin-bottom: 36px;
  font-size: calc(15px + 2 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(15px + (15 + 2 * 0.7) * ((100vw - 320px) / 1480));
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
  gap: 20px;
  
  @media (max-width: 1470.98px) {
    flex-direction: column;
    gap: 10px;
  }
  @media (max-width: 1138.98px) {
    flex-direction: row;
    gap: 20px;
  }
  @media (max-width: 630.98px) {
    flex-direction: column;
  }
`;


export const LeftButton = styled.button`
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(210, 218, 227, 0.8);
  border-radius: 200px 100px 100px 0;
  font-family: 'Gilroy',sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
  padding: 12px 53.5px;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  @media (hover: hover) and (min-width: 1023.98px) {
    &:hover {
      background: rgba(255, 255, 255, 1);
      border-color: rgba(210, 218, 227, 1);
    }
  }
`;

export const RightButton = styled.button`
  background: var(--blueButton);
  border-radius: 100px 200px 0 100px;
  font-family: 'Gilroy',sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 53.5px;
  color:var(--white);
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  @media (hover: hover) and (min-width: 1023.98px) {
    &:hover {
      background: var(--blueButtonHover);
    }
  }

  @media (max-width: 438.98px) {
    padding: 12px 45px;
  }
`;



export const Button = styled.button`
  /* стили для кнопки */
`;