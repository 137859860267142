import React from 'react';
import {Container} from "./style-footer-top";
import {Contacts} from "./contacts/Contacts";
import {Help} from "./help/Help";

export const FooterTop = () => {
    return (
        <Container>
           <Contacts/>
            <Help/>
        </Container>
    );
};

