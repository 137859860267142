import React, {useEffect, useState} from 'react';
import {Flex, Upload, UploadProps} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {
    AdsLabel,
    AdsSmallFormBlock,
    DraggableBlock,
    FileIconContainer,
    FileUploadIcon,
    FileUploadText,
    LoaderBlock,
    StyledSmaleFileContainer,
    StyledSmaleFileInputContainer,
    StyledSmallFileImg
} from 'components/pages/presentation/form-presentation/upload-big-img/style-upload-big-img';
import {ReactComponent as CameraIcon} from 'assets/main/form/camera.svg';
import {
    useMultimediaGalleryAddMutation,
    useMultimediaGalleryDeleteMutation,
    useMultimediaGalleryQuery,
    useMultimediaGallerySortMutation
} from "services/company/multimedia/miltimediaGallery/multimedia-gallery.service";
import {Loader} from "components/pages/account/login/account-form/form-login/style-form";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";


export const MultimediaGallery: React.FC = () => {
    const [imageUrls, setImageUrls] = useState<Record<string, { filename: string, link: string }>>({});
    const {data, error, isLoading, isError, isFetching} = useMultimediaGalleryQuery();
    const [multimediaGalleryAdd, {isLoading: isLoadingGalleryAdd}] = useMultimediaGalleryAddMutation();
    const [multimediaGalleryDelete, {isLoading: isLoadingGalleryDelete}] = useMultimediaGalleryDeleteMutation();
    const [multimediaGallerySort, {isLoading: isLoadingGallerySort}] = useMultimediaGallerySortMutation();
    const [loadingKey, setLoadingKey] = useState<string | null>(null);

    const loading = isLoading || isLoadingGalleryAdd || isLoadingGalleryDelete || isFetching || isLoadingGallerySort;
    const loadingFull = isLoading || isLoadingGalleryAdd || isFetching || isLoadingGallerySort;


    useEffect(() => {
        if (data) {
            setImageUrls(data);
        }
    }, [data]);


    const handleChange: UploadProps['onChange'] = async (info) => {

        if (info.file.status === 'uploading') {
            setLoadingKey(info.file.uid);
            return;
        }
        if (info.file.status === 'done') {
            const formData = new FormData();
            formData.append('gallery_image', info.file.originFileObj as any);
            try {
                await multimediaGalleryAdd(formData);
                setLoadingKey(null);
            } catch (error) {
                console.error('Failed to add image', error);
                setLoadingKey(null);
            }
        }
    };


    const handleDelete = async (key: string) => {
        setLoadingKey(key);
        try {
            await multimediaGalleryDelete(key);
            const updatedImageUrls = {...imageUrls};
            delete updatedImageUrls[key];
            setImageUrls(updatedImageUrls);
            setLoadingKey(null);
        } catch (err) {
            console.error('Failed to delete image', err);
            setLoadingKey(null);
        }
    };


    const sanitizeImageUrl = (url: string) => {
        return url.replace(/\s+/g, '%20');
    };

    const handleDragEnd = async (result: any) => {
        if (!result.destination) return;

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        const newImageUrls = {...imageUrls};
        const keys = Object.keys(newImageUrls);
        const [removedKey] = keys.splice(startIndex, 1);
        keys.splice(endIndex, 0, removedKey);

        setImageUrls(prevImageUrls => {
            const orderedImageUrls: Record<string, { filename: string; link: string }> = {};
            keys.forEach((key) => {
                orderedImageUrls[key] = prevImageUrls[key];
            });
            return orderedImageUrls;
        });

        const imageUrlsArray = keys.map(key => newImageUrls[key].filename);

        try {
            await multimediaGallerySort(imageUrlsArray);
        } catch (err) {
            console.error('Failed to sort images', err);
        }
    };


    return (
        <AdsSmallFormBlock>
            <AdsLabel>
                Galeria zdjęć
            </AdsLabel>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="imageUrls">


                    {(provided: any) => (

                        <DraggableBlock ref={provided.innerRef} {...provided.droppableProps}>
                            <>
                                {Object.keys(imageUrls).length < 20 && (
                                    <StyledSmaleFileInputContainer>
                                        <Flex gap="middle" wrap="wrap">
                                            <Upload
                                                customRequest={async ({file}) => {
                                                    const formData = new FormData();
                                                    formData.append('gallery_image', file);
                                                    try {
                                                        await multimediaGalleryAdd(formData);
                                                    } catch (error) {
                                                        console.error('Failed to add image', error);
                                                    }
                                                }}
                                                onChange={handleChange}
                                                showUploadList={false}
                                            />
                                        </Flex>
                                        {loading ? (
                                            <LoaderBlock>
                                                <Loader/>
                                            </LoaderBlock>
                                        ) : (
                                            <>
                                                <FileIconContainer>
                                                    <FileUploadIcon>+</FileUploadIcon>
                                                    <FileUploadText>Dodaj zdjęcie</FileUploadText>
                                                </FileIconContainer>
                                                <CameraIcon/>
                                            </>
                                        )}
                                    </StyledSmaleFileInputContainer>
                                )}


                                {Object.keys(imageUrls).map((key, index) => (
                                    <Draggable key={key} draggableId={key} index={index}>
                                        {(provided: any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <StyledSmaleFileContainer key={key}>
                                                    {loadingKey === key || loadingFull ? (
                                                        <LoaderBlock>
                                                            <Loader/>
                                                        </LoaderBlock>
                                                    ) : (
                                                        <StyledSmallFileImg
                                                            imageUrl={sanitizeImageUrl(imageUrls[key].link)}/>
                                                    )}
                                                    <CloseOutlined
                                                        onClick={() => handleDelete(key)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            zIndex: 3,
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                </StyledSmaleFileContainer>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </>
                            {provided.placeholder}
                        </DraggableBlock>
                    )}
                </Droppable>
            </DragDropContext>


            <AdsLabel>
                Maksymalna ilość zdjęć wynosi 20 szt. W przypadku chęci dodania większej liczby prosimy o
                kontakt z opiekunem
            </AdsLabel>
        </AdsSmallFormBlock>
    );
};
