import thunkMiddleware from 'redux-thunk';
import {AnyAction, combineReducers, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {baseApi} from "services/base-api";
import {appReducer} from "app/app-reduser";
import {tabsReducer} from "app/tabs-reduser";
import {popupsReducer} from "app/popups-reducer";


const rootReducer = combineReducers({
    app: appReducer,
    tabs: tabsReducer,
    popups: popupsReducer,
    [baseApi.reducerPath]: baseApi.reducer,
})


export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .prepend(thunkMiddleware)
        .concat(baseApi.middleware),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    AnyAction
>
// @ts-ignore
window.store = store;


