import React from 'react';
import {AdvertisementBlock, AdvertisementButton, Container, SubTitle, Title} from "./style-advertisement";


interface IAdvertisement {
    title:string,
    text: string,
}

export const Advertisement = ({title,text}:IAdvertisement) => {
    return (
        <Container>
            <AdvertisementBlock>
                <Title>{title}</Title>
                <SubTitle>{text}</SubTitle>
                    <AdvertisementButton>Przejdź</AdvertisementButton>
            </AdvertisementBlock>
        </Container>
    );
};

