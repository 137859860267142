import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {NameWrapper} from "./style-username";
import {ReactComponent as Icon} from '../../../../assets/header/icon.svg';
import {useAppSelector} from "common/hook";


export const UserName: React.FC = () => {
    const dispatch = useDispatch();
    const username = useAppSelector((state) => state.app.name);

    useEffect(() => {
        // dispatch(fetchUsername());
    }, [dispatch]);

    return (
        <NameWrapper>
            <Icon/>
            <p>{username}</p>
        </NameWrapper>
    );
};

