import React from 'react';
import {FullWrapper} from "components/layout/style-layout";
import {
    ConfirmationBlock,
    ConfirmationContainer,
    ConfirmationText,
    ConfirmationWrap
} from "components/pages/password-change/confirmation/style-confirmation";
import {ReactComponent as CheckCircleIcon} from '../../../../assets/sing/checkCircle.svg';


export const Confirmation = () => {
    return (
        <FullWrapper>
            <ConfirmationWrap>
                <ConfirmationContainer>
                    <ConfirmationBlock>
                        <CheckCircleIcon/>
                        <ConfirmationText>Potwierdzenie E-mail</ConfirmationText>
                    </ConfirmationBlock>
                </ConfirmationContainer>
            </ConfirmationWrap>
        </FullWrapper>
    );
};

