import {
    ContHeg50,
    ContListSelectMate,
    ContSelectCenter,
    ContSelectInt,
    IconSelectMate,
    SelecionadoOpcion,
    SelectMate
} from "common/selector/style-question-select";
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as ArrayIcon} from '../../assets/main/form/selectArray.svg';
import {CompanyData} from "services/company/company.types";

interface CategorySelectProps {
    offerType: string;
    categoryId: string;
    data: CompanyData | undefined;
    setSelectedFeatures: (p: any[]) => void;
    setCategoryId: (selectedIndex: string) => void;
    error:boolean
}

export const CategorySelect: React.FC<CategorySelectProps> = (
    {
        data,
        offerType,
        setCategoryId,
        setSelectedFeatures,
        error
    }
) => {
    const categories: string[] = Object.keys(data?.options.offer_types[offerType]?.categories || {}); // Отрисовка всех селект
    const start = data?.company?.category?.id !== undefined ? +data.company.category.id : 0;  //стартовое занчение селект
    const [selectedIndex, setSelectedIndex] = useState(start);
    const selectedTitle = selectedIndex !== null ? data?.options.offer_types[offerType]?.categories[selectedIndex]?.title : null;
    const selectRef = useRef<HTMLDivElement | null>(null); // изменили на HTMLDivElement
    const [selectOpen, setSelectOpen] = useState(false);
    const [showEllipsis, setShowEllipsis] = useState(false);

    useEffect(() => {
        setCategoryId(selectedIndex.toString());
    }, [selectedIndex, setCategoryId]);

    const handleToggleSelect = () => {
        setSelectOpen((prevState) => !prevState);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setSelectOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setSelectedIndex(start);
    }, [start]);

    const handleClickOption = (key: number) => {
        setSelectedIndex(key);
        setSelectOpen(false);
        setSelectedFeatures([]);
        const selectedValue = categories[key];
        const event = new Event('change', {bubbles: true});
        Object.defineProperty(event, 'target', {value: {value: selectedValue}, enumerable: true});
        event.initEvent('change', true, true);
    };

    useEffect(() => {
        if (selectedIndex !== null && categories[selectedIndex] !== undefined) {
            const selectedValue = categories[selectedIndex];
            const event = document.createEvent('Event');
            event.initEvent('change', true, true);
            const target = {value: selectedValue};
            Object.defineProperty(event, 'target', {value: target, enumerable: true});
        }
    }, [selectedIndex, categories]);

    return (
        <ContSelectCenter ref={selectRef}>
            <ContHeg50/>
            <SelectMate
                error={error}
                data-mate-select="active">
                <SelecionadoOpcion  error={error} onClick={handleToggleSelect}>
                    {showEllipsis ? '...' : selectedTitle || 'Wybierz kategorię'}
                </SelecionadoOpcion>
                <IconSelectMate onClick={handleToggleSelect}>
                    <ArrayIcon />
                </IconSelectMate>
                <ContListSelectMate>
                    <ContSelectInt style={{height: selectOpen ? 'auto' : 0}}>
                        {categories.map((key) => (
                            <li
                                key={key}
                                className={parseInt(key) === selectedIndex ? 'active' : ''}
                                onClick={() => handleClickOption(parseInt(key))}
                            >
                                {data?.options.offer_types[offerType]?.categories[key]?.title}
                            </li>
                        ))}
                    </ContSelectInt>
                </ContListSelectMate>
            </SelectMate>
        </ContSelectCenter>
    );
};




