import React from 'react';
import {Container, ContainerBlock, Email, Name, Phone} from "./style-contacts";
import {ContactsTitle, ContainerItems} from "../style-footer-top";


const contactsArray = [
    {
        name: 'Idalia Biernacka',
        tell: '793 111 791',
        email: 'idalia.biernacka@gdziewesele.pl',
    },
    {
        name: 'Marta Wójcik',
        tell: '506 501 285',
        email: 'info@gdziewesele.pl',
    },
]

export const Contacts = () => {
    return (
        <Container>
            <ContactsTitle>Nasze kontakty</ContactsTitle>
            <ContainerItems>
                {contactsArray.map((el, index) => {
                    return (
                        <ContainerBlock key={index}>
                            <Name>{el.name}</Name>
                            <Phone href={`tel:${el.tell}`}>{el.tell}</Phone>
                            <Email href={`mailto:${el.email}`}>{el.email}</Email>
                        </ContainerBlock>
                    )
                })}
            </ContainerItems>

        </Container>
    );
};

