import React, {useEffect, useState} from 'react';
import {
    BlockWrap,
    FormBlock,
    FormMultimediaWrap,
    FormTitle,
    InputBlock,
    InputItem
} from "components/pages/presentation/form-presentation/style-form-presentation";
import {ErrorText, StyledInput, StyledLabel} from "components/pages/account/login/account-form/form-login/style-form";
import {MultimediaFile} from "components/pages/presentation/multimedia/form-multimedia/multimedia-file/MultimediaFile";
import {useCompanyEditMutation} from "services/company/company.service";
import {message} from "antd";

type FormMultimediaType = {
    youtubeVideo: string,
    setYoutubeVideo: (youtubeVideo: string) => void
    vimeoVideo: string;
    setVimeoVideo: (vimeoVideo: string) => void
}


export const FormMultimedia = ({setYoutubeVideo, youtubeVideo, setVimeoVideo, vimeoVideo}: FormMultimediaType) => {

    const [updatePresentation, {
        data: updateData,
        isLoading: updateLoadingState,
        error,
        isError
    }] = useCompanyEditMutation()

    const[errorMessage, setErrorMessage] = useState<any>({})




    useEffect(() => {
        if (isError) {
            const err = error as any;
            setErrorMessage(err);
            message.error(`Proszę poprawić błędy w formularzu`)
        } else {
            setErrorMessage({});
        }
    }, [isError, error,updateData]);




    const handleYoutubeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setYoutubeVideo(e.target.value);
    };

    const handleVimeoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVimeoVideo(e.target.value);
    };

    return (
        <FormMultimediaWrap>
            <FormBlock id="multimedia">
                <BlockWrap>
                    <FormTitle>Multimedia</FormTitle>
                    <InputBlock>
                        <InputItem>
                            <StyledLabel
                                error={errorMessage?.data?.youtube_video}
                            >Link do filmu na YouTube</StyledLabel>
                            <StyledInput
                                error={errorMessage?.data?.youtube_video}
                                value={youtubeVideo}
                                onChange={handleYoutubeChange}
                            />
                            {(errorMessage?.data?.youtube_video) && (
                                <ErrorText>{errorMessage?.data?.youtube_video}</ErrorText>
                            )}
                        </InputItem>
                        <InputItem>
                            <StyledLabel error={errorMessage?.data?.vimeo_video}
                            >Link do filmu na Vimeo</StyledLabel>
                            <StyledInput
                                error={errorMessage?.data?.vimeo_video}
                                value={vimeoVideo}
                                onChange={handleVimeoChange}
                            />
                            {(errorMessage?.data?.vimeo_video) && (
                                <ErrorText>{errorMessage?.data?.vimeo_video}</ErrorText>
                            )}
                        </InputItem>
                    </InputBlock>
                </BlockWrap>

                <MultimediaFile/>

            </FormBlock>
        </FormMultimediaWrap>
    );
};





