import React from 'react';
import {
    ContactInformation
} from "components/pages/informations/informations-forms/contact-information/ContactInformation";
import {ContactPerson} from "components/pages/informations/informations-forms/contact-person/ContactPerson";

export const InformationForm = () => {
    return (

        <div>
            <ContactPerson/>
            <ContactInformation/>
        </div>
    );
};

