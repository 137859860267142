import React from 'react';
import {Advertisement} from "../account/advertisement/advertisement";
import {FullWrapper} from "../../layout/style-layout";
import {CalendarInfo} from "./calendar-info/CalendarInfo";
import {CalendarBlock} from "./calendar-block/CalendarBlock";

export const Calendar = () => {
    return (
        <FullWrapper>
            <CalendarInfo/>
            <CalendarBlock/>
            <Advertisement title='Zwiększ zasięg reklamy?'
                           text='Wybierz odpowiedni plan taryfowy i wypromuj swoją firmę'/>
        </FullWrapper>
    );
};
