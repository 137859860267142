import styled from "styled-components";
import backgroundImage from '../../../../assets/main/account/advertisement/bg.png'

export const Container = styled.div`
  position: relative;
  background-image: url(${backgroundImage});
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 55px 40px 72px 40px;
  margin-bottom: 120px;
  z-index: 1;
  transition: width 0.3s, margin 0.3s, padding 0.3s;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: -1;
    background-color:rgba(0, 0, 0, .5);
  }
  
  @media (max-width: 1024.98px) {
    width: 65%;
  }  
  @media (max-width: 994.98px) {
    width: 100%;
  }
  

  @media (max-width: 1000.98px) {
    margin-bottom: 20px;
  }
  
  @media (max-width: 773.98px) {
    padding: 40px 20px;
  }
`;


export const AdvertisementWrap = styled.div`
    padding-top: 100px;
`;
export const AdvertisementBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

export const Title = styled.p`
  font-family: 'Cormorant Garamond', sans-serif;
  font-weight: 600;
  //font-size: 34px;
  line-height: 105%;
  text-align: center;
  letter-spacing: 0.04em;
  color:var(--white);
  margin-bottom: 16px;
  font-size: calc(20px + 14 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(20px + (20 + 14 * 0.7) * ((100vw - 320px) / 1480));
  }
`;

export const SubTitle = styled.p`
  font-family: 'Gilroy', sans-serif;
  font-weight: 300;
  line-height: 140%;
  color:var(--white);
  margin-bottom: 26px;
  font-size: calc(15px + 7 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(15px + (15 + 7 * 0.7) * ((100vw - 320px) / 1480));
  }
`;


export const AdvertisementButton = styled.a`
  background: var(--blueButton);
  border-radius: 200px 100px 100px 0;
  font-family: 'Gilroy',sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color:var(--white);
  padding: 12px 77px;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  text-align: center;
  @media (hover: hover) and (min-width: 1023.98px) {
    &:hover {
      background: var(--blueButtonHover);
    }
  }
  @media (max-width: 773.98px) {
    width: 100%;
  }
`;
