import styled from "styled-components";

export const Container = styled.div`
  background: linear-gradient(90deg, #1056B2 0%, #2779E4 100%);
  padding: 61px 10px 65px 30px;
  display: flex;
  width: 100%;
  
  @media (max-width: 773.98px) {
   flex-direction: column;
    gap: 20px;
    padding: 40px 20px ;
  }
`;


export const ContactsTitle = styled.p`
  font-family: 'Gilroy',sans-serif;
  font-weight: 600;
  //font-size: 24px;
  line-height: 29px;
  color: var(--white);
  font-size: calc(16px + 8 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(16px + (16 + 8 * 0.7) * ((100vw - 320px) / 1480));
  }
`;


export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 773.98px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 527.98px) {
    flex-direction: column;
  }
  
`;




