import styled from "styled-components";

export const ConfirmationWrap = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 46px;
    margin-bottom: 301px;
    @media (max-width: 1000.98px) {
        margin-bottom: 50px;
        padding-top: 0px;
    }
`;

export const ConfirmationContainer = styled.div`
    background-color: #F5F9FF;
    width: 90%; 
    max-width: 540px; 
    height: 162px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
`;

export const ConfirmationBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px; 
`;

export const ConfirmationText = styled.span`
    font-weight: 500;
    font-size: 30px; 
    line-height: 35.31px;
    color: #0C1215; 
`;
