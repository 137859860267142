import Cookies from "universal-cookie";
import {baseApi} from "services/base-api";


const cookies = new Cookies();

const getToken = () => {
    const token = cookies.get('jwt_authorization');
    if (!token) {
        throw new Error('Токен авторизации не найден в куки');
    }
    return token;
}

export const getCompanyId = () => {
    const companyId = cookies.get('company_id');
    if (!companyId) {
        throw new Error('companyId не найден в куки');
    }
    return companyId;
}

export const companyFileService = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        companyFile: builder.query<any, void>({
            providesTags: ['CompanyFile'],
            query: () => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    method: 'GET',
                    url: `/company/${companyId}/brochure/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        companyAddFile: builder.mutation<any, any>({
            invalidatesTags: ['CompanyFile'],
            query: (body) => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    body,
                    method: 'POST',
                    url: `/company/${companyId}/brochure/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        companyDeleteFile: builder.mutation<any, any>({
            invalidatesTags: ['CompanyFile'],
            query: () => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    method: 'DELETE',
                    url: `/company/${companyId}/brochure/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),

    }),
});

export const {
    useCompanyFileQuery,
    useCompanyAddFileMutation,
    useCompanyDeleteFileMutation,
} = companyFileService;
