import styled from "styled-components";

export const CertificatesPagesWrap = styled.section`
  display: flex;
  flex-direction: column;
`;



export const CertificatesBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(374px, 1fr));
    gap: 40px 20px;

 
    @media (max-width: 834.98px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 700.98px) {
        grid-template-columns: 1fr;
      margin: 0 auto;
    }
`;


export const CertificatesImage = styled.div`
  margin: 0 auto 12px auto;

  img {
    width: 100%;
    min-height: 235px;
    height: auto;
  }

`;

export const CertificatesItemTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
  margin-bottom: 10px;

  @media (max-width: 634.98px) {
    font-size: 18px;
  }
`;

export const CertificatesItemIconsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 634.98px) {
    gap: 10px;
  }

  @media (max-width: 454.98px) {
    gap: 40px;
  }

`;

export const CertificatesItemIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
  transition: opacity 0.3s; /* Add a smooth transition effect */
  svg {
    width: 24px;
    height: 24px;
  }
  &:hover {
      opacity: 0.6;
`;

export const ItemIconsText = styled.p`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 634.98px) {
    font-size: 14px;
  }
`;

export const CertificateBlock = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0; 
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s; /
  
`;

export const CertificatesItem = styled.div`
  max-width: 374px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  
  &:hover ${CertificateBlock} {
    opacity: 1;
    visibility: visible;
  }
`;

export const CertificatesEditButton = styled.button`
  display: flex;
  width: 124px;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 200px 100px 100px 0;
  border: 1px solid var(--border, #D2DAE3);
  opacity: 0.800000011920929;
  background: var(--white, #FFF);
  color: #0C1215;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;

  @media (max-width: 449.98px) {
    width: 100%;
  }

  &:hover {
    color: #1056B2;

    svg path {
      fill: #1056B2;
    }
  }
`;