import React, { useRef } from 'react';
import { PresentationNavigationWrap, NavigationContainer } from "./style-presentation-navigation";

const navigationItems = [
    { id: 'service', name: 'O usłudze' },
    { id: 'address-data', name: 'Dane adresowe' },
    { id: 'information-additional', name: 'Informacje dodatkowe' },
    { id: 'multimedia', name: 'Multimedia' },
    { id: 'faq', name: 'FAQ' }
];



export const PresentationNavigation = () => {
    const navRef = useRef(null);



    const handleClick = (item:any) => {
        const formBlock = document.getElementById(item.id);
        formBlock && formBlock.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <PresentationNavigationWrap>
            <NavigationContainer ref={navRef}>
                <ul>
                    {navigationItems.map(item => (
                        <li key={item.id}>
                            <a href={`#${item.id}`} onClick={() => handleClick(item)}>{item.name}</a>
                        </li>
                    ))}
                </ul>
            </NavigationContainer>
        </PresentationNavigationWrap>
    );
};
