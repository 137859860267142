import React from 'react';
import {RatingInformation} from "./rating-information/RatingInformation";
import {FeedbackInformation} from "./feedback-information/FeedbackInformation";
import {FeedbackBlockWrapper} from "./style-feedback-block";

export const FeedbackBlock = () => {
    return (
        <FeedbackBlockWrapper>
            <FeedbackInformation/>
            <RatingInformation/>
        </FeedbackBlockWrapper>
    );
};

