import React, {useEffect, useState} from 'react';
import {
    BlockWrap,
    FormBlock,
    FormPresentationWrap,
    FormTitle,
    InputBlock,
    InputItem,
    QuestionSubmit,
    SubmitBlock,
    SubmitWrap
} from "components/pages/presentation/form-presentation/style-form-presentation";
import {
    ErrorText,
    Loader,
    StyledInput,
    StyledLabel
} from "components/pages/account/login/account-form/form-login/style-form";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {ContactInformationSchema, InformationFormValues} from "common/validation/information/contactInformationShema";
import {ReactComponent as CheckIcon} from '../../../../../../assets/main/presentation/check.svg';
import {message} from "antd";


interface ContactInformationProps {
    onSubmit: (handlerInformation: InformationFormValues) => void;
    data?: any;
    updateLoadingState:boolean;
    isLoading:boolean;
    isError: any;
    error:any;

}

export const ContactInformationForm = ({ onSubmit, data,updateLoadingState,isLoading,isError, error}: ContactInformationProps) => {
    const [formData, setFormData] = useState<any | null>(null);
    const loader = updateLoadingState || isLoading;
    const [errorMessage, setErrorMessage] = useState<any>({})


    useEffect(() => {
        if (isError) {
            const err = error as any;
            setErrorMessage(err);
            message.error(`Proszę poprawić błędy w formularzu`)
        } else {
            setErrorMessage({});
        }
    }, [error, isError]);


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<InformationFormValues>({
        resolver: zodResolver(ContactInformationSchema),
        defaultValues: {
            contact_phone: '',
            contact_email: '',
            contact_last_name: '',
            contact_address_1: '',
            contact_city: '',
            contact_company_nip: '',
            contact_postal_code: '',
        },
    });

    useEffect(() => {
        if (data?.company) {
            setFormData({
                contact_phone: data.company.contact_phone,
                contact_email: data.company.contact_email,
                contact_last_name: data.company.contact_last_name,
                contact_address_1: data.company.contact_address_1,
                contact_city: data.company.contact_city,
                contact_company_nip: data.company.contact_company_nip,
                contact_postal_code: data.company.contact_postal_code,
            });
        }
    }, [data]);

    useEffect(() => {
        if (formData) {
            setValue('contact_phone', formData.contact_phone || '');
            setValue('contact_email', formData.contact_email || '');
            setValue('contact_last_name', formData.contact_last_name || '');
            setValue('contact_address_1', formData.contact_address_1 || '');
            setValue('contact_city', formData.contact_city || '');
            setValue('contact_company_nip', formData.contact_company_nip || '');
            setValue('contact_postal_code', formData.contact_postal_code || '');
        }
    }, [formData, setValue]);

    useEffect(() => {
        if (data?.company) {
            setValue('contact_phone', data.company.contact_phone || '');
            setValue('contact_email', data.company.contact_email || '');
            setValue('contact_last_name', data.company.contact_last_name || '');
            setValue('contact_address_1', data.company.contact_address_1 || '');
            setValue('contact_city', data.company.contact_city || '');
            setValue('contact_company_nip', data.company.contact_company_nip || '');
            setValue('contact_postal_code', data.company.contact_postal_code || '');
        }
    }, [data, setValue]);

    const handleFormSubmit = (formValues: InformationFormValues) => {
        onSubmit(formValues);
    };

    return (
        <FormPresentationWrap onSubmit={handleSubmit(handleFormSubmit)}>
            <FormBlock>
                <BlockWrap>
                    <FormTitle>Informacje kontaktowe</FormTitle>
                    <InputBlock>
                        <InputItem>
                            <StyledLabel>Nazwa firmy</StyledLabel>
                            <StyledInput
                                type="text"
                                {...register("contact_last_name")}
                            />
                        </InputItem>
                        <InputItem>
                            <StyledLabel>NIP Firmy</StyledLabel>
                            <StyledInput
                                type="text"
                                {...register("contact_company_nip")}
                            />
                        </InputItem>
                    </InputBlock>
                    <InputBlock>
                        <InputItem>
                            <StyledLabel>Kod pocztowy</StyledLabel>
                            <StyledInput
                                type="text"
                                {...register("contact_postal_code")}
                            />
                        </InputItem>
                        <InputItem>
                            <StyledLabel>Miasto</StyledLabel>
                            <StyledInput
                                type="text"
                                {...register("contact_city")}
                            />
                        </InputItem>
                    </InputBlock>
                    <InputBlock>
                        <InputItem>
                            <StyledLabel>Ulica</StyledLabel>
                            <StyledInput
                                type="text"
                                {...register("contact_address_1")}
                            />
                        </InputItem>
                        <InputItem>
                            <StyledLabel
                                error={errors.contact_email?.message || errorMessage?.data?.contact.contact_email}
                                htmlFor="email"
                            >
                                Kontaktowy adres e-mail*
                            </StyledLabel>
                            <StyledInput
                                error={errors.contact_email?.message || errorMessage?.data?.contact.contact_email}
                                id="email"
                                type="email"
                                {...register("contact_email")}
                            />
                            {(errors.contact_email?.message || errorMessage?.data?.contact.contact_email) && (
                                <ErrorText>{errors.contact_email?.message || errorMessage?.data?.contact.contact_email}</ErrorText>
                            )}
                        </InputItem>
                    </InputBlock>
                    <InputBlock>
                        <InputItem>
                            <StyledLabel
                                error={errors.contact_phone?.message || errorMessage?.data?.contact.contact_phone}
                                htmlFor="contact_phone"
                            >
                                Numer telefonu*
                            </StyledLabel>
                            <StyledInput
                                error={errors.contact_phone?.message || errorMessage?.data?.contact.contact_phone}
                                id="tel"
                                type="tel"
                                placeholder="+48 XXX XXX XXX"
                                {...register("contact_phone")}
                            />
                            {(errors.contact_phone?.message || errorMessage?.data?.contact.contact_phone) && (
                                <ErrorText>{errors.contact_phone?.message || errorMessage?.data?.contact.contact_phone}</ErrorText>
                            )}
                        </InputItem>
                        <InputItem />
                    </InputBlock>
                </BlockWrap>
            </FormBlock>
            <SubmitWrap>
                <SubmitBlock>
                    <QuestionSubmit style={{ marginLeft: "auto" }} type="submit">
                        {loader ? <Loader /> : <CheckIcon />}
                        <span>Zapisz</span>
                    </QuestionSubmit>
                </SubmitBlock>
            </SubmitWrap>
        </FormPresentationWrap>
    );
};
