import {z} from 'zod';

export const contactPersonSchema = z.object({
    first_name: z.string().min(1, {message: "Wprowadź nazwę"}),
    last_name: z.string().min(1, {message: "Wprowadź nazwisko"}),
    phone: z.string()
        .regex(/^(\+?48)? ?(\d{3} ?){2}\d{3}$/, {message: "Nieprawidłowy numer telefonu"}),
    email: z.string().email({message: "Nieprawidłowy adres email"}),
    profile_picture: z.any().optional(), // Добавляем поле profile_picture
});

export type ContactFormValues = z.infer<typeof contactPersonSchema>;
