import React from 'react';

import {ReactComponent as СrossIcon} from '../../../../../assets/main/form/cross.svg';
import {FormContainer, FormWrapper} from '../../login/account-form/form-login/style-form';
import {DateWork} from "../../../сalendar/calendar-block/calendar-header/date-form/date-work/DateWork";
import {FormDateWrap} from "./style-date-form";
import {CloseButton} from "../../../feedback/feedback-block/feedback-information/delete-popup/form-delete/form";

















interface PropsType {
    handleCalendarClosePopups: () => void;
    showCalendarPopup: boolean;
}


export const FormDate: React.FC<PropsType> = ({handleCalendarClosePopups, showCalendarPopup}) => {
    return (
        <FormWrapper onClick={(e) => e.stopPropagation()}>
            <FormDateWrap>
                <CloseButton type="button" onClick={handleCalendarClosePopups}>
                    <СrossIcon/>
                </CloseButton>
                <FormContainer>
                    <DateWork handleCalendarClosePopups={handleCalendarClosePopups} showCalendarPopup={showCalendarPopup} />
                </FormContainer>
            </FormDateWrap>
        </FormWrapper>
    );
};