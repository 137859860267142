import React from 'react';
import {ReactComponent as OneIcon} from '../../../../assets/main/calendar/one.svg';
import {ReactComponent as TwoIcon} from '../../../../assets/main/calendar/two.svg';
import {ReactComponent as ThreeIcon} from '../../../../assets/main/calendar/three.svg';
import {ReactComponent as FourIcon} from '../../../../assets/main/calendar/four.svg';
import {CalendarDescription, CalendarText, Container, Description, DescriptionItem} from "./style-calendar-info";


export const CalendarInfo = () => {
    return (
        <Container>
            <CalendarText>
                Skorzystaj z kalendarza, który umożliwia Ci dodanie wolnego terminy, wprowadź zaliczki on-line lub dodaj
                informację o rezerwacji
            </CalendarText>
            <CalendarDescription>
                <Description>
                    <DescriptionItem>
                        <OneIcon/>
                        <span>—</span>
                        <p>Aktualna data</p>
                    </DescriptionItem>
                    <DescriptionItem>
                        <TwoIcon/>
                        <span>—</span>
                        <p>Zarezerwowane terminy</p>
                    </DescriptionItem>
                    <DescriptionItem>
                        <ThreeIcon/>
                        <span>—</span>
                        <p>Dostępne terminy</p>
                    </DescriptionItem>
                    <DescriptionItem>
                        <FourIcon/>
                        <span>—</span>
                        <p>Przeszłość terminy</p>
                    </DescriptionItem>
                </Description>
            </CalendarDescription>
        </Container>
    );
};

