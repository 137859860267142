import styled from 'styled-components';


export const GoToTopWrapper = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #1056b2;
`;

export const GoToTopButton = styled.div`
    font-size: 2.4rem;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    position: fixed;
    bottom: 5rem;
    right: 1rem;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }

    svg {
        animation: gototop 1.2s linear infinite alternate-reverse;

        &:hover {
            transform: scale(1.1);
        }
    }

    @keyframes gototop {
        0% {
            transform: translateY(-0.5rem);
        }
        100% {
            transform: translateY(1rem);
        }
    }

    @media (max-width: 1024.98px) {
        right: 0;
        left: 85%;
    }
    @media (max-width: 425.98px) {
        right: 0;
        left: 78%;
    }
    @media (max-width: 320.98px) {
        right: 0;
        left: 72%;
    }
`;
