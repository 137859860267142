import React, {useEffect, useState} from 'react';
import {Flex, Upload, UploadProps} from 'antd';
import {
    useMultimediaPictureAddMutation,
    useMultimediaPictureDeleteMutation,
    useMultimediaPictureQuery
} from 'services/company/multimedia/multimedia-picture/multimedia-picture.service';
import {CloseOutlined} from '@ant-design/icons';
import {
    AdsBigFormBlock,
    AdsLabel,
    FileIconContainer,
    FileUploadIcon,
    FileUploadText,
    LoaderBlock,
    StyledBigFileContainer,
    StyledBigFileImg,
    StyledBigFileInputContainer
} from 'components/pages/presentation/form-presentation/upload-big-img/style-upload-big-img';
import {ReactComponent as CameraIcon} from 'assets/main/form/camera.svg';
import {Loader} from "components/pages/account/login/account-form/form-login/style-form";


export const MultimediaPicture: React.FC = () => {
    const [statusCode, setStatusCode] = useState<number>();
    const [imageUrl, setImageUrl] = useState<string | undefined>();
    const {data, error, isLoading, isError, isFetching} = useMultimediaPictureQuery();
    const [multimediaPictureAdd, {
        isLoading: isLoadingGalleryAdd
    }] = useMultimediaPictureAddMutation();
    const [multimediaPictureDelete, {isLoading: isLoadingPictureDelete}] = useMultimediaPictureDeleteMutation();
    const loading = isLoading || isLoadingGalleryAdd || isLoadingPictureDelete || isFetching;

    useEffect(() => {
        if (isError) {
            const err = error as any;
            const status = err.data?.status;
            if (status !== undefined) {
                setStatusCode(status);
            }
        }
    }, [statusCode, isLoadingGalleryAdd, isError, error]);


    const handleChange: UploadProps['onChange'] = async (info) => {
        if (info) {
            const formData = new FormData();
            formData.append('image', info.file.originFileObj as any);
            try {
                await multimediaPictureAdd(formData)
                setStatusCode(200);
            } catch (error) {
                console.error('Failed to upload image', error);
            }

        }
    };


    const handleDelete = async () => {
        try {
            await multimediaPictureDelete({});
            setStatusCode(200);
        } catch (err) {
            console.error('Failed to delete image', err);
        }
    };


    return (
        <AdsBigFormBlock>
            <AdsLabel>Zdjęcie profilowe</AdsLabel>
            {(statusCode === 404 && !imageUrl) ? (
                <>
                    <StyledBigFileInputContainer>
                        <Flex gap="middle" wrap="wrap">
                            <Upload
                                customRequest={async ({file}) => {
                                    const formData = new FormData();
                                    formData.append('image', file);
                                    try {
                                        await multimediaPictureAdd(formData);
                                        setStatusCode(200);
                                    } catch (error) {
                                        console.error('Failed to upload image', error);
                                    }
                                }}
                                onChange={handleChange}
                                showUploadList={false}
                            ></Upload>
                        </Flex>
                        <FileIconContainer>
                            <FileUploadIcon>+</FileUploadIcon>
                            <FileUploadText>Dodaj zdjęcie</FileUploadText>
                        </FileIconContainer>
                        <CameraIcon/>
                    </StyledBigFileInputContainer>
                </>
            ) : (
                <>
                    <StyledBigFileContainer>
                        {loading ? (
                            <LoaderBlock>
                                <Loader/>
                            </LoaderBlock>
                        ) : (
                            data?.link && (
                                <>
                                    <StyledBigFileImg imageUrl={data?.link}/>
                                    <CloseOutlined
                                        onClick={handleDelete}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            zIndex: 3,
                                            cursor: 'pointer',
                                        }}
                                    />
                                </>
                            )
                        )}

                    </StyledBigFileContainer>
                </>
            )}
        </AdsBigFormBlock>
    );
};




