import styled from "styled-components";
import {Rate} from "antd";

export const RatingInformationWrapper = styled.div`
  min-width: 316px;
  @media (max-width: 792.98px) {
    width: 100%;
  }
`;

export const RatingInformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 5px;
  justify-content: space-between;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.08);
  padding: 30px 58px 32px 30px;
  @media (max-width: 792.98px) {
    flex-direction: row;
    padding: 10px 3px;
    justify-content: space-around;
  }
`;


export const TitleBlock = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: 425.98px) {
    flex-direction: column;
    align-self: center;
    text-align: center;
  }
  
`;


export const TitleQuantity = styled.div`
  color: var(--black, #0C1215);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Cormorant Garamond, serif;
  font-size: 65px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: calc(60px + 5 * ((100vw - 480px) / 288));
  }
  @media (max-width: 480px) {
    font-size: 50px;
  }

`;

export const TitleOpinions = styled.div`
  p {
    color: #0C1215;
    font-family: Gilroy, serif;
    font-size: 16px;
    font-weight: 400;
  }
`;


export const StyledRate = styled(Rate)`
  .ant-rate-star {
    color: #1056B2;
  }

  @media (max-width: 792.98px) {
    .ant-rate {
      font-size: 14px;
    }
  }

  .custom-icon .ant-rate-star {
    /* Your custom icon size for the StarsBlock here */
    font-size: 20px; /* For example, setting the icon size to 20px */
  }
`;

export const StarsBlocks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  @media (max-width: 768.98px) {
 gap: 0;
  }

`;

export const StarsBlock = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  p {
    color: #0C1215;
    font-family: Gilroy, serif;
    font-size: 20px;
    font-weight: 400;
    @media (max-width: 792.98px) {
      font-size: 14px;

    }
  }
  


`;
