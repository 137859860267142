import React from 'react';
import {StyledTitle} from "../account/login/account-form/form-login/style-form";
import {FullWrapper} from "../../layout/style-layout";
import {Subscription} from "./subscription/Subscription";
import {Advertisement} from "../account/advertisement/advertisement";
import {Payment} from "./payment/Payment";

export const Advertising = () => {
    return (
        <FullWrapper>
            <StyledTitle>Twoje konto</StyledTitle>
            <Subscription/>
            <Payment/>
            <Advertisement title='Masz wolny termin na wesele?'
                           text='Wprowadź wolne terminy na przyjęcie do swojego kalendarza i sprzedaj je'/>
        </FullWrapper>
    );
};


