import React from 'react';
import {Container, HelpButton, HelpButtonBlock, HelpContent} from "./style-help";
import {ContactsTitle} from "../style-footer-top";
import {ButtonBlock} from "../../../../pages/account/hall/style-hall";

export const Help = () => {
    return (
        <Container>
            <ContactsTitle>Potrzebujesz pomocy?</ContactsTitle>
            <HelpContent>
                W przypadku jakichkolwiek pytań dotyczących prezentacji, poprawienia jej efektywności,
                płatności za abonament jestem do Państwa dyspozycji.
                Zachęcam do kontaktu, na pewno pomogę
            </HelpContent>
            <HelpButtonBlock>
                <HelpButton>Napisz wiadomość</HelpButton>
            </HelpButtonBlock>
        </Container>
    );
};

