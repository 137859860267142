import React from 'react';
import {
    ContactInformationForm
} from "components/pages/informations/informations-forms/contact-information/contact-information-form/ContactInformationForm";
import {useCompanyEditMutation, useCompanyQuery} from "services/company/company.service";

export const ContactInformation = () => {
    const {data, isLoading} = useCompanyQuery();


    const [updatePresentation, {
        data: updateData,
        isLoading: updateLoadingState,
        error,
        isError
    }] = useCompanyEditMutation()


    const handlerInformation = async (args: any) => {
        try {
            console.log(args, 'args')
            await updatePresentation(args)
        } catch (e) {
            console.error(e);
        }
    }


    return (
        <div>
            <ContactInformationForm
                data={data}
                onSubmit={handlerInformation}
                updateLoadingState={updateLoadingState}
                isLoading={isLoading}
                error={error}
                isError={isError}
                // isLoading={isLoading}
            />
        </div>
    );
};

