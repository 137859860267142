import styled from "styled-components";
import React from "react";

interface CustomDivProps extends React.HTMLAttributes<HTMLDivElement> {
    hide?: boolean;
}


export const FeedbackInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 120px;
  @media (max-width: 768.98px) {
    gap: 20px;
    margin-bottom: 20px;
  }
`;

export const InformationBlock = styled.div<CustomDivProps>`
  border-radius: 5px;
  background: #F5F9FF;
  padding: 30px 25px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  ${({ hide }) => hide && "opacity: 0;"};
  @media (max-width: 768.98px) {
    padding: 10px;
  }
`;

export const InformationTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 13px;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 385.98px) {
    align-items: start;
    gap: 7px;
  }
`;

export const InformationIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    color: #0C1215;
    font-family: Gilroy, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const InformationRate = styled.div`
  svg {
    cursor: pointer;
  }
`;

export const InformationText = styled.p`
  color: #0C1215;
  font-family: Gilroy, serif;
  font-size: 16px;
  font-weight: 400;
`;

export const MyResponseBlock = styled.div`
  padding-top: 25px;
`;

export const FormResponse = styled.div`
  margin-top: 20px;
  border-radius: 5px;
  background: #E7ECF3;
  padding: 20px;
  transition: opacity 0.3s ease;

  @media (max-width: 768.98px) {
    padding: 10px;
  }
  
`;




export const FormBlock = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 425.98px) {
    flex-direction: column;
  }

  p {
    color: #0C1215;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
  }

  input {
    flex: 1 1 auto;
    height: 48px;
    border-radius: 5px;
    border: 0.6px solid #D2DAE3;
    background: #FFF;
    padding: 0 10px
  }
`;

export const InformationInput = styled.div`
  padding-top: 12px;

  @media (max-width: 425.98px) {
    padding-top: 0;
  }


`;

export const FormBlockButton = styled.div`
  text-align: end;
  padding-top: 25px;
  margin-bottom: 20px;

  @media (max-width: 425.98px) {
    display: flex;
  }
`;


export const MyResponse = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  margin-bottom: 17px;

  p {
    color: #0C1215;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 600;
  }
`;

export const MyResponseText = styled.p`
  color: #0C1215;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 12px;
`;

export const InformationFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 24px;

  p {
    color: #1056B2;
    font-family: Gilroy, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      text-decoration-line: none;
    }
  }

  svg {
    margin-left: auto;
    cursor: pointer;
    path {
      stroke: rgba(12, 18, 21, 0.2);
    }

    &:hover  path{

      stroke: #1056B2;
    }
  }
`;

export const ShowAll = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: transparent;
    border-bottom: 2px dotted rgba(12, 18, 21, 0.6);
  }
`;

