import React, {useState} from "react";


import {
    Block, ByStatus,
    CartBlock,
    CartWrap, Category,
    DressesTitle,
    DressesTitleBlock,
    DressWrap, ImageBlock, ImageWrap, Price,
    Status,
    StatusBlock
} from "./style-ads-carts";
import {AdsPoints} from "../ads-points/AdsPoints";
import {AdsData} from "../adsDate";

export const AdsCarts = () => {
    const [popup, setPopup] = useState(false);


    const handleTogglePopup = () => {
        setPopup(!popup);
    };

    return (
        <CartWrap>
            <CartBlock>
                {AdsData.map((cart) => {
                    return (
                        <Block key={cart.id}>
                            <ImageBlock>
                                <ImageWrap src={cart.img} alt={cart.name}/>
                            </ImageBlock>
                            <StatusBlock>
                                <Category>{cart.type}</Category>
                                <Status>{cart.status}</Status>
                            </StatusBlock>
                            <DressWrap>
                                <DressesTitleBlock>
                                    <DressesTitle>{cart.name}</DressesTitle>
                                    <AdsPoints expensesId={cart.id}/>
                                </DressesTitleBlock>
                                <ByStatus>{cart.byStatus}</ByStatus>
                            </DressWrap>
                            <Price>{cart.priceLocal}</Price>
                        </Block>
                    )
                })}
            </CartBlock>
        </CartWrap>
    )
};


