import styled from 'styled-components';

export const CompareDatesWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 1168.98px) {
    flex-direction: column;

  }
`;

export const DateItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  @media (max-width: 1512.98px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 522.98px) {
    flex-direction: row;
    gap: 10px 10px;
    justify-content: center;
  }
`;

export const DateBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  

  @media (max-width: 522.98px) {
    flex-direction: column;
  }

  p {
    color: rgba(12, 18, 21, 0.60);
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    white-space:nowrap;
    opacity: 0.800000011920929;
  }
`;

export const DateButtonBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 522.98px) {
    flex-direction: column;
  }
`;

export const DateTitle = styled.div`
  color: #0C1215;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 425.98px) {
    display: flex;
    align-items: center;
  }
`;