import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {AvatarImage, AvatarWrapper, Dropdown, DropdownItem, DropdownTail, LogoutItem} from "./style-avatar";
import {useAppSelector} from "common/hook";
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";


interface UserProps {
    userId: string;
    size?: number;
}


export const Avatar: React.FC<UserProps> = ({userId, size = 36}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const avatarUrl = useAppSelector((state) => state.app.avatar);

    const cookies = new Cookies();
    const navigate = useNavigate()
    const handleLogout = () => {
        cookies.remove('jwt_authorization');
        cookies.remove('company_id');
        // // Удаляем куки с сохраненным email и паролем
        // cookies.remove('rememberedEmail');
        // cookies.remove('rememberedPassword');
        // cookies.remove('rememberMe');
        if (!cookies.get('jwt_authorization')) {
            navigate('/login');
        }
    };


    return (
        <AvatarWrapper size={size} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {avatarUrl && <AvatarImage src={avatarUrl} alt="Avatar" />}
            <Dropdown isOpen={isDropdownOpen}>
                <DropdownItem to="/information">Twoje informacje</DropdownItem>
                <LogoutItem onClick={handleLogout}>Wyloguj</LogoutItem>
                <DropdownTail />
            </Dropdown>
        </AvatarWrapper>
    );
};
