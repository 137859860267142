import React, {useEffect} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import {MenuItem, MenuList, NavigateWrap} from './style-navigate';
import {useAppDispatch, useAppSelector} from "common/hook";
import {setOpenBurgerAC} from "app/app-reduser";
import {HeaderImage, PageWrapNav} from "../header/style-header";
import logo from '../../../assets/header/logo.svg'
import {ReactComponent as LineIcons} from '../../../assets/header/line.svg';
import {menuItems} from "components/layout/navigation/index";


export const Navigate = () => {
    const dispatch = useAppDispatch();
    const openBurger = useAppSelector(state => state.app.isOpenBurger)
    const location = useLocation();
    const onClickHandler = () => {
        dispatch(setOpenBurgerAC(false))
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <NavigateWrap>
            <PageWrapNav>
                <Link to="/">
                    <HeaderImage>
                        <img src={logo} alt="Logo"/>
                    </HeaderImage>
                </Link>
                <LineIcons/>
            </PageWrapNav>
            <MenuList isOpenBurger={openBurger}>
                {menuItems.map((item, index) => (
                    <MenuItem key={index} className={location.pathname === item.path ? 'active' : ''}>
                        <NavLink to={item.path} onClick={onClickHandler} end={true}>
                            {item.icon}
                            <span>{item.title}</span>
                        </NavLink>
                    </MenuItem>
                ))}
            </MenuList>
        </NavigateWrap>
    );
};
