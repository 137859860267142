import styled from "styled-components";


export const PaymentWrap = styled.div`
  display: flex;
  flex-direction: column;
  background: #F5F9FF;
  padding: 30px 0 50px 40px;
  border-radius: 5px;
  margin-bottom: 120px;


  @media (max-width: 773.98px) {
    padding: 40px 20px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  @media (max-width: 1297.98px) {
    overflow-x: auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(12, 18, 21, 0.5);
  text-align: left;
  padding-bottom: 35px;

  & + & {
    padding-left: 10px;
  }
`;

export const TableRow = styled.tr`

`;

export const TableBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const TableName = styled.td`
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: var(--black);
`;

export const TableActive = styled.td`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(12, 18, 21, 0.5);
`;

export const TableCell = styled.td`
  padding-bottom: 41px;

  & + & {
    padding-left: 15px;
  }
`;


export const CompleteContainer = styled.div`
  padding-top: 30px;
  border-top: 1px solid #D2DAE3;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 648.98px) {
    gap: 20px;
  }
`;

export const CompleteItems = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  @media (max-width: 648.98px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

export const CompleteItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;

`;

export const CompleteItemRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  padding-right: 40px;
  @media (max-width: 398.98px) {
    padding-right: 0;
  }
`;


export const CompleteItemHeader = styled.p`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(12, 18, 21, 0.5);
`;


export const CompleteInfo = styled.p`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--black);
`;

export const CompletePrise = styled.p`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  letter-spacing: -0.02em;

  color: var(--black);
`;

export const CompleteIcons = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;

  a {
    display: inline-block;
    transition: transform 0.3s ease;
  }

  a:hover {
    transform: scale(1.2);
  }
`;

export const TableBlocks = styled.div`
  display: flex;
  gap: 15px;
  align-items: baseline;
`;

export const BlockInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: start;
`;

export const Checkbox = styled.input`
  cursor: pointer;
`;

export const Container = styled.div`
  position: relative;
`;

export const PlusButton = styled.div`
  cursor: pointer;
`;

export const DropdownMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9;

  ${Container}:hover & {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  }
`;

export const MenuItem = styled.p`
  width: 360px;
  padding: 11px 17px;
  cursor: pointer;
  font-family: 'Gilroy',sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: rgba(12, 18, 21, 0.5);
`;