import { z } from 'zod';

// Схема для валидации пароля

export const passwordSchema = z.object({
    oldPassword: z.string().min(3, { message: "Hasło musi składać się z co najmniej 6 znaków" }).max(30, { message: "Hasło nie może mieć więcej niż 30 znaków" }),
    password: z.string().min(3, { message: "Hasło jest za krótkие" }).max(30, { message: "Hasło jest zbyt długie" }),
    retypePassword: z.string().min(3, { message: "Hasło jest za krótkие" }).max(30, { message: "Hasło jest zbyt długie" }),
}).refine(data => data.password === data.retypePassword, {
    message: "Passwords do not match",
    path: ['retypePassword']
});
