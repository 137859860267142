import styled from 'styled-components';


export const TabsWrap = styled.div`
  overflow: auto;
  margin: 0 -10px -20px;
  padding-bottom: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    height: 2px; 
    background-color: #D2DAE3;
    max-width: 1242px;
    width: 100%;
   }
 
`;

export const TabsContainer = styled.div`
  position: relative;
  display: flex;
  transition:0.3s ease;
`;

export const TabNavItem = styled.span`
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 0 10px 15px 10px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.2s ease;
  text-align: center;
  min-width: 145px;
  &.active {
    box-shadow: 0px -2px 0px 0px #1056B2 inset;
    opacity: 1;
    cursor: default;
  }

  .no-touchevents &:not(.active):hover {
    opacity: 1;
  }
`;