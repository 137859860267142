import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding: 10px 42px 0 0;
  border-right: 1px solid #DEE2E8;

  @media (max-width: 773.98px) {
    border-right: none;
    border-bottom: 1px solid #DEE2E8;
    padding: 0 0 20px 0;
  }
  
`;


export const ContainerBlock = styled.div`
    display: flex;
  flex-direction: column;
  gap: 5px;
`;


export const Name = styled.p`
  font-family: 'Gilroy',sans-serif;
  font-weight: 500;
  //font-size: 18px;
  line-height: 140%;
  color: var(--white);
  font-size: calc(15px + 3 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(15px + (15 + 3 * 0.7) * ((100vw - 320px) / 1480));
  }
  
`;

export const Phone = styled.a`
  font-family: 'Gilroy',sans-serif;
  font-weight: 300;
  //font-size: 18px;
  line-height: 21px;
  color: var(--white);
  font-size: calc(15px + 3 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(15px + (15 + 3 * 0.7) * ((100vw - 320px) / 1480));
  }
`;

export const Email = styled.a`
  font-family: 'Gilroy', sans-serif;
  font-weight: 300;
  //font-size: 18px;
  line-height: 21px;
  color: var(--white);
  font-size: calc(15px + 3 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(15px + (15 + 3 * 0.7) * ((100vw - 320px) / 1480));
  }
`;

