import React from 'react';

import {AdsEdit} from "./ads-edit/AdsEdit";
import {AdsDelete} from "./ads-delete/AdsDelete";
import {PointsAdsPopupWrap} from "../../style-ads";


interface IExpensesId {
    expensesId: string;
}

export const AdsPinterPopup = ({ expensesId }: IExpensesId) => {
    return (
        <PointsAdsPopupWrap>
            <AdsEdit expensesId={expensesId} show={false}/>
            <AdsDelete expensesId={expensesId} show={false}/>
        </PointsAdsPopupWrap>
    );
};