import React from 'react';
import {BoldOutlined, CopyOutlined, ItalicOutlined, UnderlineOutlined} from '@ant-design/icons';
import {message} from "antd";
import {
    ToggleGroupButton,
    ToggleGroupButtonWrap
} from "components/pages/presentation/form-presentation/toggle-group/style-toggle-group";

interface CustomToolbarProps {
    editorHtml: string;
}

export const CustomToolbar: React.FC<CustomToolbarProps> = ({editorHtml}) => {
    const handleCopy = () => {
        const plainText = editorHtml.replace(/<[^>]+>/g, '');
        navigator.clipboard.writeText(plainText)
            .then(() => {
                message.success('Tekst został skopiowany pomyślnie!');
            })
            .catch((error) => {
                console.error('Błąd podczas kopiowania tekstu:', error);
                message.error('Błąd podczas kopiowania тексту!');
            });
    };

    return (

        <ToggleGroupButtonWrap id="toolbar">
            <ToggleGroupButton className="ql-bold">
                <BoldOutlined/>
            </ToggleGroupButton>
            <ToggleGroupButton className="ql-italic">
                <ItalicOutlined/>
            </ToggleGroupButton>
            <ToggleGroupButton className="ql-underline">
                <UnderlineOutlined/>
            </ToggleGroupButton>
            {/*<ToggleGroupButton className="ql-link">*/}
            {/*    <LinkOutlined/>*/}
            {/*</ToggleGroupButton>*/}
            <select className="ql-size">
                <option value="small"/>
                <option value="large"/>
                <option value="huge"/>
            </select>

            <ToggleGroupButton onClick={handleCopy}>
                <CopyOutlined/>
            </ToggleGroupButton>

            {/*<ToggleGroupButton className="ql-clean">*/}
            {/*    <ClearOutlined/>*/}
            {/*</ToggleGroupButton>*/}
        </ToggleGroupButtonWrap>
    );
};







