import React from 'react';
import {FullWrapper} from "../../layout/style-layout";
import {Advertisement} from "../account/advertisement/advertisement";
import {FeedbackBlock} from "./feedback-block/FeedbackBlock";

export const Feedback = () => {
    return (
        <FullWrapper>
            <FeedbackBlock/>
            <Advertisement title='Zwiększ zasięg reklamy?'
                           text='Wybierz odpowiedni plan taryfowy i wypromuj swoją firmę'/>
        </FullWrapper>
    );
};

