import {baseApi} from "../base-api";
import Cookies from "universal-cookie";
import {ContactResponse} from "services/contact/contact.types";



const cookies = new Cookies();

const getToken = () => {
    const token = cookies.get('jwt_authorization');
    if (!token) {
        throw new Error('Токен авторизации не найден в куки');
    }
    return token;
}


export const contactService = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        contact: builder.query<ContactResponse, void>({
            providesTags: ['Contact'],
            query: () => {
                const token = getToken();
                return {
                    method: 'GET',
                    url: `/auth/me/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        contactEdit: builder.mutation<any, any>({
            invalidatesTags: ['Company'],
            query: (body) => {
                const token = getToken();
                return {
                    body,
                    method: 'POST',
                    url: `/auth/me/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
    }),
});

export const {useContactQuery,useContactEditMutation} = contactService;

