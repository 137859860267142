import styled from "styled-components";

export const SubscriptionWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 50px;
`;

export const SubscriptionBlock = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  @media (max-width: 476.98px) {
    flex-direction: column;
    align-items: flex-start;
  }
  p {
    font-family: 'Gilroy',sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: var(--black);
  }
  span {
    font-family: 'Gilroy',sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: rgba(12, 18, 21, 0.5);
  }
`;