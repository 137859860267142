import React, {ReactNode} from 'react';
import {Popup} from "components/pages/presentation/form-presentation/faq/faq-popup/popups";
import {Portal} from "../portal/Portal";



interface PopupsProps {
    show: boolean;
    handleClosePopups: () => void;
    onClickClosest: () => void;
    formComponent: ReactNode;
}

export const Popups = ({ show, onClickClosest, formComponent }: PopupsProps) => {
    return (
        <Portal>
            <Popup show={show} onClick={onClickClosest}>
                {formComponent}
            </Popup>
        </Portal>
    );
}
