import React from 'react';
import {ReactComponent as EyeIcon} from '../../../../assets/main/statistics/svg/eye.svg';
import {ReactComponent as PhoneIcon} from '../../../../assets/main/statistics/svg/phone.svg';
import {ReactComponent as QuestionIcon} from '../../../../assets/main/statistics/svg/question.svg';
import {ReactComponent as EmailIcon} from '../../../../assets/main/statistics/svg/email.svg';
import {ReactComponent as WebsiteIcon} from '../../../../assets/main/statistics/svg/website.svg';
import {ReactComponent as FacebookIcon} from '../../../../assets/main/statistics/svg/facebook.svg';
import {ReactComponent as InstagramIcon} from '../../../../assets/main/statistics/svg/instagram.svg';
import {ReactComponent as YouTubeIcon} from '../../../../assets/main/statistics/svg/youTube.svg';
import eyeImage from "../../../../assets/main/statistics/img/eye.png";
import phoneImage from "../../../../assets/main/statistics/img/phone.png";
import questionImage from "../../../../assets/main/statistics/img/question.png";
import emailImage from "../../../../assets/main/statistics/img/email.png";
import websiteImage from "../../../../assets/main/statistics/img/website.png";
import facebookImage from "../../../../assets/main/statistics/img/facebook.png";
import instagramImage from "../../../../assets/main/statistics/img/instagram.png";
import youTubeImage from "../../../../assets/main/statistics/img/youTube.png";



import {
    Container,
    StatisticsBlock, StatisticsBlockTitle,
    StatisticsItems,
    StatisticsNumber,
    StatisticsText,
    StatisticsTitle
} from "./style-statistics";
import {InfoContentBlock, InspirationsLink} from "../../ads/style-ads";
import {Link} from "react-router-dom";
import {ReactComponent as Expand} from '../../../../assets/main/abs/expand_more.svg';



const menuItems = [
    {
        icon: <EyeIcon/>,
        imageUrl:eyeImage,
        number: '254668',
        subTitle: 'Ilość wyświetleń prezentacji',
    },
    {
        icon: <PhoneIcon/>,
        imageUrl:phoneImage,
        number: '1036',
        subTitle: 'Ilość wyświetleń numeru telefonu',
    },
    {
        icon: <QuestionIcon/>,
        imageUrl:questionImage,
        number: '235',
        subTitle: 'Ilość wysłanych zapytań',
    },
    {
        icon: <EmailIcon/>,
        imageUrl:emailImage,
        number: '12',
        subTitle: 'Opinie',
    },
    {
        icon: <WebsiteIcon/>,
        imageUrl:websiteImage,
        number: '1775',
        subTitle: 'Przejść na stronę www',
    },
    {
        icon: <FacebookIcon/>,
        imageUrl:facebookImage,
        number: '16',
        subTitle: 'Przejść na Facebook',
    },
    {
        icon: <InstagramIcon/>,
        imageUrl:instagramImage,
        number: '61',
        subTitle: 'Przejść na Instagram',
    },
    {
        icon: <YouTubeIcon/>,
        imageUrl:youTubeImage,
        number: '15',
        subTitle: 'Przejść na YouTube',
    },

];


export const Statistics = () => {
    return (
        <Container>
            <StatisticsBlockTitle>
                <StatisticsTitle>
                    Statystyki Twojej prezentacji
                </StatisticsTitle>

                <InspirationsLink style={{paddingRight:'33px', paddingBottom:'0'}}>
                    <Link to="/statistics?tab=0">
                        <InfoContentBlock><span style={{fontSize:'16px'}}>Szczegółowe Statystyki</span><Expand/></InfoContentBlock>
                    </Link>
                </InspirationsLink>

            </StatisticsBlockTitle>
            <StatisticsItems>
                {menuItems.map((el, index) => {
                    return (
                        <StatisticsBlock key={index} imageUrl={el.imageUrl} index={index}>
                            <span>{el.icon}</span>
                            <StatisticsNumber>{el.number}</StatisticsNumber>
                            <StatisticsText>{el.subTitle}</StatisticsText>
                        </StatisticsBlock>
                    );
                })}
            </StatisticsItems>
        </Container>
    );
};
