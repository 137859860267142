import React from 'react';
import {Outlet} from 'react-router-dom';
import {Header} from "./header/Header";
import {useLocation} from 'react-router-dom';
import {
    MainWrapper,
    NavigateMainWrap,
    OutletContainer,
    OutletWrapper,
    StyledContainer,
    StyleWrapper
} from "./style-layout";
import {Navigate} from "./navigation/Navigate";
import {Footer} from "./footer/Footer";
import {GoToTop} from "common/goToTop/GoToTop";
import usePopup from "common/hook/use-popup";

export const Layout = () => {
    const location = useLocation();
    const path = location.pathname;

    return (
        <StyleWrapper>
            <Header/>
            <GoToTop />
            <MainWrapper>
                <StyledContainer>
                    <NavigateMainWrap>
                        <Navigate/>
                        <OutletWrapper background={path === '/' ? '#f5f9fc' : ''}
                                       padding={path === '/presentation' ? '70px' : '124px'}>
                            <OutletContainer>
                                <Outlet/>
                            </OutletContainer>
                            {path === '/adsplace' || path === '/ads'  ?
                                <></> :
                                <Footer/>
                            }
                        </OutletWrapper>
                    </NavigateMainWrap>
                </StyledContainer>
            </MainWrapper>
        </StyleWrapper>
    );
};



