import styled from "styled-components";

export const Container = styled.div`
  padding: 30px 25px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 814.98px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

export const LeftBlock = styled.div`
  font-family: 'Gilroy', sans-serif;
  color: var(--black);
  font-size: 14px;
  display: flex;
  gap: 52px;

  @media (max-width: 1078.98px) {
    gap: 10px;
  }
  @media (max-width: 814.98px) {
    gap: 30px;
  }

  @media (max-width: 460.98px) {
    flex-direction: column-reverse;
    text-align: center;
  }

  p {
    font-weight: 600;
    line-height: 17px;
  }

  span {
    font-weight: 500;
    line-height: 16px;
    border-bottom: 1px solid rgba(12, 18, 21, 0.4);

  }
`;
export const RightBlock = styled.nav`
  ul {
    display: flex;
    gap: 24px;
    @media (max-width: 375.98px) {
      gap: 10px;
    }
  }

  @media (max-width: 1078.98px) {
    gap: 10px;
  }

  li {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: var(--black);
    border-bottom: 1px solid rgba(12, 18, 21, 0.4);
    transition: border-color 0.3s;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s;
  }

  li:hover {
    border-color: rgba(12, 18, 21, 1);
  }

  a:hover {
    color: var(--primary-color);
  }
`;

