import React from "react";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {z} from "zod";
import sing from "assets/sing/sing.png";
import singLogoIcon from "assets/sing/sing-logo.svg";
import {Link} from "react-router-dom";
import {ReactComponent as ArrayLeftIcon} from '../../../../assets/sing/array.svg';
import {
    ForgetText,
    ForgotContainer, ForgotLink,
    ForgotTextBlock,
    ForgotTitle
} from "components/pages/forgot-password/forgot/style-forgot";
import {FormBlock, StyledInput, StyledLabel} from "components/pages/account/login/account-form/form-login/style-form";
import {
    SharingArrayLeftBlock,
    SingInButton,
    SingInContainer, SingInForm, SingInFormContainer,
    SingInIcon,
    SingInImg,
    SingInImgContainer
} from "components/pages/sign-in-page/sing-in/style-sing-in";
import {CalendarText} from "components/pages/ads/style-ads";


interface ForgotProps {
    onSubmit: (handlerForgot: FormValues) => void
}


const forgotSchema = z.object({
    email: z.string().email({message: "Nieprawidłowy adres email"}),
});


type FormValues = z.infer<typeof forgotSchema>

export const Forgot = ({onSubmit}: ForgotProps) => {

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<FormValues>({
        resolver: zodResolver(forgotSchema),
        defaultValues: {
            email: '',
        }
    })

    return (
        <SingInContainer>
            <SingInImgContainer>
                <SingInImg src={sing} alt="sing"/>
                <SingInIcon src={singLogoIcon} alt="icon"/>
            </SingInImgContainer>
            <SingInFormContainer>
                <SingInForm onSubmit={handleSubmit(onSubmit)}>
                    <ForgotContainer>
                        <ForgotTextBlock>
                            <ForgotTitle>
                                Odzyskiwanie hasła
                            </ForgotTitle>
                            <ForgetText>
                                Wpisz swój adres e-mail, a my wyślemy dalsze instrukcje dotyczące przywrócenia konta
                            </ForgetText>
                        </ForgotTextBlock>
                    </ForgotContainer>
                    <FormBlock>
                        <StyledLabel htmlFor="email">E-mail</StyledLabel>
                        <StyledInput type="email" id="email" {...register("email")} />
                        <p style={{color: "red"}}>{errors.email?.message}</p>
                    </FormBlock>
                    <SingInButton type="submit">
                        <CalendarText>
                            Potwierdzać
                        </CalendarText>
                    </SingInButton>
                    <ForgotLink>
                        <Link to='/login'>
                            <SharingArrayLeftBlock>
                                <ArrayLeftIcon/>
                                <span>Wróć</span>
                            </SharingArrayLeftBlock>
                        </Link>
                    </ForgotLink>
                </SingInForm>
            </SingInFormContainer>
        </SingInContainer>
    )
}
