import styled from "styled-components";
import right from '../../../../assets/main/abs/cart/array-right.svg'

export const StatusBlock = styled.div`
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`

export const Status = styled.span`
  font-family: 'Gilroy',sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: right;
  letter-spacing: 0.01em;
  color: #0C1215;
  opacity: 0.5;
`
export const DressWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const DressesTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
  max-width: 368px;
  
  svg {
    width: 8px;
    height: 14px;
  }
`
export const DressesCarTitleBlock = styled.div`
  display: flex;
  align-items: baseline;
  gap: 20px;
  
  svg {
    width: 13px;
    height: 14px;
    stroke-width: 1.4px;
    stroke: #0C1215;
    opacity: 0.8;
  }
`


export const DressesTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  position: relative;
  cursor: pointer;
  max-width: 320px;
  width: 100%;

  &:hover::before {
    content: "";
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 10px;
    background: url(${right}) no-repeat;
    background-size: cover;
  }
`;

export const CartWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 60px;
  @media (max-width: 682.98px){
    padding-top: 20px;
    gap: 60px;
  }
`;

export const CartBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 20px;
  
  @media (max-width: 682.98px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px 20px;
  }
  @media (max-width: 420.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 374px;
`;

export const ImageBlock = styled.div`
  max-width: 374px;
  width: 100%;
`;

export const ImageWrap = styled.img`
  width: 100%;
  max-width: 100%;
  filter: drop-shadow(0px 6.05348px 45.4011px rgba(11, 62, 138, 0.08));
  border-radius: 5px;
`;

export const Category = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  padding: 8px 12px;
  gap: 10px;
 //width: 80px;
  height: 30px;
  background: #1056B2;
  border-radius: 20px;
`;

export const ByStatus = styled.p`
  color:  #1056B2;
  text-align: left;
  font-family: Gilroy,sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; 
  letter-spacing: 0.12px;
  
`

export const Price = styled.p`
  font-family: 'Cormorant Garamond',serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #0C1215;
  opacity: 0.5;
`;