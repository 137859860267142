import React, {FC, useEffect, useRef, useState} from 'react';
import {
    ContHeg50, ContListSelectMate,
    ContSelectCenter, ContSelectInt,
    IconSelectMate,
    SelecionadoOpcion,
    Select,
    SelectMate
} from "./style-question-select";
import {ReactComponent as Array} from '../../assets/main/form/selectArray.svg';


export interface Option {
    name: string;
    value: string;
    checked?: boolean;
}


interface SelectBoxProps {
    name: string;
    options: Option[];
}


export const QuestionSelect: FC<SelectBoxProps> = ({ name, options }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectOpen, setSelectOpen] = useState(false);
    const [showEllipsis, setShowEllipsis] = useState(false);
    const selectRef = useRef<HTMLSelectElement | null>(null);

    const handleClickOption = (index: number) => {
        setSelectedIndex(index);
        setSelectOpen(false);
    };

    const handleToggleSelect = () => {
        setSelectOpen((prevState) => !prevState);
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const index = event.target.selectedIndex;
        setSelectedIndex(index);
    };

    useEffect(() => {
        if (selectRef.current) {
            const selectHeight = selectRef.current.clientHeight;
            const selecionadoOpcionHeight = selectRef.current.querySelector('.selecionado-opcion')?.clientHeight;

            if (selecionadoOpcionHeight && selecionadoOpcionHeight > selectHeight) {
                setShowEllipsis(true);
            } else {
                setShowEllipsis(false);
            }
        }
    }, [selectOpen]);

    return (
        <ContSelectCenter>
            <ContHeg50 />
            <SelectMate data-mate-select="active">
                <Select name={name} onChange={handleSelectChange} onClick={(e) => e.preventDefault()} ref={selectRef}>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </Select>
                <SelecionadoOpcion onClick={handleToggleSelect}>
                    {showEllipsis ? '...' : options[selectedIndex].name}
                </SelecionadoOpcion>
                <IconSelectMate onClick={handleToggleSelect}>
                    <Array />
                </IconSelectMate>
                <ContListSelectMate>
                    <ContSelectInt style={{ height: selectOpen ? 'auto' : 0 }}>
                        {options.map((option, index) => (
                            <li
                                key={index}
                                className={index === selectedIndex ? 'active' : ''}
                                onClick={() => handleClickOption(index)}
                            >
                                {option.name}
                            </li>
                        ))}
                    </ContSelectInt>
                </ContListSelectMate>
            </SelectMate>
        </ContSelectCenter>
    );
};