import React from "react";
import {createBrowserRouter, Navigate, RouteObject, RouterProvider} from 'react-router-dom'

import {Layout} from "components/layout/Layout";
import {Account} from "components/pages/account/Account";
import {Advertising} from "components/pages/advertising/Advertising";
import {Calendar} from "components/pages/сalendar/Calendar";
import {Feedback} from "components/pages/feedback/Feedback";
import {Ads} from "components/pages/ads/Ads";
import {AdsPlace} from "components/pages/ads/ads-place/AdsPlace";
import {Banners} from "components/pages/banners/Banners";
import {Statistics} from "components/pages/statistics/Statistics";
import {ForgotPassword} from "components/pages/forgot-password/forgot-password";
import {PasswordChange} from "components/pages/password-change/password-change";
import {SignInPage} from "components/pages/sign-in-page/sign-in-page";
import {useMeQuery} from "services/auth/auth.service";
import {Confirmation} from "components/pages/password-change/confirmation/Confirmation";
import {Presentation} from "components/pages/presentation/Presentation";
import {Information} from "components/pages/informations/information";


const publicRoutes: RouteObject[] = [
    {
        path: '/login',
        element: <SignInPage/>,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword/>,
    },
]


const privateRoutes: RouteObject[] = [
    {
        path: '/',
        element: <Account/>,
    },
    {
        path: '/advertising',
        element: <Advertising/>,
    },
    {
        path: '/statistics',
        element: <Statistics/>,
    },
    {
        path: '/calendar',
        element: <Calendar/>,
    },
    {
        path: '/feedback',
        element: <Feedback/>,
    },
    {
        path: '/presentation',
        element: <Presentation/>,
        // element: <MyComponent/>,
    },
    {
        path: '/ads',
        element: <Ads/>,
    },

    {
        path: '/adsplace',
        element: <AdsPlace/>,
    },
    {
        path: '/banners',
        element: <Banners/>,
    },
    {
        path: '/password-change',
        element: <PasswordChange/>,
    },
    {
        path: '/confirmation',
        element: <Confirmation/>,
    },
    {
        path: '/information',
        element: <Information/>,
    },
]


const router = createBrowserRouter([
    {
        element: <PrivateRoutes/>,
        children: privateRoutes,
    },
    ...publicRoutes,
])

export const Router = () => {
    return <RouterProvider router={router}/>
}


function PrivateRoutes() {
    const {isLoading, isError } = useMeQuery()

    if (isLoading) {
        return null
    }

    const isAuthenticated = !isError

    if (!isAuthenticated) {
        return <Navigate to="/login"/>;
    }

    return <Layout/>;
}
