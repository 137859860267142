import React from 'react';
import {
    RatingInformationBlock,
    RatingInformationWrapper,
    StarsBlock,
    StarsBlocks,
    StyledRate,
    TitleBlock,
    TitleOpinions,
    TitleQuantity
} from "./style-rating-information";

export const RatingInformation = () => {
    return (
        <RatingInformationWrapper>
            <RatingInformationBlock>
                <TitleBlock>
                    <TitleQuantity>4.8</TitleQuantity>
                    <TitleOpinions>
                        <StyledRate allowHalf disabled defaultValue={4.8}/>
                        <p>50 opinii</p>
                    </TitleOpinions>
                </TitleBlock>
                <StarsBlocks id='stars'>
                    <StarsBlock>
                        <StyledRate allowHalf disabled defaultValue={5}/>
                        <p>95%</p>
                    </StarsBlock>
                    <StarsBlock>
                        <StyledRate allowHalf disabled defaultValue={4}/>
                        <p>5%</p>
                    </StarsBlock>
                    <StarsBlock>
                        <StyledRate allowHalf disabled defaultValue={3}/>
                        <p>0%</p>
                    </StarsBlock>
                    <StarsBlock>
                        <StyledRate allowHalf disabled defaultValue={2}/>
                        <p>0%</p>
                    </StarsBlock>
                    <StarsBlock>
                        <StyledRate allowHalf disabled defaultValue={1}/>
                        <p>0%</p>
                    </StarsBlock>
                </StarsBlocks>
            </RatingInformationBlock>
        </RatingInformationWrapper>
    );
};

