import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TabsState {
    activeTab: number;
}

const initialState: TabsState = {
    activeTab: 0,
};

const slice = createSlice({
    name: 'tabs',
    initialState,
    reducers: {
        setActiveTab: (state, action: PayloadAction<number>) => {
            state.activeTab = action.payload;
        },
    },
});

export const tabsReducer = slice.reducer
export const {setActiveTab} = slice.actions

