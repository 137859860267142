import {z} from 'zod';

export const ContactInformationSchema = z.object({
    contact_phone: z.string()
        .nonempty({message: "Numer telefonu jest wymagany"})
        .regex(/^(\+?48)? ?(\d{3} ?){2}\d{3}$/, {message: "Nieprawidłowy numer telefonu"}),
    contact_email: z.string()
        .nonempty({message: "Adres email jest wymagany"})
        .email({message: "Nieprawidłowy adres email"}),
    contact_last_name: z.string().optional(),
    street: z.string().optional(),
    contact_city: z.string().optional(),
    contact_address_1: z.string().optional(),
    contact_company_nip: z.string().optional(),
    contact_postal_code: z.string().optional(),
});

export type InformationFormValues = z.infer<typeof ContactInformationSchema>;
