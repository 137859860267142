import styled from "styled-components";


export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -4px;
  right: 4px;
  background: white;

  @media (max-width:  425.98px) {
    top: -29px;
    right: 6px;
  }
`;

export const CloseButtonTwo = styled.button`
  position: absolute;
  top: -38px;
  right: 4px;
  background: white;

  @media (max-width:  425.98px) {
    top: -29px;
    right: 6px;
  }
`;


export const StyledTitle = styled.p`
  color: var(--black, #0C1215);
  text-align: center;
  font-family: Gilroy;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.3px;
  max-width: 416px;
  line-height: normal;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    padding: 0 15px;
  }
`;

export const FormBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormBlockText = styled.div`
  color: var(--black, #0C1215);
  text-align: center;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  opacity: 0.5;
`;

export const ButtonBlock = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

export const WrapButtonBlock = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  @media (max-width: 449.98px){
    flex-direction: column;
    //padding: 0 30px;
  }
`;

export const TextAreaBlock = styled.div`
  width: 420px;
  max-width: 100%; 
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;

  textarea {
    width: 100%;
  }

    @media (max-width: 449.98px){
        width: 100%;
    }
`;


export const ButtonSubmit = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 12px 30px;
  background: #1056B2;
  border-radius: 100px 208px 0 100px;

  span {
    color: var(--white, #FFF);
    font-family: Gilroy,sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.84px;
    text-transform: uppercase;
  }

  &:hover,
  &:focus {
    background-color: #1976d2;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
