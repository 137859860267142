import styled from 'styled-components';


interface CustomDateCellProps {
    isHighlighted: boolean;
    isAvailable: boolean;
    isPast: boolean;
}


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  margin-bottom: 121px;

  @media (max-width: 773.98px) {
    margin-bottom: 20px;
  }
`;


export const MonthBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1056B2;
  text-align: center;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
`;


export const CalendarWrapper = styled.div`
  padding: 8px;
  width: 252px;
  @media (max-width: 425.98px) {
    width: 100%;
  }
`;

export const StyledCalendarWrapper = styled(CalendarWrapper)`
  .ant-picker-calendar {
    background: none;
  }

  .ant-picker-body {
    //background-color: rgb(245, 245, 245);
  }

  .ant-picker-calendar .ant-picker-panel {
    border-top: none;
  }

  .ant-picker-calendar .ant-picker-cell {
    padding: 0;
  }
  
  //.ant-popover-open {
  //  display: none;
  //}

  .ant-picker-content {

    tr {
      color: var(--black, #0C1215);
      text-align: center;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
    }

    th {
      width: 36px;
      height: 36px;
      padding: 6px 13px 12px 12px;
      opacity: 0.5;
    }

  }
`;

export const CurrentDayStyle = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--border, #D2DAE3);
  background: rgb(255, 255, 255);
`;


export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 1394.98px) {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }

  @media (max-width: 1110.98px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1001.98px) {
    grid-template-columns: repeat(3, 1fr);

  }

  @media (max-width: 860.98px) {
    grid-template-columns: repeat(2, 1fr);

  }

  @media (max-width: 552.98px) {
    grid-template-columns: 1fr;
  }
`;

export const CustomDateCell = styled.div<CustomDateCellProps>`
  position: relative;
  width: 36px;
  height: 36px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.isHighlighted ? '#1056B2' : props.isPast ? 'white' : props.isAvailable ? 'white' : 'transparent'};
  border-radius: 100px 0 100px 100px;
  color: ${(props) => props.isHighlighted ? 'white' : props.isAvailable || props.isPast ? '#0C1215' : ''};
`;


export const WhiteDot = styled.svg`
  position: absolute;
  top: 5px;
  right: 4px;
  width: 6px;
  height: 6px;
  fill: white;
`;