import styled from "styled-components";

export const BannersWrap = styled.section`
  display: flex;
  flex-direction: column;
  gap: 120px;
  margin-bottom: 120px;

  @media (max-width: 1000.98px) {
    margin-bottom: 60px;
    gap: 60px;
  }
`;