import styled from "styled-components";


export const AdsPlaceWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 100px;
  padding-top: 110px;
  padding-left: 30px;
  margin-top: -124px;
  margin-right: -30px;
background-color: white;
  @media (max-width: 1000.98px) {
    padding-top: 100px;
    width: 100%;
    padding-left: 0;
  }

`


export const AdsFormWrapper = styled.div`
background-color: white;
  form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    grid-auto-flow: dense;
    justify-content: center;
    //padding-top: 30px;
    position: relative;
    background-color: white;
  }
`;
export const AdsPlaceFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  background-color: #fff;
`;


export const AdsPlaceButtonBlock = styled.div`
  display: flex;
  gap: 14.37px;
  padding-top: 10px;
  margin-bottom: 18px;
  width: 875px;
  @media (max-width: 1023.98px) {
    width: 100%;
  }
  @media (max-width: 497.98px) {
    flex-direction: column;
  }
`


export const AdsButtonSubmit = styled.button`
  max-width: 416px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 12px 30px;
  background: #1056B2;
  border-radius: 200px 100px 100px 0;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }

  &:hover,
  &:focus {
    background-color: #1976d2;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

`;

export const AdsButtonSubmitApproval = styled.button`
  max-width: 416px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 12px 30px;
  border-radius: 200px 100px 100px 0px;
  border: 1px solid #D2DAE3;
  opacity: 0.8;
  background: #FFF;


  span {
    color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover,
    &:focus {
      color: #1056B2;
    }
  }


  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

`;


export const AdsPlaceListContainer = styled.div`
  background-color: #fff;
  border-radius: 20px 20px 20px 0;
  @media (max-width: 1024.98px) {
    width: 90%;
  }
  @media (max-width: 1000.98px) {
    width: 100%;
  }
  //@media (max-width: 425.98px) {
  //  padding: 15px;
  //}
`

export const AdsStyledInput = styled.input`
  font-size: 16px;
  line-height: 46px;
  color: rgba(12, 18, 21, 0.8);
  height: 48px;
  padding: 0 20px;
  background: #fff;
  border: 1px solid rgba(12, 18, 21, 0.2);
  border-radius: 5px;
  width: 416px;

  @media (max-width: 1024.98px) {
    width: 100%;
  }
  @media (max-width: 425.98px) {
    padding: 15px;
  }
  //&:hover {
  //  border-color: #007aff;
  //}
  //
  //&:focus {
  //  border-color: #007aff;
  //  outline: none; 
  //  box-shadow: 0 0 3px #007aff; 
  //}
`;

export const AdsAnswerQuestionBlock = styled.div`
  display: flex;
  gap: 14.37px;
  padding-top: 7px;
  margin-bottom: 18px;
  @media (max-width: 497.98px) {
    flex-direction: column;
  }

`

export const AdsNumberWrap = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 497.98px) {
    flex-direction: column;
  }
`;

export const AdsRow = styled.div`
  display: flex;
  gap: 14.37px;
  padding-top: 7px;

  @media (max-width: 497.98px) {
    flex-direction: column;
  }
`;

export const AdNewRow = styled.div`
  display: flex;
  gap: 14.37px;
  padding-top: 18px;


  @media (max-width: 497.98px) {
    flex-direction: column;
  }
`;

export const AdsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14.37px;
  padding-top: 7px;

  @media (max-width: 497.98px) {
    flex-direction: column;
  }
`;

export const AdsFormBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;


export const AdsUploadFormBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    display: flex;
    gap: 12px;
    font-style: normal;
    opacity: 0.8;
    cursor: pointer;
    width: 153px;
    height: 153px;
    color: #1056B2;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
`;
export const AdsFileContainer = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
  @media (max-width: 497.98px) {
    gap: 10px;
  }
`
export const AdsFileItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FileTitle = styled.div`
  color: #0C1215;
  font-family: Gilroy,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
`


export const StyledFileInputContainer = styled.label`
  display: inline-block;
  width: 153px;
  height: 153px;
  align-items: center;
  outline: none;
  cursor: pointer;
  text-align: center;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #D2DAE3;
  box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
  border-radius: 5px;
  color: #1056B2;
  transition: all 0.3s ease;

  &:hover {
    background: #999999;
    box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
    border-radius: 5px;
    color: #FFFFFF;

  }


  input[type='file'] {
    display: none;
  }


  svg {
    position: absolute;
    top: 5px;
    left: 5px;
  }
`;

export const FileIconContainer = styled.div`
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  gap: 8px;

  svg {
    position: absolute;
    top: 0;
    left: -14px;

  }
`;


export const FileUploadIcon = styled.span`

`;

export const FileUploadText = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;


export const AddInput = styled.p`
  color: #1056B2;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
`;


export const StyledAdsPlusTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
`
