import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialStateType = {
    isDelete: boolean;


};

const initialState: InitialStateType = {
    isDelete: false,

};

const slice = createSlice({
    name: 'popups',
    initialState,
    reducers: {
        setDeleteAC: (state, action: PayloadAction<boolean>) => {
            state.isDelete = action.payload;
        },
    },
});

export const popupsReducer = slice.reducer;
export const { setDeleteAC } = slice.actions;
