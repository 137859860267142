
import {ForgotPasswordType} from "services/auth/auth.types";
import {useEffect, useState} from "react";
import {Forgot} from "components/pages/forgot-password/forgot/forgot";

export const ForgotPassword = () => {
    // const [forgotPassword] = useForgotPasswordMutation()
    // const [token, setToken] = useState<string>('');
    //
    // useEffect(() => {
    //     // Получаем токен из cookie при загрузке компонента
    //     const tokenFromCookie = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    //     setToken(tokenFromCookie);
    // }, []);


    const handlerForgot = async (args: ForgotPasswordType) => {
        // args.token = token;
        // console.log(args,'Отправлено')
        // запрос на сервер востановление пароля
        // try {
        //     await forgotPassword(args)
        //     navigate('/')
        // } catch (e) {
        //     console.error(e)
        // }
    }


    return (
        <Forgot onSubmit={handlerForgot}/>
    )
};

