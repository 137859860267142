import React, {FC} from 'react';
import {
    ButtonBlock,
    ButtonSubmit,
    CloseButton,
    FormBlock,
    FormBlockText,
    FormContainer,
    StyledTitle
} from "./form";
import {ReactComponent as CloseIcon} from '../../../../../../../assets/main/feedback/crose.svg';
import {FormWrapper} from "../../../../../account/login/account-form/form-login/style-form";

type PropsType = {
    handleClosePopups: () => void;
};


export const FormDelete: FC<PropsType> = ({handleClosePopups}) => {


    return (
        <FormWrapper onClick={(e) => e.stopPropagation()}>
            <form>
                <CloseButton type="button" onClick={handleClosePopups}>
                    <CloseIcon/>
                </CloseButton>
                <StyledTitle>Czy na pewno chcesz usunąć opinię?</StyledTitle>
                <FormContainer>
                    <FormBlock>
                        <FormBlockText>Tej czynności nie można cofnąć!</FormBlockText>
                    </FormBlock>
                    <ButtonBlock>
                        <ButtonSubmit type="submit"><span>Potwierdzać</span></ButtonSubmit>
                    </ButtonBlock>
                </FormContainer>
            </form>
        </FormWrapper>
    );
};


