import React from 'react';

import {ReactComponent as СrossIcon} from '../../../../../assets/main/form/cross.svg';
import {ReactComponent as Checkbox} from '../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as CloseIcon} from '../../../../../assets/main/form/close.svg';
import {
    FormBlock,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel,
    StyledTitle,
    TimeBlock,
    TimeInput,
    TimeInputDoLabel,
    TimeInputOdLabel
} from "../../login/account-form/form-login/style-form";

import {Input} from "antd";
import {
    DeleteBlock,
    FreeDateButtonBlock,
    FreeDateIcon,
    FreeDateLeftButton,
    FreeDateRightButton
} from "../add-free-date-form/style-add-free-date-form";
import {Formik} from "formik";
import {Popups} from "../../../../../common/popups/Popups";
import {
    DeleteBookedTerm
} from "../../../сalendar/calendar-block/calendar-header/date-form/date-work/delete-booked-term/DeleteBookedTerm";
import usePopup from "../../../../../common/hook/use-popup";
import {CloseButton} from "../../../feedback/feedback-block/feedback-information/delete-popup/form-delete/form";


const {TextArea} = Input;

interface PropsType {
    handleClosePopups: () => void;
    showNewPopup: boolean;
}


const initialFormValues = {
    title: '',
    timeWith: '',
    timeBefore: '',
    location: '',
    customerName: '',
    contact: '',
    emil: '',
    addNote: '',
};


export const EditReservedDateForm: React.FC<PropsType> = ({handleClosePopups, showNewPopup}) => {


    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    if (showNewPopup && showPopup) {
        handleClosePopups()
    }

    const handleSubmit = (values: any) => {
        console.log(values);
    };

    return (
        <Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
            {() => (
                <FormWrapper onClick={(e) => e.stopPropagation()}>
                    <form>
                        <CloseButton type="button" onClick={handleClosePopups}>
                            <СrossIcon/>
                        </CloseButton>
                        <StyledTitle>Edytuj zarezerwowany termin</StyledTitle>
                        <FormContainer>
                            <FormBlock>
                                <StyledLabel htmlFor="title">Tytuł</StyledLabel>
                                <StyledInput type="text" name="title"/>
                            </FormBlock>
                            <FormBlock>
                                <StyledLabel htmlFor="timeWith">Czas</StyledLabel>
                                <TimeBlock>
                                    <TimeInputOdLabel htmlFor="timeWith"></TimeInputOdLabel>
                                    <TimeInput type="text" name="timeWith" autoComplete="off"/>
                                    <TimeInputDoLabel htmlFor="timeBefore"></TimeInputDoLabel>
                                    <TimeInput type="text" name="timeBefore" autoComplete="off"/>
                                </TimeBlock>
                            </FormBlock>

                            <FormBlock>
                                <StyledLabel htmlFor="advance">Lokalizacja</StyledLabel>
                                <TextArea name="location" autoSize={{minRows: 3, maxRows: 8}}/>
                            </FormBlock>

                            <FormBlock>
                                <StyledLabel htmlFor="title">Imię Klienta</StyledLabel>
                                <StyledInput type="text" name="customerName"/>
                            </FormBlock>

                            <FormBlock>
                                <StyledLabel htmlFor="title">Kontakt</StyledLabel>
                                <StyledInput type="text" name="contact"/>
                            </FormBlock>

                            <FormBlock>
                                <StyledLabel htmlFor="title">Email</StyledLabel>
                                <StyledInput type="text" name="emil"/>
                            </FormBlock>

                            <FormBlock>
                                <StyledLabel htmlFor="advance">Dodaj notatkę</StyledLabel>
                                <TextArea autoSize={{minRows: 5, maxRows: 8}} name="addNote"/>
                            </FormBlock>

                            <FreeDateButtonBlock>
                                <FreeDateLeftButton>
                                    <FreeDateIcon>
                                        <CloseIcon/>
                                        <span>Anuluj</span>
                                    </FreeDateIcon>
                                </FreeDateLeftButton>
                                <FreeDateRightButton
                                    type="submit">
                                    <FreeDateIcon>
                                        <Checkbox/>
                                        <span>Zapisz</span>
                                    </FreeDateIcon>
                                </FreeDateRightButton>
                            </FreeDateButtonBlock>


                            <DeleteBlock onClick={onClickHandler}>
                                <p>Usuń</p>
                            </DeleteBlock>


                            <Popups
                                show={showPopup}
                                handleClosePopups={handleClosePopup}
                                onClickClosest={onClickClosest}
                                formComponent={<DeleteBookedTerm handleClosePopups={handleClosePopup}/>}
                            />

                        </FormContainer>
                    </form>
                </FormWrapper>
            )}
        </Formik>
    );
};
