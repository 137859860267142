import React, {FC} from 'react';
import {ButtonArchive} from "../../../style-calendar-header-block";
import {ReactComponent as EditIcon} from '../../../../../../../../assets/main/calendar/edit.svg';
import usePopup from "../../../../../../../../common/hook/use-popup";
import {EditReservedDateForm} from "../../../../../../account/forms/edit-reserved-date-form/EditReservedDateForm";
import {Popups} from "../../../../../../../../common/popups/Popups";

interface PropsType {
    handleCalendarClosePopups: () => void;
    showCalendarPopup: boolean;
}

export const EditReservedDate: FC<PropsType> = ({handleCalendarClosePopups, showCalendarPopup}) => {
    const {
        showPopup: showNewPopup,
        onClickHandler: onClickCalendarHandler,
        handleClosePopup: handleCloseCalendarPopup,
        onClickClosest: onClickClosestCalendar,
    } = usePopup();

    if (showCalendarPopup && showNewPopup) {
        handleCalendarClosePopups();
    }

    return (
        <div>
            <ButtonArchive onClick={onClickCalendarHandler}>
                <EditIcon/>
                <span>Edytuj zarezerwowany termin</span>
            </ButtonArchive>
            <Popups
                show={showNewPopup}
                handleClosePopups={handleCloseCalendarPopup}
                onClickClosest={onClickClosestCalendar}
                formComponent={<EditReservedDateForm showNewPopup={showNewPopup} handleClosePopups={handleCloseCalendarPopup}/>}
            />
        </div>
    );
};

