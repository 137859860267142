import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowUpIcon } from '../../assets/header/arrawUp.svg';
import { GoToTopWrapper, GoToTopButton } from 'common/goToTop/goToTop-style';

export const GoToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const goToBtn = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const listenToScroll = () => {
        let heightToHidden = 20;
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHidden) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => window.removeEventListener('scroll', listenToScroll);
    }, []);

    return (
        <GoToTopWrapper>
            {isVisible && (
                <GoToTopButton onClick={goToBtn}>
                    <ArrowUpIcon />
                </GoToTopButton>
            )}
        </GoToTopWrapper>
    );
};

