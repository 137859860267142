import React, {useEffect, useRef, useState} from "react";
import {CompanyData} from "services/company/company.types";
import {
    ContHeg50,
    ContListSelectMate,
    ContSelectCenter,
    ContSelectInt,
    IconSelectMate,
    SelecionadoOpcion,
    SelectMate
} from "common/selector/style-question-select";
import {ReactComponent as ArrayIcon} from '../../assets/main/form/selectArray.svg';
import {ErrorText} from "components/pages/account/login/account-form/form-login/style-form";

interface OfferTypeSelectProps {
    offerType: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    data: CompanyData | undefined;
    setSelectedFeatures: (p: any[]) => void
    error:boolean
}

export const OfferTypeSelect: React.FC<OfferTypeSelectProps> = ({data,error, offerType, onChange, setSelectedFeatures}) => {
    const options: string[] = Object.keys(data?.options.offer_types || {}) || [];
    const selectRef = useRef<HTMLDivElement | null>(null); // изменили на HTMLDivElement
    const start = data?.company?.offer_type?.id !== undefined ? +data.company.offer_type.id : 0;
    const [selectedIndex, setSelectedIndex] = useState<number>(start);
    const selectedTitle = selectedIndex !== null ? data?.options.offer_types?.[options[selectedIndex]]?.title : null;
    const [selectOpen, setSelectOpen] = useState(false);
    const [showEllipsis, setShowEllipsis] = useState(false);

    const handleToggleSelect = () => {
        setSelectOpen((prevState) => !prevState);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setSelectOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setSelectedIndex(start - 1);
    }, [start]);

    const handleClickOption = (index: number) => {
        setSelectedIndex(index);
        setSelectOpen(false);
        setSelectedFeatures([]);
        const selectedValue = options[index];
        const event = new Event('change', {bubbles: true});
        Object.defineProperty(event, 'target', {value: {value: selectedValue}, enumerable: true});
        event.initEvent('change', true, true);
        onChange(event as unknown as React.ChangeEvent<HTMLSelectElement>);
    };

    useEffect(() => {
        if (selectedIndex !== null && options[selectedIndex] !== undefined) {
            const selectedValue = options[selectedIndex];
            const event = document.createEvent('Event');
            event.initEvent('change', true, true);
            const target = {value: selectedValue};
            Object.defineProperty(event, 'target', {value: target, enumerable: true});
            onChange(event as unknown as React.ChangeEvent<HTMLSelectElement>);
        }
    }, [onChange, selectedIndex, options]);

    return (
        <ContSelectCenter ref={selectRef}>
            <ContHeg50/>
            <SelectMate
                error={error}
                data-mate-select="active">
                <SelecionadoOpcion  error={error}  onClick={handleToggleSelect}>
                    {showEllipsis ? '...' : selectedTitle || 'Wybierz тyp oferty'}
                </SelecionadoOpcion>
                <IconSelectMate onClick={handleToggleSelect}>
                    <ArrayIcon/>
                </IconSelectMate>
                <ContListSelectMate>
                    <ContSelectInt style={{height: selectOpen ? 'auto' : 0}}>
                        {options.map((key, index) => (
                            <li
                                key={index}
                                className={index === selectedIndex ? 'active' : ''}
                                onClick={() => handleClickOption(index)}
                            >
                                {data?.options.offer_types[key]?.title}
                            </li>
                        ))}
                    </ContSelectInt>
                </ContListSelectMate>
            </SelectMate>
        </ContSelectCenter>
    );
};


