import React from 'react';
import {PhotoBlock} from "components/pages/presentation/form-presentation/style-form-presentation";
import {
    MultimediaGallery
} from "components/pages/presentation/multimedia/form-multimedia/multimedia-file/multimedia-gallery/multimediaGallery";
import {
    MultimediaPicture
} from "components/pages/presentation/multimedia/form-multimedia/multimedia-file/multimedia-picture/multimediaPicture";

export const MultimediaFile = () => {

    return (
        <PhotoBlock>
            <MultimediaPicture/>
            <MultimediaGallery/>
        </PhotoBlock>
    );
};
