import React from "react";
import {useForm} from "react-hook-form";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {ReactComponent as ShowPassword} from '../../../../assets/main/form/showPassword.svg';
import {ReactComponent as ShowPasswordError} from '../../../../assets/main/form/showPasswordError.svg';
import {ReactComponent as VisibilityPassword} from '../../../../assets/main/form/visibilityPassword.svg';
import {ReactComponent as VisibilityPasswordError} from '../../../../assets/main/form/visibilityPasswordError.svg';
import {
    ErrorText,
    FormBlock,
    FormBlockPassword,
    Loader,
    StyledLabel
} from "components/pages/account/login/account-form/form-login/style-form";
import {CalendarText} from "components/pages/ads/style-ads";
import {ContentButton, SingInButton, SingInForm} from "components/pages/sign-in-page/sing-in/style-sing-in";
import {WrapperPassword} from "components/layout/style-layout";
import {passwordSchema} from "common/validation/login/passwordSchema";
import {PasswordChangeResponseType} from "services/auth/auth.types";


interface PasswordProps {
    onSubmit: (formData: FormValues) => void;
    showPassword: {
        oldPassword: boolean;
        password: boolean;
        retypePassword: boolean;
    };
    togglePasswordVisibility: (field: any) => void;
    data?: PasswordChangeResponseType;
    isLoading: boolean
}

type FormValues = z.infer<typeof passwordSchema>;

export const Password: React.FC<PasswordProps> = (
    {onSubmit, showPassword, togglePasswordVisibility, data, isLoading}
) => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<FormValues>({
        resolver: zodResolver(passwordSchema),
        defaultValues: {
            oldPassword: "",
            password: "",
            retypePassword: "",
        },
    });

    const isOldPasswordError = errors.oldPassword?.message || data?.errors?.oldPassword;
    const isPasswordError = errors.password?.message;
    const isRetypePasswordError = errors.retypePassword?.message;
    return (
        <WrapperPassword>
            <SingInForm onSubmit={handleSubmit(onSubmit)}>
                <FormBlock>
                    <StyledLabel
                        error={isOldPasswordError}
                        htmlFor="oldPassword">
                        Stare hasło*
                    </StyledLabel>
                    <FormBlockPassword error={isOldPasswordError}>
                        <input
                            type={showPassword.oldPassword ? 'text' : 'password'}
                            id="oldPassword"
                            {...register("oldPassword")}
                        />
                        <button type="button" onClick={() => togglePasswordVisibility('oldPassword')}>
                            {showPassword.oldPassword ? (
                                isOldPasswordError ? <VisibilityPasswordError/> : <VisibilityPassword/>
                            ) : (
                                isOldPasswordError ? <ShowPasswordError/> : <ShowPassword/>
                            )}
                        </button>
                    </FormBlockPassword>
                    {(isOldPasswordError) && (
                        <ErrorText>{isOldPasswordError}</ErrorText>
                    )}
                </FormBlock>

                <FormBlock>
                    <StyledLabel
                        error={isPasswordError}
                        htmlFor="password">
                        Nowe hasło*
                    </StyledLabel>
                    <FormBlockPassword error={isPasswordError}>
                        <input
                            type={showPassword.password ? 'text' : 'password'}
                            id="password"
                            {...register("password")}
                        />
                        <button type="button" onClick={() => togglePasswordVisibility('password')}>
                            {showPassword.password ? (
                                isPasswordError ? <VisibilityPasswordError/> : <VisibilityPassword/>
                            ) : (
                                isPasswordError ? <ShowPasswordError/> : <ShowPassword/>
                            )}
                        </button>
                    </FormBlockPassword>
                    {isPasswordError && (
                        <ErrorText>{isPasswordError}</ErrorText>
                    )}
                </FormBlock>
                <FormBlock>
                    <StyledLabel
                        error={isRetypePasswordError}
                        htmlFor="retypePassword">
                        Potwierdź nowe hasło*
                    </StyledLabel>

                    <FormBlockPassword error={isRetypePasswordError}>
                        <input
                            type={showPassword.retypePassword ? 'text' : 'password'}
                            id="retypePassword"
                            {...register("retypePassword")}
                        />
                        <button type="button" onClick={() => togglePasswordVisibility('retypePassword')}>
                            {showPassword.retypePassword ? (
                                isRetypePasswordError ? <VisibilityPasswordError/> : <VisibilityPassword/>
                            ) : (
                                isRetypePasswordError ? <ShowPasswordError/> : <ShowPassword/>
                            )}
                        </button>
                    </FormBlockPassword>
                    {isRetypePasswordError && (
                        <ErrorText>{isRetypePasswordError}</ErrorText>
                    )}
                </FormBlock>
                <SingInButton
                    disabled={Object.keys(errors).length > 0}
                    type="submit"
                >
                    <ContentButton>
                        {isLoading && <Loader/>}
                        <CalendarText transform={true}>
                            Potwierdzać
                        </CalendarText>
                    </ContentButton>
                </SingInButton>
            </SingInForm>
        </WrapperPassword>
    );
};

