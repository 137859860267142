import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import { TabNavItem, TabsContainer, TabsWrap } from './style-tabs';
import { useAppSelector } from 'common/hook';
import { setActiveTab } from 'app/tabs-reduser';

export const Tabs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeTab = useAppSelector((state) => state.tabs.activeTab);
    const tabs = [
        { id: 0, label: 'Ilość wyświetleń prezentacji' },
        { id: 1, label: 'Ilość wyświetleń numeru telefonu' },
        { id: 2, label: 'Ilość wysłanych zapytań' },
        { id: 3, label: 'Opinie' },
        { id: 4, label: 'Przejść na stronę www' },
        { id: 5, label: 'Przejść na Facebook\'a' },
        { id: 6, label: 'Przejść na Instagram' },
        { id: 7, label: 'Przejść na YouTube\'a' },
    ];

    const handleTabClick = (index: number) => {
        dispatch(setActiveTab(index));
        navigate(`/statistics?tab=${index}`);
    };

    return (
        <TabsWrap>
            <TabsContainer>
                {tabs.map((tab) => (
                    <TabNavItem
                        key={tab.id}
                        className={`tab-nav-item ${activeTab === tab.id ? 'active' : ''}`}
                        onClick={() => handleTabClick(tab.id)}
                    >
                        {tab.label}
                    </TabNavItem>
                ))}
            </TabsContainer>
        </TabsWrap>
    );
};

