import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px 42px;

  @media (max-width: 773.98px) {
    padding: 0;
  }
  
`;

export const HelpContent = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-weight: 300;
  //font-size: 18px;
  line-height: 140%;
  color: var(--white);
  padding-top: 20px;
  margin-bottom: 40px;
  max-width: 772px;
  font-size: calc(15px + 3 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(15px + (15 + 3 * 0.7) * ((100vw - 320px) / 1480));
  }
`;


export const HelpButtonBlock = styled.div`

`;


export const HelpButton = styled.button`
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: var(--black);
  padding: 12px 79px;
  background: var(--white);
  border: 1px solid rgba(210, 218, 227, 0.8);
  border-radius: 200px 100px 100px 0;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  @media (hover: hover) and (min-width: 1023.98px) {
    &:hover {
      background: rgba(255, 255, 255, 1);
      border-color: rgba(210, 218, 227, 1);
    }
  }

  @media (max-width: 773.98px) {
    width: 100%;
    padding: 12px 50px;
  }
`;
