import React from 'react';
import {SubscriptionBlock, SubscriptionWrap} from "./style-subscription";

export const Subscription = () => {
    return (
        <SubscriptionWrap>
            <SubscriptionBlock>
                <p>Roczny abonament:</p>
                <span>od 02.01.2022 do 01.01.2023</span>
            </SubscriptionBlock>
            <SubscriptionBlock>
                <p>Wyróżniony na liście:</p>
                <span>od 02.01.2022 do 01.02.2022</span>
            </SubscriptionBlock>
        </SubscriptionWrap>
    );
};

