import React from 'react';
import {ReactComponent as ChevronIcon} from '../../../../../assets/main/calendar/chevron-left.svg';
import {ReactComponent as BoxIcon} from '../../../../../assets/main/calendar/box.svg';
import {
    ArchiveButtonWrap,
    ButtonArchive,
    ButtonNewArchive,
    ButtonYear,
    CalendarHeader
} from "./style-calendar-header-block";
import usePopup from "../../../../../common/hook/use-popup";
import {FullInformation} from "./full-information/FullInformation";
import {FormViewArchive} from "../../../account/forms/form-view-archive/FormViewArchive";
import {Popups} from "../../../../../common/popups/Popups";

interface CalendarHeaderProps {
    currentYear: number;
    handlePrevYear: () => void;
    handleNextYear: () => void;
}

export const CalendarHeaderBlock: React.FC<CalendarHeaderProps> = ({currentYear, handlePrevYear, handleNextYear}) => {
    const {
        showPopup: showCalendarPopup,
        onClickHandler: onClickCalendarHandler,
        handleClosePopup: handleCloseCalendarPopup,
        onClickClosest: onClickClosestCalendar,
    } = usePopup();

    return (
        <CalendarHeader>
            <ButtonYear>
                <button onClick={handlePrevYear}><ChevronIcon/></button>
                <span>{currentYear}</span>
                <button onClick={handleNextYear}><ChevronIcon style={{transform: 'rotate(180deg)'}}/></button>
            </ButtonYear>
            <ArchiveButtonWrap>
                <ButtonNewArchive onClick={onClickCalendarHandler}>
                    <BoxIcon/>
                    <span>Zobacz archiwum</span>
                </ButtonNewArchive>
            </ArchiveButtonWrap>
            <Popups
                show={showCalendarPopup}
                handleClosePopups={handleCloseCalendarPopup}
                onClickClosest={onClickClosestCalendar}
                formComponent={<FormViewArchive showCalendarPopup={showCalendarPopup} handleClosePopups={onClickClosestCalendar}/>}
            />
        </CalendarHeader>
    );
};
