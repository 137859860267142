import React from 'react';
import {ReactComponent as СrossIcon} from '../../../../../assets/main/form/cross.svg';
import {FormContainer, FormWrapper} from "../../login/account-form/form-login/style-form";
import {
    DateCell,
    NameCell,
    StyledTable,
    StyledTableHeader,
    StyledTableRow,
    StyledTitleCenter,
    TableWrapper,
    TdContact,
    ViewWrapper
} from "./style-form-view-archive";

import {FullInformation} from "../../../сalendar/calendar-block/calendar-header/full-information/FullInformation";
import {CloseButtonTwo} from "../../../feedback/feedback-block/feedback-information/delete-popup/form-delete/form";


interface PropsType {
    handleClosePopups: () => void;
    showCalendarPopup:boolean;
}


export const FormViewArchive: React.FC<PropsType> = ({handleClosePopups,showCalendarPopup}) => {



    const data = [
        {
            date: "26.03.2021",
            name: "Janusz & Lena Biernacka",
            contactPhone: "+45 506 501 285",
            contactEmail: 'janusz_biernacka@gmail.com'
        },
        {
            date: "27.12.2020",
            name: "Eugeniusz & Magdalena Sosnowski",
            contactPhone: "+45 506 501 285",
            contactEmail: 'e_sosnowski@gmail.com'
        },
        {
            date: "05.11.2020",
            name: "Bogumił & Dominika Komorowski",
            contactPhone: "+45 506 501 285",
            contactEmail: 'bogumił_komorowski@gmai...'
        },
        {
            date: "13.10.2020",
            name: "Robert & Anna Janowski",
            contactPhone: "+45 506 501 285",
            contactEmail: 'r.janowski@gmail.com'
        },
        {
            date: "27.12.2020",
            name: "Artur & Elena Żurek ",
            contactPhone: "+45 506 501 285",
            contactEmail: 'a_żurek@gmail.com'
        },
    ];



    return (
        <FormWrapper onClick={(e) => e.stopPropagation()}>
            <ViewWrapper>
                <CloseButtonTwo type="button" onClick={handleClosePopups}>
                    <СrossIcon/>
                </CloseButtonTwo>
                <StyledTitleCenter>Zobacz archiwum</StyledTitleCenter>
                <FormContainer>
                    <TableWrapper>
                        <StyledTable>
                            <thead>
                            <StyledTableRow>
                                <StyledTableHeader>Data</StyledTableHeader>
                                <StyledTableHeader>Imię Klienta</StyledTableHeader>
                                <StyledTableHeader>Szczegóły kontaktu</StyledTableHeader>
                            </StyledTableRow>
                            </thead>
                            <tbody>
                            {data.map((row, index) => (
                                <tr key={index}>
                                    <DateCell>{row.date}</DateCell>
                                    <NameCell>{row.name}</NameCell>
                                    <TdContact>
                                        <span>{row.contactPhone}</span>
                                        <span>{row.contactEmail}</span>
                                            <FullInformation handleClosePopups={handleClosePopups} showCalendarPopup={showCalendarPopup}/>
                                    </TdContact>
                                </tr>
                            ))}
                            </tbody>
                        </StyledTable>
                    </TableWrapper>
                </FormContainer>
            </ViewWrapper>
        </FormWrapper>
    );
};