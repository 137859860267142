import React, {useState} from 'react';
import {FormBlock} from "../style-form-presentation";
import {
    BlockFaq,
    CollapseBlock,
    CollapsePopup,
    CollapseWrap,
    EditButtonFaq,
    EditButtonFaqBlock,
    FAQTitle,
    FaqWrap,
    FormText
} from "./style-faq";

import {Collapse} from 'antd';
import {ReactComponent as EditIcon} from '../../../../../assets/main/presentation/edit.svg';
import {ReactComponent as DownArrowIcon} from '../../../../../assets/main/presentation/down-arrow.svg';
import {ReactComponent as UpArrowIcon} from '../../../../../assets/main/presentation/up-arrow.svg';
import usePopup from "../../../../../common/hook/use-popup";
import {Popups} from "common/popups/Popups";
import {FormFaq} from "./faq-popup/form-faq/FormFaq";
import {useFaqQuery} from "services/company/faq/faq.service";
import {FaqData, FaqItem} from "services/company/faq/faq.types";
import Skeleton from 'react-loading-skeleton'


type PanelKey = '1' | '2' | '3';
export const Faq = () => {
    const {data, isLoading, isFetching} = useFaqQuery();
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();
    const [expandedPanel, setExpandedPanel] = useState<PanelKey | null>(null);
    const [selectedFaq, setSelectedFaq] = useState<FaqItem | null>(null);
    const loading = isLoading || isFetching;


    const remainingItems = data ? Math.max(0, 3 - data.length) : 3;


    const handleAddClick = () => {
        setSelectedFaq(null);
        onClickHandler();
    };

    const handlePanelChange = (panelKey: PanelKey) => {
        setExpandedPanel((prevExpandedPanel) => (prevExpandedPanel === panelKey ? null : panelKey));
    };

    const handleEditClick = (faqItem: FaqItem) => {
        setSelectedFaq(faqItem);
        onClickHandler();
    };


    return (
        <FaqWrap>
            <FormBlock id="faq">
                <CollapseWrap>
                    <FAQTitle>FAQ</FAQTitle>
                    <FormText>
                        Dodaj najczęściej zadawane pytania przez Pary Młode i odpowiedz na nie. Pojawią się one w Twojej
                        prezentacji jako dodatkowy atut.
                    </FormText>
                    {remainingItems > 0 && (
                        <CollapsePopup onClick={handleAddClick}>
                            + Dodaj pytanie ({remainingItems} pozostały)
                        </CollapsePopup>
                    )}
                    {data?.map((item: FaqData) => (
                        loading ? (
                            <CollapseBlock key={item.id}>
                                <Skeleton count={1} height={60}/>
                            </CollapseBlock>
                        ) : (
                            <BlockFaq key={item.id}>
                                <CollapseBlock>
                                    <Collapse
                                        size="large"
                                        activeKey={expandedPanel === item.id.toString() ? item.id.toString() : undefined}
                                        onChange={() => handlePanelChange(item.id.toString() as PanelKey)}
                                        expandIcon={({isActive}) =>
                                            isActive ? <UpArrowIcon/> : <DownArrowIcon/>
                                        }
                                        items={[
                                            {
                                                key: item.id.toString(),
                                                label: item.question,
                                                children: <p>{item.answer}</p>,
                                            },
                                        ]}
                                    />
                                </CollapseBlock>
                                <EditButtonFaqBlock>
                                    <EditButtonFaq onClick={() => handleEditClick(item)}>
                                        <EditIcon/>
                                        <span>Edytuj</span>
                                    </EditButtonFaq>
                                </EditButtonFaqBlock>
                            </BlockFaq>
                        )
                    ))}

                </CollapseWrap>

                <Popups
                    show={showPopup}
                    handleClosePopups={handleClosePopup}
                    onClickClosest={onClickClosest}
                    formComponent={
                        <FormFaq
                            handleClosePopups={handleClosePopup}
                            faqItem={selectedFaq}
                        />
                    }
                />
            </FormBlock>
        </FaqWrap>
    );
};


