import styled, {keyframes} from "styled-components";
import {DatePicker} from "antd";


export interface ErrorProps {
  error?: any;
  errors?: string;
}

export const LoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 160px;
`;
export const FormFlexBlock = styled.div`
  display: flex;
  gap: 30px;
  padding-top: 7px;
  margin-bottom: 18px;

  @media screen and (max-width: 768.98px) {
    flex-wrap: wrap;
  }
`

export const FormWrapper = styled.div`
  background: white;
  padding: 30px 62px 50px 62px;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%);
  overflow: auto;


  @media screen and (max-width: 446.98px) {
    padding: 50px 20px 20px 20px;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    transform: none;
  }
  
  //@media screen and (max-width: 425.98px) {
  //  padding: 50px 20px 20px 20px;
  //  width: 100%;
  //  height: 100%;
  //  top: 0;
  //  left: 0;
  //  transform: none;
  //}

  form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px 30px;
    grid-auto-flow: dense;
    justify-content: center;
    padding-top: 30px;
    position: relative;
  }
`;

export const DivWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px 30px;
  grid-auto-flow: dense;
  justify-content: center;
  padding-top: 30px;
  position: relative;
  text-align: center;
`

export const StyledDivTitle = styled.p`
  color: #0C1215;
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

export const DivText = styled.p`
  color: #0C1215;
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
`;


export const FormWrapperLogin = styled.div`
  overflow: auto;

  form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px 30px;
    grid-auto-flow: dense;
    justify-content: center;
  }
`;

export const StyledTitle = styled.p`
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 120%;
  color: #0C1215;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
`;


export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  gap: 18px;
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-input {
    color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    &:hover {
      border-color: rgba(12, 18, 21, 0.20);;
    }

    &:focus {
      border-color: rgba(12, 18, 21, 0.20);
    }


    svg {
      position: relative;
      left: -4px;
      top: 0;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      opacity: 0.6;
    }

    .ant-picker-suffix svg {
      display: none;
    }


    @media screen and (max-width: 1132px) {
      width: 100%;
    }
`;
export const FormPhotoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 185px;
`

export const FormBlockTooltips = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-input {
    color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
   
  }
  

  @media screen and (max-width: 1132px) {
    width: 100%;
  }
`;

export const StyleLabelTooltips = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;


export const StyledLabel = styled.label<ErrorProps>`
  color: ${props => props.error || props.errors ? 'red' : '#0C1215'};
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
`;

export const StyledInput = styled.input<ErrorProps>`
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  height: 48px;
  padding: 0 20px;
  background: #fff;
  border: ${props => props.error || props.errors ? '0.6px solid red' : '0.6px solid rgba(12, 18, 21, 0.2)'};
  border-radius: 5px;
  transition: border-color 0.3s ease-in-out;

  color:${props => props.error || props.errors ? 'red' : '#0C1215'};
  
  @media screen and (min-width: 1132px) {
    width: 416px;
  }
  
`;

export const StyledTextArea = styled.textarea<ErrorProps>`
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  height: auto;
  min-height: 100px;
  max-height: 190px;
  padding: 10px 20px;
  background: #fff;
  border: ${props => props.error || props.errors ? '0.6px solid red' : '0.6px solid rgba(12, 18, 21, 0.2)'};
  border-radius: 5px;
  transition: border-color 0.3s ease-in-out, height 0.3s ease-in-out; 
  overflow-y: auto;
  resize: none;

  color:${props => props.error || props.errors ? 'red' : '#0C1215'};

  @media screen and (min-width: 1132px) {
    width: 416px;
  }
`;




export const ButtonSubmit = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 12px 30px;
  background: #1056B2;
  border-radius: 200px 100px 100px 0;
  max-width: 200px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }
  @media (max-width: 773.98px){
  max-width: 100%;
}

  &:hover,
  &:focus {
    background-color: #1976d2;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;


export const ButtonBlockLogin = styled.div`
  padding-top: 12px;
  width: 100%;
`

export const ButtonCloses = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 12px 30px;
  background: #ffffff;
  opacity: 0.8;
  border: 1px solid #d2dae3;
  border-radius: 208px 100px 100px 0;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #0C1215;
  }

`;



export const FormBlockPassword = styled.div<ErrorProps>`
  padding: 0.5rem 1rem;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: ${props => props.error || props.errors ? '0.6px solid red' : '0.6px solid rgba(12, 18, 21, 0.2)'};
  border-radius: 5px;
  font-size: 16px;
  line-height: 46px;
  color: rgba(12, 18, 21, 0.8);
  height: 48px;
  transition: border-color 0.3s ease-in-out;
  //padding: 0 20px;

  input {
    outline: none;
    font-size: 16px;
    color: rgba(12, 18, 21, 0.8);
    background: #fff;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 100%;
    }

    @media screen and (min-width: 1024px) {
      width: 358px;
    }
  }

  svg {
    cursor: pointer;
  }
`;

export const DatePickerCustomContainer = styled.div`
  position: relative;
  display: inline-block;
`;


export const DatePickCustomContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const CalendarTodayIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
`;

export const DatePickerCustom = styled(DatePicker)`
  border-radius: 5px;
  border: 0.6px solid rgba(12, 18, 21, 0.20);
  background: #FFF;
  max-width: 417px;
  width: 100%;
  height: 48px;
  
  svg {
    display: none;
  }
`

export const TimeBlock = styled.div`
  position: relative;
  display: flex;
  gap: 17px;
`

export const TimeInput = styled.input`
  font-family: Gilroy, serif;
  font-size: 14px;
  line-height: 46px;
  color: rgba(12, 18, 21, 0.8);
  height: 48px;
  padding: 0 20px;
  background: #fff;
  border: 1px solid rgb(12, 18, 21, 0.2);
  border-radius: 5px;
  transition: border-color 0.3s ease;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    width: 200px;
  }
  
`;

export const TimeInputOdLabel = styled.label`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: rgba(12, 18, 21, 0.6);
  pointer-events: none;
  transition: all 0.3s ease;
  user-select: none;

  ${TimeInput}:focus + &,
  ${TimeInput}:not(:placeholder-shown) + & {
    transform: translateY(-120%) translateX(-5px);
    color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 0 5px;
    opacity: 0.4;
  }
`;

export const TimeInputDoLabel = styled.label`
  position: absolute;
  top: 86%;
  right: 161px;
  transform: translateY(-50%);
  color: rgba(12, 18, 21, 0.6);
  pointer-events: none;
  transition: all 0.3s ease;
  user-select: none;

  ${TimeInput}:focus + &,
  ${TimeInput}:not(:placeholder-shown) + & {
    transform: translateY(-120%) translateX(-5px);
    color: #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 0 5px;
    opacity: 0.4;
  }
`;


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1; 
  }
`;

export const ErrorText = styled.p`
  color: #E52C13;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-in-out forwards; 
  font-size: 16px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
    border: 0.6px solid #1056B2;
    border-radius: 50%;
    border-top: 0.6px solid #ffffff;
    width: 16px;
    height: 16px;
    animation: ${spin} 1s linear infinite;
`;
