import React from 'react';
import {InformationForm} from "components/pages/informations/informations-forms/InformationForm";
import {Advertisement} from "components/pages/account/advertisement/advertisement";
import {FullWrapper} from "components/layout/style-layout";
import {AdvertisementWrap} from "components/pages/account/advertisement/style-advertisement";

export const Information = () => {
    return (
        <FullWrapper>
            <InformationForm/>
            <AdvertisementWrap>
                <Advertisement
                    title='Zwiększ zasięg reklamy?'
                    text='Wybierz odpowiedni plan taryfowy i wypromuj swoją firmę'/>
            </AdvertisementWrap>
        </FullWrapper>
    );
};

