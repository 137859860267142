import React, {FC} from 'react';
import {AddFreeDateWrapper, ButtonArchive} from "../../../style-calendar-header-block";
import {ReactComponent as CalendarIcon} from '../../../../../../../../assets/main/calendar/calendar.svg';
import usePopup from "../../../../../../../../common/hook/use-popup";
import {Popups} from "../../../../../../../../common/popups/Popups";
import {AddFreeDateForm} from "../../../../../../account/forms/add-free-date-form/AddFreeDateForm";


interface PropsType {
    handleCalendarClosePopups: () => void;
    showCalendarPopup: boolean;
}

export const AddFreeDate: FC<PropsType> = ({handleCalendarClosePopups, showCalendarPopup}) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    if (showCalendarPopup && showPopup) {
        handleCalendarClosePopups();
    }

    return (
        <AddFreeDateWrapper>
            <ButtonArchive onClick={onClickHandler}>
                <CalendarIcon/>
                <span>Dodaj wolny termin</span>
            </ButtonArchive>
            <Popups
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<AddFreeDateForm handleClosePopups={handleClosePopup}/>}
            />
        </AddFreeDateWrapper>
    );
};
