import React from 'react';
import {
    ContactPersonForm
} from "components/pages/informations/informations-forms/contact-person/contact-person-form/ContactPersonForm";
import {useContactEditMutation, useContactQuery} from "services/contact/contact.service";
import {usePasswordChangeMutation} from "services/auth/auth.service";

export const ContactPerson = () => {
    const { data, error, isLoading } = useContactQuery();
    const [contactEdit, {data:contactData, isLoading:contactLoading, error:contactEditError,
        isError:contactEditIsError}] = useContactEditMutation();




    const handlerPerson = async (args: any) => {
        try {
            console.log(args,'args')
            await contactEdit(args)
        } catch (e) {
            console.error(e);
        }
    }


    return (
        <div>
            <ContactPersonForm
                data={data}
                isLoading={isLoading}
                onSubmit={handlerPerson}
                contactEditError={contactEditError}
                contactEditIsError={contactEditIsError}
                contactEdit={contactEdit}
                contactLoading={contactLoading}
            />
        </div>
    );
};

