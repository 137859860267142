import styled from "styled-components";


interface StatisticsBlockProps {
    imageUrl: string;
    index:number;
}

export const Container = styled.div`
  margin-bottom: 120px;

  @media (max-width: 1000.98px) {
    margin-bottom: 20px;
  }
`;

export const StatisticsItems = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  gap: 50px 0;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
  padding: 32px 0;
  border-radius: 5px;
  margin-right: 30px;

  @media (max-width: 1000.98px) {
    margin-right: 0;
  }

  @media (max-width: 830.98px) { 
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    padding: 40px 0;
    gap: 25px 0;
  }
  @media (max-width: 638.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 438.98px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 15px 0;
  }
  
`;

export const StatisticsBlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 590.98px) {
    flex-direction: column;
  }
  @media (max-width: 1000.98px) {
    margin-bottom: 30px;
  }
`;

export const StatisticsTitle = styled.p`
  font-family: 'Gilroy',sans-serif;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.01em;
  color: var(--black);
  font-size: calc(19px + 11 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(19px + (19 + 11 * 0.7) * ((100vw - 320px) / 1480));
  }
`;



export const StatisticsBlock = styled.div<StatisticsBlockProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  text-align: center;
  background: url(${(props) => props.imageUrl}) no-repeat center/contain;
  border-right: 1px solid #D2DAE3;
  padding: 32px 24px 5px 32px;
  ${(props) => (props.index + 1) % 4 === 0 && `border-right: none;`}
  span {
    width: 54.13px;
    height: 33.99px;
    margin: 0 auto;
  }

  @media (max-width: 830.98px) {
    padding: 0 24px;
  }
  @media (max-width: 425.98px) {
    border-right: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const StatisticsNumber = styled.p`
  padding-top: 38.66px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  color: var(--black);
  font-size: calc(19px + 11 * (100vw / 1480));

  @media(max-width: 767px) {
    font-size: calc(19px + (19 + 11 * 0.7) * ((100vw - 320px) / 1480));
  }
  @media (max-width: 830.98px) {
    padding-top: 20px;
  }
`;

export const StatisticsText = styled.p`
  padding-top: 5px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: lowercase;
  color: var(--black);
`;