export interface Option {
    name: string;
    value: string;
}

export const options: Option[] = [
    {name: '1  mies', value: 'option1'},
    {name: '6 mies', value: 'option2'},
    {name: '2 rok', value: 'option3'},
];

