import React, {useEffect, useState} from 'react';
import {
    BlockWrap,
    CheckboxBlock,
    FormBlock,
    FormPresentationWrap,
    FormTitle,
    InputBlock,
    InputItem,
    QuestionSubmit,
    SubmitBlock,
    SubmitWrap
} from "./style-form-presentation";
import {message} from 'antd';
import {ReactComponent as CheckIcon} from '../../../../assets/main/presentation/check.svg';
import {MapBlock} from "./map-block/MapBlock";
import {
    ErrorText,
    Loader,
    StyledInput,
    StyledLabel,
    StyleLabelTooltips
} from "../../account/login/account-form/form-login/style-form";
import {Tooltips} from "common/tooltips/Tooltips";
import {ReactComponent as TooltipsIcon} from '../../../../assets/main/form/tooltips.svg';
import {getCompanyId, useCompanyEditMutation, useCompanyQuery} from "services/company/company.service";
import {OfferTypeSelect} from "common/elements/OfferTypeSelect";
import {CategorySelect} from "common/elements/CategorySelect";
import {FeatureCheckboxes} from "common/elements/FeatureCheckboxes";
import {PresentationFile} from "common/elements/PresentationFile/PresentationFile";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {FormValuesPresentation, presentationSchema} from "common/elements/PresentationFile/PresentationScema";
import {FormMultimedia} from "components/pages/presentation/multimedia/form-multimedia/FormMultimedia";
import ToggleGroup from "components/pages/presentation/form-presentation/toggle-group/ToggleGroup";


export const FormPresentation = () => {
    const {data, isLoading} = useCompanyQuery();

    const [updatePresentation, {
        data: updateData,
        isLoading: updateLoadingState,
        error,
        isError
    }] = useCompanyEditMutation()

    const [errorMessage, setErrorMessage] = useState<any>({})


    useEffect(() => {
        if (isError) {
            const err = error as any;
            setErrorMessage(err);
            message.error(`Proszę poprawić błędy w formularzu`)
        } else {
            setErrorMessage({});
        }
    }, [isError, error, updateData]);


    useEffect(() => {
        if (updateData) {
            message.success(`Formularz został pomyślnie zmieniony`)
        }
    }, [updateData]);


    const companyId = getCompanyId();
    const initialPresentationName = data?.company?.presentation_name || '';
    const initialObjectDescription = data?.company?.object_description || '';
    const initialPersonsFrom = data?.company?.persons_from || '';
    const initialPersonsTo = data?.company?.persons_to || '';
    const initialLinkHomepage = data?.company?.homepage || '';
    const initialLinkEmail = data?.company?.contact_email || '';
    const initialLinkFacebook = data?.company?.facebook || '';
    const initialLinkInstagram = data?.company?.instagram || '';
    const initialLinkYoutube = data?.company?.youtube || '';
    const initialLinkVimeo = data?.company?.vimeo || '';
    const initialLinkTiktok = data?.company?.tiktok || '';
    const initialAddress = data?.company?.address || '';
    const initialYoutubeVideo = data?.company?.youtube_video || '';
    const initialVimeoVideo = data?.company?.vimeo_video || '';


    const [presentationName, setPresentationName] = useState<string>(initialPresentationName);
    const [objectDescription, setObjectDescription] = useState<any>(initialObjectDescription);
    const [personsFrom, setPersonsFrom] = useState<number | string>(initialPersonsFrom);
    const [personsTo, setPersonsTo] = useState<number | string>(initialPersonsTo);
    const [linkHomepage, setLinkHomepage] = useState<string>(initialLinkHomepage);
    const [linkEmail, setLinkEmail] = useState<string>(initialLinkEmail);
    const [linkFacebook, setLinkFacebook] = useState<string>(initialLinkFacebook);
    const [linkInstagram, setLinkInstagram] = useState<string>(initialLinkInstagram);
    const [linkYoutube, setLinkYoutube] = useState<string>(initialLinkYoutube);
    const [linkVimeo, setLinkVimeo] = useState<string>(initialLinkVimeo);
    const [linkTiktok, setLinkTiktok] = useState<string>(initialLinkTiktok);
    const [address, setAddress] = useState<string>(initialAddress);
    const [categoryId, setCategoryId] = useState<string>('');
    const [youtubeVideo, setYoutubeVideo] = useState<string>(initialYoutubeVideo);
    const [vimeoVideo, setVimeoVideo] = useState<string>(initialVimeoVideo);

    const companyFeaturesCheck = Object.keys(data?.company?.features || {}).map(key => key);

    const [selectedFeatures, setSelectedFeatures] = useState<string[]>(companyFeaturesCheck);


    useEffect(() => {
        setPresentationName(initialPresentationName);
        setObjectDescription(initialObjectDescription);
        setPersonsFrom(initialPersonsFrom);
        setPersonsTo(initialPersonsTo);
        setLinkHomepage(initialLinkHomepage);
        setLinkEmail(initialLinkEmail);
        setLinkFacebook(initialLinkFacebook);
        setLinkInstagram(initialLinkInstagram);
        setLinkYoutube(initialLinkYoutube);
        setLinkVimeo(initialLinkVimeo);
        setLinkTiktok(initialLinkTiktok);
        setAddress(initialAddress);
        setYoutubeVideo(initialYoutubeVideo);
        setVimeoVideo(initialVimeoVideo);
    }, [data]);


    const {
        formState: {errors},
    } = useForm<FormValuesPresentation>({
        resolver: zodResolver(presentationSchema),
    });


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = {
            id: companyId,
            offer_type: offerType,
            category: categoryId,
            features: selectedFeatures,
            presentation_name: presentationName,
            object_description: objectDescription,
            persons_to: +personsTo,
            persons_from: +personsFrom,
            homepage: linkHomepage,
            contact_email: linkEmail,
            facebook: linkFacebook,
            instagram: linkInstagram,
            youtube: linkYoutube,
            vimeo: linkVimeo,
            tiktok: linkTiktok,
            address: address,
            youtube_video: youtubeVideo,
            vimeo_video: vimeoVideo,
        };
        try {
            updatePresentation(formData);
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPresentationName(e.target.value);
    };


    useEffect(() => {
        setSelectedFeatures(companyFeaturesCheck)
    }, [data?.company?.features]);


    const [offerType, setOfferType] = useState<string>(
        Object.keys(data?.options?.offer_types || {})[0] || ''
    );


    const onChangeOfferType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setOfferType(value);

    };


    const onFeatureChange = (e: React.ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
        const featureId = e.target.value;
        const newSelectedFeatures = [...selectedFeatures];
        const featureIndex = newSelectedFeatures.indexOf(featureId);

        if (e.target.checked) {
            if (featureIndex === -1) {
                newSelectedFeatures.push(featureId);
            }
        } else {
            if (featureIndex !== -1) {
                newSelectedFeatures.splice(featureIndex, 1);
            }
        }

        setSelectedFeatures(newSelectedFeatures);
    };


    return (
        <FormPresentationWrap onSubmit={handleSubmit}>
            <FormBlock id="service">
                <BlockWrap>
                    <FormTitle>Podstawowe informacje o usłudze</FormTitle>
                    <InputBlock>
                        <InputItem>
                            <StyleLabelTooltips style={{height: '15px'}}>
                                <StyledLabel
                                    error={errorMessage?.data?.presentation_name}
                                    htmlFor="advance">Nazwa prezentacji *</StyledLabel>
                                <Tooltips
                                    title='W przypadku chęci zmiany nazwy prezentacji, prosimy o kontakt z opiekunem'>
                                    <TooltipsIcon/>
                                </Tooltips>
                            </StyleLabelTooltips>
                            <StyledInput
                                error={errorMessage?.data?.presentation_name}
                                type="text"
                                id="advance"
                                value={presentationName}
                                onChange={handleInputChange}
                            />
                            {(errorMessage?.data?.presentation_name) && (
                                <ErrorText>{errorMessage?.data?.presentation_name}</ErrorText>
                            )}
                        </InputItem>

                        <InputItem>
                            <StyledLabel
                                error={errorMessage?.data?.offer_type}
                                htmlFor="category"
                            >Typ oferty *</StyledLabel>
                            <OfferTypeSelect
                                error={errorMessage?.data?.offer_type}
                                offerType={offerType}
                                onChange={onChangeOfferType}
                                data={data}
                                setSelectedFeatures={setSelectedFeatures}
                            />
                            {(errorMessage?.data?.offer_type) && (
                                <ErrorText>{errorMessage?.data?.offer_type}</ErrorText>
                            )}
                        </InputItem>
                    </InputBlock>
                    <InputBlock>
                        <InputItem>
                            <StyledLabel
                                error={errorMessage?.data?.category}
                                htmlFor="category">Kategoria *</StyledLabel>
                            <CategorySelect
                                error={errorMessage?.data?.category}
                                offerType={offerType}
                                categoryId={categoryId}
                                data={data}
                                setSelectedFeatures={setSelectedFeatures}
                                setCategoryId={setCategoryId}
                            />
                            {(errorMessage?.data?.category) && (
                                <ErrorText>{errorMessage?.data?.category}</ErrorText>
                            )}
                        </InputItem>
                        <InputItem/>
                    </InputBlock>
                </BlockWrap>

                <BlockWrap>
                    <FormTitle>Cechy obiektu</FormTitle>
                    <CheckboxBlock>
                        <FeatureCheckboxes
                            offerType={offerType}
                            categoryId={categoryId}
                            selectedFeatures={selectedFeatures}
                            onChange={onFeatureChange}
                            data={data}/>
                    </CheckboxBlock>
                </BlockWrap>
                <BlockWrap>
                    <StyledLabel style={{marginBottom: "-10px"}}>Szczegółowy opis</StyledLabel>

                    <ToggleGroup objectDescription={objectDescription} setObjectDescription={setObjectDescription}/>

                    <PresentationFile/>

                    <InputBlock>

                        <InputItem>
                            <StyledLabel
                                error={errorMessage?.data?.persons_from}
                                htmlFor='persons_from'>
                                Liczba osób od
                            </StyledLabel>
                            <StyledInput
                                error={errorMessage?.data?.persons_from}
                                type="text"
                                value={personsFrom}
                                onChange={(e) => setPersonsFrom(e.target.value)}
                            />
                            {(errorMessage?.data?.persons_from) && (
                                <ErrorText>{errorMessage?.data?.persons_from}</ErrorText>
                            )}
                        </InputItem>

                        <InputItem>
                            <StyledLabel
                                error={errorMessage?.data?.persons_to}
                                htmlFor='persons_to'
                            >
                                Liczba osób do</StyledLabel>
                            <StyledInput
                                error={errorMessage?.data?.persons_to}
                                type="text"
                                value={personsTo}
                                onChange={(e) => setPersonsTo(e.target.value)}
                            />
                            {(errorMessage?.data?.persons_to) && (
                                <ErrorText>{errorMessage?.data?.persons_to}</ErrorText>
                            )}
                        </InputItem>
                    </InputBlock>

                </BlockWrap>
            </FormBlock>
            <FormBlock id="address-data">
                <BlockWrap>
                    <FormTitle>Dane teleadresowe</FormTitle>
                    <MapBlock address={address} setAddress={setAddress}/>
                </BlockWrap>
            </FormBlock>
            <FormBlock id="information-additional">
                <BlockWrap>
                    <FormTitle>Informacje dodatkowe</FormTitle>
                    <InputBlock>
                        <InputItem>
                            <StyledLabel
                                error={errorMessage?.data?.homepage}
                            >Strona WWW</StyledLabel>
                            <StyledInput
                                error={errorMessage?.data?.homepage}
                                value={linkHomepage}
                                onChange={(e) => setLinkHomepage(e.target.value)}
                            />
                            {(errorMessage?.data?.homepage) && (
                                <ErrorText>{errorMessage?.data?.homepage}</ErrorText>
                            )}
                        </InputItem>
                        <InputItem>
                            <StyleLabelTooltips style={{height: '15px'}}>
                                <StyledLabel
                                    error={errorMessage?.data?.contact_email}
                                    htmlFor="advance">Email</StyledLabel>
                                <Tooltips
                                    title='W przypadku chęci zmiany adresu e-mail, prosimy o kontakt z opiekunem'>
                                    <TooltipsIcon/>
                                </Tooltips>
                            </StyleLabelTooltips>
                            <StyledInput
                                error={errorMessage?.data?.contact_email}
                                type='email'
                                value={linkEmail}
                                onChange={(e) => setLinkEmail(e.target.value)}
                            />
                            {(errorMessage?.data?.contact_email) && (
                                <ErrorText>{errorMessage?.data?.contact_email}</ErrorText>
                            )}
                        </InputItem>
                    </InputBlock>

                    <InputBlock>
                        <InputItem>
                            <StyledLabel
                                error={errorMessage?.data?.facebook}>Link do profilu Facebook</StyledLabel>
                            <StyledInput
                                error={errorMessage?.data?.facebook}
                                value={linkFacebook}
                                onChange={(e) => setLinkFacebook(e.target.value)}
                            />
                            {(errorMessage?.data?.facebook) && (
                                <ErrorText>{errorMessage?.data?.facebook}</ErrorText>
                            )}
                        </InputItem>
                        <InputItem>
                            <StyledLabel error={errorMessage?.data?.instagram}>Link do profilu Instagram</StyledLabel>
                            <StyledInput
                                error={errorMessage?.data?.instagram}
                                value={linkInstagram}
                                onChange={(e) => setLinkInstagram(e.target.value)}
                            />
                            {(errorMessage?.data?.instagram) && (
                                <ErrorText>{errorMessage?.data?.instagram}</ErrorText>
                            )}
                        </InputItem>
                    </InputBlock>
                    <InputBlock>
                        <InputItem>
                            <StyledLabel
                                error={errorMessage?.data?.youtube}
                            >Link do profilu YouTube</StyledLabel>
                            <StyledInput
                                error={errorMessage?.data?.youtube}
                                value={linkYoutube}
                                onChange={(e) => setLinkYoutube(e.target.value)}
                            />
                            {(errorMessage?.data?.youtube) && (
                                <ErrorText>{errorMessage?.data?.youtube}</ErrorText>
                            )}
                        </InputItem>
                        <InputItem>
                            <StyledLabel
                                error={errorMessage?.data?.vimeo}
                            >Link do profilu Vimeo</StyledLabel>
                            <StyledInput
                                error={errorMessage?.data?.vimeo}
                                value={linkVimeo}
                                onChange={(e) => setLinkVimeo(e.target.value)}
                            />
                            {(errorMessage?.data?.vimeo) && (
                                <ErrorText>{errorMessage?.data?.vimeo}</ErrorText>
                            )}
                        </InputItem>
                    </InputBlock>
                    <InputBlock>
                        <InputItem>
                            <StyledLabel
                                error={errorMessage?.data?.tiktok}
                            >Link do profilu TikTok</StyledLabel>
                            <StyledInput
                                error={errorMessage?.data?.tiktok}
                                value={linkTiktok}
                                onChange={(e) => setLinkTiktok(e.target.value)}
                            />
                            {(errorMessage?.data?.tiktok) && (
                                <ErrorText>{errorMessage?.data?.tiktok}</ErrorText>
                            )}
                        </InputItem>
                    </InputBlock>
                </BlockWrap>
            </FormBlock>
            <FormMultimedia
                youtubeVideo={youtubeVideo}
                setYoutubeVideo={setYoutubeVideo}
                vimeoVideo={vimeoVideo}
                setVimeoVideo={setVimeoVideo}
            />
            <SubmitWrap>
                <SubmitBlock>
                    <QuestionSubmit style={{marginLeft: "auto"}} type="submit">
                        {updateLoadingState ? <Loader/> : <CheckIcon/>}
                        <span>Zapisz</span>
                    </QuestionSubmit>
                </SubmitBlock>
            </SubmitWrap>
        </FormPresentationWrap>
    );

};

