import React from 'react'
import {GoogleMap} from '@react-google-maps/api';
import {defaultTheme} from "./Theme";
import {CurrentLocationMarker} from "../current-location-marker/CurrentLocationMarker";
import {MapBlockWrap} from "../style-map-block";


const containerStyle = {
    width: '100%',
    height: '315.56px',
};

const defaultOptions = {
    panControl: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: false,
    keyboardShortcuts: false,
    scrollwheel: false,
    disableDoubleClickZoom: false,
    fullscreenControl: false,
    styles: defaultTheme,
    language: 'pl',

}

interface IMap {
    center: any;
}


export const Map = ({center}: IMap) => {

    const mapRef = React.useRef(undefined)

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map: any) {
        mapRef.current = map
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        mapRef.current = undefined
    }, [])

    return (
        <MapBlockWrap>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={defaultOptions}
            >
                <CurrentLocationMarker position={center} />
            </GoogleMap>
        </MapBlockWrap>

    )
}

