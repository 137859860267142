import styled from "styled-components";


export const FaqWrap = styled.div`

    margin-bottom: 120px;
    @media (max-width: 768.98px) {
        margin-bottom: 20px;
    }
`;
export const CollapseWrap = styled.div`
    background: #fff;
    padding: 20px 0;
`;
export const FAQTitle = styled.p`
    color: #0C1215;
    font-family: Gilroy;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 26.4px */
    margin-bottom: 20px;
`;


export const FormText = styled.p`
    color: #0C1215;
    font-family: Gilroy;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 20px;
    max-width: 852px;
`;


export const CollapsePopup = styled.p`
    display: table;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #1056b2;
    margin: 30px 0 10px;
    cursor: pointer;
    text-decoration-line: underline;
    transition: text-decoration 0.3s ease-in-out;

    &:hover {
        text-decoration-line: none;
    }

`;


export const BlockFaq = styled.div`
    display: flex;
    gap: 20px 50px;
    @media (max-width: 1380.98px) {
        gap: 20px;
    }
`;


export const EditButtonFaqBlock = styled.div`
    padding-top: 30px;
    @media (max-width: 1400.98px) {
        display: flex;
        justify-content: center;
    }
`;

export const EditButtonFaq = styled.button`
    display: flex;
    width: 200px;
    padding: 12px 30px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 200px 100px 100px 0;
    border: 1px solid #D2DAE3;
    opacity: 0.800000011920929;
    background: var(--white, #FFF);
    color: #0C1215;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    transition: border-color 0.3s ease, opacity 0.3s ease;

    @media (max-width: 1380.98px) {
        height: 40px;
        width: auto;
    }

    @media (max-width: 480.98px) {
        border-radius: 50px;
        padding: 10px;
        //align-items: end;
    }




    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16.48px;
        color: #0C1215;
        @media (max-width: 425.98px) {
            display: none;
        }
    }

    &:hover {
        border-color: #a5adbb;
        opacity: 1;
    }
`;


export const CollapseBlock = styled.div`
    background: #fff;
    width: 100%;
    //max-width: 852px;
    max-width: 970px;
    @media (max-width: 1112.98px) {
        max-width: 710px;
    }
    @media (max-width: 1094.98px) {
        max-width: 650px;
    }
    @media (max-width: 1068.98px) {
        max-width: 600px;
    }
    @media (max-width: 1022.98px) {
        max-width: 580px;
    }

    @media (max-width: 736.98px) {
        max-width: 100%;
    }

    .ant-collapse {
        border: none;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 30px 0 15px 0;
        flex-direction: row-reverse;
        border-radius: 0 0 0 0;
        border: 0px;
        background: #fff;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
        transform: rotate(90deg);
    }

    .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
        color: var(--black, #0C1215);
        font-family: Gilroy;
        font-size: 18px;
        font-weight: 300;
        line-height: 140%;
        opacity: 0.6000000238418579;
        padding: 0 0 16px 0;
    }

    .ant-collapse .ant-collapse-item:last-child > .ant-collapse-content {
        border-radius: 0;
        border: white;
    }

    .ant-collapse-header-text {
        color: var(--black, #0C1215);
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
    }

    @media (max-width: 768.98px) {
        .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
            transform: none;
        }
    }

`;

export const EditButton = styled.button`
    display: flex;
    width: 200px;
    padding: 12px 30px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 200px 100px 100px 0;
    border: 1px solid #D2DAE3;
    opacity: 0.800000011920929;
    background: var(--white, #FFF);
    color: #0C1215;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    transition: border-color 0.3s ease, opacity 0.3s ease;

    @media (max-width: 449.98px) {
        width: 100%;
    }

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16.48px;
        color: #0C1215;
    }

    &:hover {
        border-color: #a5adbb;
        opacity: 1;
    }
`;
