import React from 'react';
import usePopup from "../../../../../../common/hook/use-popup";
import {ReactComponent as ArrayRightIcon} from '../../../../../../assets/main/form/array-right.svg';
import {Popups} from "../../../../../../common/popups/Popups";
import {FormArchive} from "../../../../account/forms/archive-form/FormArchive";
import {FullInformationWrap} from "./style-full-information";

interface PropsType {
    handleClosePopups: () => void;
    showCalendarPopup:boolean;
}

export const FullInformation: React.FC<PropsType> = ({handleClosePopups,showCalendarPopup}) => {
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();

    if (showCalendarPopup && showPopup) {
        handleClosePopups()
    }

    return (
        <FullInformationWrap onClick={onClickHandler} >
            <p>Zobacz pełną informację</p>
            <ArrayRightIcon/>
            <Popups
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<FormArchive handleClosePopups={handleClosePopup}/>}
            />
        </FullInformationWrap>
    );
};

