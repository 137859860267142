import React from 'react';
import {AdvertisentContent, FullWrapper} from "../../layout/style-layout";
import {Hall} from "./hall/hall";
import {Statistics} from "./statistics/Statistics";
import {Advertisement} from "./advertisement/advertisement";

export const Account = () => {
    return (
        <FullWrapper>
            <Hall/>
            <Statistics/>
            <AdvertisentContent>
                <Advertisement title='Zwiększ zasięg reklamy?'
                               text='Wybierz odpowiedni plan taryfowy i wypromuj swoją firmę'/>
            </AdvertisentContent>
        </FullWrapper>
    );
};

