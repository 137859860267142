import React from "react";
import {ReactComponent as AccountIcon} from '../../../assets/navigate/account.svg';
import {ReactComponent as AdvertisingIcon} from '../../../assets/navigate/advertising.svg';
import {ReactComponent as StatisticsIcon} from '../../../assets/navigate/statistics.svg';
import {ReactComponent as CalendarIcon} from '../../../assets/navigate/calendar.svg';
import {ReactComponent as FeedbackIcon} from '../../../assets/navigate/feedback.svg';
import {ReactComponent as PresentationIcon} from '../../../assets/navigate/presentation.svg';
import {ReactComponent as MyAdsIcon} from '../../../assets/navigate/myAds.svg';
import {ReactComponent as BannersIcon} from '../../../assets/navigate/banners.svg';
import {ReactComponent as PasswordIcon} from '../../../assets/navigate/password.svg';

export const menuItems = [
    {
        icon: <AccountIcon/>,
        title: 'Twoje konto',
        path: '/',
    },
    {
        icon: <PresentationIcon/>,
        title: 'Prezentacja',
        path: '/presentation',
    },
    {
        icon: <AdvertisingIcon/>,
        title: 'Plan taryfowy i reklama',
        path: '/advertising',
    },
    {
        icon: <StatisticsIcon/>,
        title: 'Statystyki',
        path: '/statistics?tab=0',
    },
    {
        icon: <CalendarIcon/>,
        title: 'Kalendarz',
        path: '/calendar',
    },
    {
        icon: <FeedbackIcon/>,
        title: 'Opinie',
        path: '/feedback',
    },
    {
        icon: <MyAdsIcon/>,
        title: 'Moje ogłoszenia',
        path: '/ads',
    },
    {
        icon: <BannersIcon/>,
        title: 'Banery i certyfikaty',
        path: '/banners',
    },
    {
        icon: <PasswordIcon/>,
        title: 'Zmień hasło',
        path: '/password-change',
    },

];
