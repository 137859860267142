import React, {useCallback, useEffect, useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.css';
import {CustomToolbar} from "components/pages/presentation/form-presentation/toggle-group/CustomToolbar";


interface ToggleGroupProps {
    objectDescription: string;
    setObjectDescription: React.Dispatch<React.SetStateAction<string>>;
}

const ToggleGroup: React.FC<ToggleGroupProps> = ({objectDescription, setObjectDescription}) => {
    const [editorHtml, setEditorHtml] = useState<string>('');

    useEffect(() => {
        if (objectDescription) {
            setEditorHtml(objectDescription);
        } else {
            setEditorHtml('');
        }
    }, [objectDescription]);


    const handleTextChange = useCallback((html: string) => {
        setEditorHtml(html);
        setObjectDescription(html);
    }, [setObjectDescription]);


    const modules = {
        toolbar: {
            container: "#toolbar",
        },
        clipboard: {
            matchVisual: false,
        }
    };

    const formats = [
        'font',
        'size',
        'small', 'large', 'huge',
        'bold', 'italic', 'underline', 'blockquote',
        'indent',
        'link',
    ];

    return (
        <div className="text-editor">
            <CustomToolbar editorHtml={editorHtml}/>
            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={editorHtml}
                onChange={handleTextChange}
                className="my-custom-editor"
            />
        </div>
    );
};

export default ToggleGroup;
