import React, {FC, useState} from "react";
import {
    ContHeg50,
    ContListSelectMate,
    ContSelectCenter, ContSelectInt,
    IconSelectMate,
    SelecionadoOpcion,
    Select,
    SelectMate
} from "./style-selector";
import {ReactComponent as Array} from '../../../../../assets/main/form/arraySelector.svg';

export interface Option {
    name: string;
    value: string;
    checked?: boolean;
}


interface SelectBoxProps {
    name: string;
    options: Option[];
}


export const Selector:FC<SelectBoxProps> = ({ name, options }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectOpen, setSelectOpen] = useState(false);
    const [arrayRotated, setArrayRotated] = useState(false);

    const handleClickOption = (index: number) => {
        setSelectedIndex(index);
        setSelectOpen(false);
        setArrayRotated(false);
    };

    const handleToggleSelect = () => {
        setSelectOpen((prevState) => !prevState);
        setArrayRotated((prevState) => !prevState);
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const index = event.target.selectedIndex;
        setSelectedIndex(index);
    };

    return(
        <ContSelectCenter>
            <ContHeg50 />
            <SelectMate data-mate-select="active">
                <Select name={name} onChange={handleSelectChange} onClick={(e) => e.preventDefault()}>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </Select>
                <SelecionadoOpcion onClick={handleToggleSelect}>{options[selectedIndex].name}</SelecionadoOpcion>
                <IconSelectMate onClick={handleToggleSelect} rotated={arrayRotated}>
                    <Array />
                </IconSelectMate>
                <ContListSelectMate>
                    <ContSelectInt style={{ height: selectOpen ? 'auto' : 0 }}>
                        {options.map((option, index) => (
                            <li
                                key={index}
                                className={index === selectedIndex ? 'active' : ''}
                                onClick={() => handleClickOption(index)}
                            >
                                {option.name}
                            </li>
                        ))}
                    </ContSelectInt>
                </ContListSelectMate>
            </SelectMate>
        </ContSelectCenter>

    );
};

