import React, {useEffect, useState} from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {CompareDates} from "components/pages/statistics/сompare-dates/CompareDates";
import {StatisticsCover} from "components/pages/statistics/statistics-cover/StatisticsCover";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        tooltip: {
            backgroundColor: '#fff',
            borderRadius: 20,
            bodyColor: '#0C1215',
            boxShadow: '0px 2px 6px 0px rgba(74, 74, 74, 0.15)',
            bodyFontSize: 12,
            padding: 10,
            yAlign: 'bottom' as const,
            displayColors: false,
            maxWidth: 143,
            bodyAlign: 'center' as const,
            "bodySpacing": 0.12,
            opacity: 0.5,
            cursor: 'pointer',
        },
        legend: {
            display: false,
        },
        title: {
            display: true,
        },
    },
};

export const Charts = ({ data, activeTab }: { data: any; activeTab: any }) => {
    const [startDateArray, setStartDateArray] = useState<number[]>([]);
    const [finishDateArray, setFinishDateArray] = useState<number[]>([]);


    useEffect(() => {
        if (data && data.date && data.date.length > 0) {
            console.log(activeTab,'activeTab')
            const tabData = data.date.find((item: any) => item.id === activeTab);

            console.log(data.date, 'Весь массив'); // Весь массив
            console.log(tabData, 'Только найденный объект'); // Только найденный объект

            if (tabData) {
                setStartDateArray(tabData.dates.start_date || []);
                setFinishDateArray(tabData.dates.finish_date || []);
            }
        }
    }, [data, activeTab]);


    const chartData = {
        labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
        datasets: [
            {
                label: 'start_date wyświetleń numeru telefonu',
                data: startDateArray,
                borderColor: '#A33939',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderWidth: 1,
            },
            {
                label: 'finish_date wyświetleń numeru telefonu',
                data: finishDateArray,
                borderColor: '#1056B2',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                borderWidth: 1,
            },
        ],
    };
    return (
        <div style={{ width: '100%' }}>
            <CompareDates />
            <Line options={options} data={chartData} />
            <StatisticsCover />
        </div>
    );
};
