import React from 'react';
import {FullWrapper} from "../../layout/style-layout";
import {Advertisement} from "../account/advertisement/advertisement";
import {PresentationNavigation} from "./presentation-navigate/PresentationNavigation";
import {FormPresentation} from "./form-presentation/FormPresentation";
import {Faq} from "components/pages/presentation/form-presentation/faq/Faq";

export const Presentation = () => {
    // const {data, error, isLoading} = useCompanyQuery();
    // const [updatePresentation, {isLoading: updateLoadingState}] = useCompanyEditMutation()
    // const companyId = getCompanyId();
    //
    //
    // const handlePresentationSubmit = async (args: ) => {
    //     try {
    //          updatePresentation(formData);
    //     } catch (error) {
    //         console.error("Error submitting data:", error);
    //     }
    // };


    return (
        <FullWrapper>
            <PresentationNavigation/>
            <FormPresentation/>
            <Faq/>
            <Advertisement title='Zwiększ zasięg reklamy?'
                           text='Wybierz odpowiedni plan taryfowy i wypromuj swoją firmę'/>
        </FullWrapper>
    );
};



