import {CompanyData} from "services/company/company.types";
import {Checkbox} from "antd";
import {CheckboxBox} from "components/pages/presentation/form-presentation/style-form-presentation";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {ChangeEvent, FC} from "react";

interface FeatureCheckboxesProps {
    offerType: string;
    selectedFeatures: string[];
    onChange: (e: ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => void;
    categoryId: string;
    data?: CompanyData;
}

export const FeatureCheckboxes: FC<FeatureCheckboxesProps> = (
    {
        offerType,
        categoryId,
        selectedFeatures,
        onChange,
        data
    }
) => {
    const featuresAll = data?.options.offer_types[offerType]?.categories?.[categoryId]?.features || {};


    return (
        <CheckboxBox>
            {Object.entries(featuresAll).map(([featureId, featureTitle]) => (
                <Checkbox
                    key={featureId}
                    checked={(selectedFeatures.includes(featureId))}
                    value={featureId}
                    onChange={onChange}
                >
                    {featureTitle}
                </Checkbox>
            ))}
        </CheckboxBox>
    );
};
