import styled from "styled-components";

export const SingInContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
`

export const SingInImgContainer = styled.div`
    position: relative;
    width: 50%;
    height: 100vh;
    @media screen and (max-width: 897.98px) {
        display: none;
    }
`

export const SingInIcon = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: #fff;
    stroke-width: 1.10px;
    stroke: #fff;
    filter: brightness(80%);
`

export const SingInImg = styled.img`
    position: relative;
    width: 100%;
    height: 100vh; 
    filter: brightness(40%);
`;

export const SingInFormContainer = styled.div`
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
 

    @media screen and (max-width: 897.98px) {
        width: 100%;
        padding: 0 10px 0 10px;

    }
`

export const SingInForm = styled.form`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    max-width: 416px;
    width: 100%;
    //width: 416px;
    gap: 10px;
`
export const SingInBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const SingInContentBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
`;

export const SingInButton = styled.button`
    border-radius: 200px 100px 100px 0;
    height: 48px;
    margin-top: 15px;
    width: 100%;
    background: ${props => props.disabled ? '#CCCCCC' : '#1056B2'}; 
    padding: 12px 30px;
    gap: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.disabled ? '#666666' : '#FFFFFF'}; 
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'}; 
    @media (max-width: 425.98px) {
        max-width: 100%;
    }

    &:hover {
        background: ${props => props.disabled ? '#CCCCCC' : 'linear-gradient(90deg, #1056B2 0%, #2779E4 100%)'}; /* Если кнопка отключена, использовать серый цвет, иначе применить градиент */
    }
`;

export const ContentButton = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;




export const SingInForgot = styled.div`
    font-weight: 500;
    font-size: 12px;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: #1056b2;
    transition: text-decoration 0.3s ease; /* Продолжительность анимации: 0.3 секунды */

    &:hover {
        text-decoration: none; /* Убираем подчеркивание при наведении */
        color: #6a8bb6;
    }
`;

export const SignSocialNetworksBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 18px;
`;

export const NetworksBlockText = styled.p`
    font-weight: 500;
    font-size: 12px;
    color: #1056b2;
`;

export const NetworksBlockSocials = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
export const SharingArrayLeftBlock = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  svg {
    width: 17px;
    height: 17px;
  }

  span {
    color:  #0C1215;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }

`
export const ContentInput = styled.input`
    cursor: pointer;
`;


export const ContentTitle = styled.div<{ isDone: boolean }>`
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: ${props => (props.isDone ? 'rgba(12, 18, 21, 0.40)' : '#0C1215')};
    cursor: pointer;
`;

