import React, {useCallback, useEffect, useState} from 'react';
import {Map} from "./map/Map";
import {MapBlockWrap} from "./style-map-block";
import {useJsApiLoader, useLoadScript} from "@react-google-maps/api";
import {getBrowserLocation} from "../../../../../common/utils/geo";
import {Autocomplete} from "components/pages/presentation/form-presentation/map-block/autocomplete/Autocomplete";


type AddressProps = {
    address:string;
    setAddress:(value:string) => void
}

export type Coordinates = {
    lat: number;
    lng: number;
};


const API_KEY = process.env.REACT_APP_API_KEY || '';

const libraries: any = ['places'];

const defaultCenter = {
    lat: 52.2297,
    lng: 21.0122
};


export const MapBlock = ({address,setAddress}:AddressProps) => {
    const [center, setCenter] = useState<Coordinates>(defaultCenter);

    const {isLoaded} = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY,
        // googleMapsApiKey: 'AIzaSyDHUo9DrxIZDTAZyugAg-X7wmkMrw6RKpQ',
        libraries
    });

    // const {isLoaded} = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: API_KEY,
    //     libraries
    // });

    const onPlaceSelect = useCallback(
        // (coordinates: Coordinates) => {
        (coordinates: any) => {
            setCenter(coordinates);
        },
        []
    );

    useEffect(() => {
        getBrowserLocation()
            .then((curLoc) => {
                setCenter(curLoc as Coordinates);
            })
            .catch((error) => {
                console.error('Error fetching browser location:', error);
                setCenter(defaultCenter);
            });
    }, []);


    return (
        <MapBlockWrap>
            <Autocomplete
                isLoaded={isLoaded}
                onSelect={onPlaceSelect}
                setAddress={setAddress}
                address={address}
            />
            {isLoaded ? <Map center={center}/> : <h2>Loading</h2>}
        </MapBlockWrap>
    );
};


// import React, {useCallback, useEffect, useState} from 'react';
// import {Map} from "./map/Map";
// import {MapBlockWrap} from "./style-map-block";
// import {useLoadScript} from "@react-google-maps/api";
// import {getBrowserLocation} from "../../../../../common/utils/geo";
// import {Autocomplete} from "components/pages/presentation/form-presentation/map-block/autocomplete/Autocomplete";
//
//
// type AddressProps = {
//     register: any;
// }
//
// export type Coordinates = {
//     lat: number;
//     lng: number;
// };
//
//
// const API_KEY = process.env.REACT_APP_API_KEY || '';
//
// const libraries: any = ['places'];
//
// const defaultCenter = {
//     lat: 52.2297,
//     lng: 21.0122
// };
//
//
// export const MapBlock = ({register}: AddressProps) => {
//     const [center, setCenter] = useState<Coordinates>(defaultCenter);
//
//     const {isLoaded} = useLoadScript({
//         id: 'google-map-script',
//         googleMapsApiKey: API_KEY,
//         // googleMapsApiKey: 'AIzaSyDHUo9DrxIZDTAZyugAg-X7wmkMrw6RKpQ',
//         libraries
//     });
//
//     // const {isLoaded} = useJsApiLoader({
//     //     id: 'google-map-script',
//     //     googleMapsApiKey: API_KEY,
//     //     libraries
//     // });
//
//     const onPlaceSelect = useCallback(
//         // (coordinates: Coordinates) => {
//         (coordinates: any) => {
//             setCenter(coordinates);
//         },
//         []
//     );
//
//     useEffect(() => {
//         getBrowserLocation()
//             .then((curLoc) => {
//                 setCenter(curLoc as Coordinates);
//             })
//             .catch((error) => {
//                 console.error('Error fetching browser location:', error);
//                 setCenter(defaultCenter);
//             });
//     }, []);
//
//
//     return (
//         <MapBlockWrap>
//             <Autocomplete
//                 isLoaded={isLoaded}
//                 onSelect={onPlaceSelect}
//                 register={register}
//             />
//             {isLoaded ? <Map center={center}/> : <h2>Loading</h2>}
//         </MapBlockWrap>
//     );
// };
