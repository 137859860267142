import Cookies from "universal-cookie";
import {baseApi} from "services/base-api";



const cookies = new Cookies();

const getToken = (): string => {
    const token = cookies.get('jwt_authorization');
    if (!token) {
        throw new Error('Токен авторизации не найден в куки');
    }
    return token;
}

export const getCompanyId = (): string => {
    const companyId = cookies.get('company_id');
    if (!companyId) {
        throw new Error('companyId не найден в куки');
    }
    return companyId;
}

export const multimediaGalleryService = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        multimediaGallery: builder.query<any, void>({
            providesTags: ['MultimediaGallery'],
            query: () => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    method: 'GET',
                    url: `/company/${companyId}/gallery/?sort=desc`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        multimediaGalleryAdd: builder.mutation<any, any>({
            invalidatesTags: ['MultimediaGallery'],
            query: (body) => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    body,
                    method: 'POST',
                    url: `/company/${companyId}/gallery/?sort=desc`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        multimediaGallerySort: builder.mutation<any, string[]>({
            invalidatesTags: ['MultimediaGallery'],
            query: (body) => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    body,
                    method: 'PATCH',
                    url: `/company/${companyId}/gallery/arrange/?sort=desc/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        multimediaGalleryDelete: builder.mutation<any, any>({
            invalidatesTags: ['MultimediaGallery'],
            query: (imageId) => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    method: 'DELETE',
                    url: `/company/${companyId}/gallery/${imageId}/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
    }),
});

export const {
    useMultimediaGalleryQuery,
    useMultimediaGalleryAddMutation,
    useMultimediaGallerySortMutation,
    useMultimediaGalleryDeleteMutation,
} = multimediaGalleryService;



//     companyEdit: builder.mutation<any, any>({
//         invalidatesTags: ['Company'],
//         query: (body) => {
//             const token = getToken();
//             const companyId = getCompanyId();
//             return {
//                 body,
//                 method: 'PUT',
//                 url: `/company/${companyId}/`,
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 }
//             };
//         },
//     }),
// }),
