import styled from "styled-components";


interface StyledBigFileImgProps {
    imageUrl: any;
}
interface StyledSmallFileImgProps {
    imageUrl: any;
}

export const UploadWrapperRight = styled.div`
  span {
    div{
      span{
        div{
          display: flex;
          align-items: center;
          column-gap: 8px;
          background: linear-gradient(90deg, #1056B2 0%, #2779E4 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &:hover {
            color: #FFFFFF;
            }
         span {
           svg {
             fill:#2475df;
           }
         }
          div{
            margin-top: 0 !important;
          }
        }
      }
    }
  }
  p {
    color: var(--black, #0C1215);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 8px;
  }

  &&& {
    .ant-upload-picture-card-wrapper .ant-upload-select,
    .ant-upload-picture-circle-wrapper .ant-upload-select {
      position: relative;
      width: 100%;
      height: 330px;
      border-radius: 5px;
      border: 1px solid #DEE2E8;
      background: #FFF;
      transition: all 375ms ease-in-out;

      &:hover {
        background: #999999;
        box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
        border-radius: 5px;
        color: #FFFFFF;

        svg {
          fill: #FFFFFF;
        }
      }

      @media (max-width: 425.98px) {
        height: 230px;
      }

      p {
        position: absolute;
        left: 15px;
        top: 16px;
      }
    }
  }
`;

export const AdsFormBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AdsBigFormBlock = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 513px;
    width: 513px;
    @media (max-width: 558.98px) {
        width: 100%;
        max-width: 395px;
    }
`;
export const AdsSmallFormBlock = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (max-width: 425.98px) {
        width: 100%;
        max-width: 395px;
    }
`;

export const AdsLabel = styled.label`
    color:  #0C1215;
    font-family: Gilroy,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
  margin-bottom: 5px;
  max-width: 280px;
`

export const StyledBigFileInputContainer = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //width: 100%;
    //max-width: 513px;
    width: 513px;
    height: 330px;
    outline: none;
    cursor: pointer;
    text-align: center;
    //padding: 16px;
    background: #FFFFFF;
    border: 1px solid #D2DAE3;
    box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
    border-radius: 5px;
    color: #1056B2;
    transition: all 0.3s ease;

    &:hover {
        background: #999999;
        box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
        border-radius: 5px;
        color: #FFFFFF;

        svg path {
            fill: #FFFFFF;
        }

        img {
            width: 100%;
        }
    }

    input[type='file'] {
        display: none;
    }

    svg {
        position: absolute;
        top: 5px;
        left: 5px;
        fill: #1056B2;
        transition: fill 0.3s ease;

        &:hover {
            fill: red;
        }
    }

    img {
        width: 30px;
    }

    @media (max-width: 558.98px) {
        max-width: 340px;
        height: 250px;
    }  
    
    @media (max-width: 382.98px) {
        max-width: 330px;
        height: 250px;
    }
`;

export const StyledBigFileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 513px;
  height: 330px;
  outline: none;
  cursor: pointer;
  text-align: center;
  //padding: 16px;
  background: #FFFFFF;
  border: 1px solid #D2DAE3;
  box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
  border-radius: 5px;
  color: #1056B2;
  transition: all 0.3s ease;

  &:hover {
    background: #999999;
    box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
    border-radius: 5px;
    color: #FFFFFF;
      
      img {
          width: 100%;
      }
  }
  input[type='file'] {
    display: none;
  }


    svg {
        position: absolute;
        top: 5px;
        left: 5px;
        fill: #1056B2;
        transition: fill 0.3s ease;

        &:hover {
            fill: #0e3a6c;
        }
    }
    img {
        width: 30px;
    }
    @media (max-width: 558.98px) {
        max-width: 100%;
        width: 340px;
        height: 250px;
    }
    @media (max-width: 382.98px) {
        width: 315px;
    }

`;

export const StyledBigFileImg = styled.div<StyledBigFileImgProps>`
    width: 513px;
    height: 330px;
    background-image: ${(props) => `url(${props.imageUrl}?t=${Date.now()})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;

    @media (max-width: 540.98px) {
        max-width: 100%;
        width: 340px;
        height: 250px;
    }
    @media (max-width: 382.98px) {
        width: 315px;
    }
    
`;

export const StyledSmallFileImg = styled.div<StyledSmallFileImgProps>`
    width: 280px;;
    height: 180px;
    background-image: ${(props) => `url(${props.imageUrl})`};
    background-size: cover;
    //background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
`;

export const CameraIconBlock = styled.div`
position: absolute;
  left: 10px;
  top: 10px;
`



export const StyledSmaleFileInputContainer = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 280px;
    height: 180px;
    outline: none;
    cursor: pointer;
    text-align: center;
    //padding: 16px;
    background: #FFFFFF;
    border: 1px solid #D2DAE3;
    box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
    border-radius: 5px;
    color: #1056B2;
    transition: all 0.3s ease;


    &:hover {
        background: #999999;
        box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
        border-radius: 5px;
        color: #FFFFFF;

        svg path {
            fill: #FFFFFF;
        }
    }

    input[type='file'] {
        display: none;
    }

    svg {
        position: absolute;
        top: 5px;
        left: 5px;
        fill: #1056B2;
        transition: fill 0.3s ease;
    }
`;

export const LoaderBlock = styled.div`
    width: 100%;
    height: 100vh;
    background: #999999;
    box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
    border-radius: 5px;
    color: #FFFFFF;
    display: flex;
    justify-content: center; 
    align-items: center;      

    svg {
        z-index: 5;
    }
`;

export const DraggableBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;


    width: 100%;
    //display: grid;
    //grid-template-columns: repeat(2, 1fr); /* Два элемента в ряду */
    //gap: 30px; /* Пространство между элементами */

`

export const StyledSmaleFileContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 280px;
    height: 180px;
    outline: none;
    cursor: pointer;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #D2DAE3;
    box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
    border-radius: 5px;
    color: #1056B2;
    transition: all 0.3s ease;

    &:not(:last-child) {
        margin-bottom: 30px;
    }


    &:hover {
        background: #999999;
        box-shadow: 0 4px 30px rgba(11, 62, 138, 0.08);
        border-radius: 5px;
        color: #FFFFFF;
    }

    input[type='file'] {
        display: none;
    }

    svg {
        position: absolute;
        top: 5px;
        left: 5px;
        fill: #1056B2;
        transition: fill 0.3s ease;

        &:hover {
            fill: #0e3a6c;
        }
    }
`;


export const FileIconContainer = styled.div`
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  gap: 8px;
  
  svg {
    position: absolute;
    top: 0;
    left: -14px;
  }
`;

export const FileUploadIcon = styled.span`

`;

export const FileUploadText = styled.span`
  font-family: Roboto,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
