import styled from "styled-components";

export const FeedbackBlockWrapper = styled.div`
  display: flex;
  gap: 40px;
  justify-content: space-between;
  @media (max-width: 792.98px) {
   flex-wrap: wrap-reverse;
  }
`;

