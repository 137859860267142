import React, {useState} from 'react';
import {
    CompareDatesWrapper,
    DateBlock,
    DateButtonBlock,
    DateContainer,
    DateItem,
    DateTitle
} from "./style-сompare-dates";
import {QuestionSubmit} from "../../presentation/form-presentation/style-form-presentation";
import {EditButton} from "../../presentation/form-presentation/faq/style-faq";
import type {DatePickerProps} from 'antd';
import {DatePicker} from 'antd';

export const CompareDates = () => {
    const [visible, setVisible] = useState(false)


    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const onClickHandler = () => {
        setVisible(visible => !visible);
    }

    return (
        <CompareDatesWrapper>
            <DateContainer>
                <DateItem>
                    <DateBlock>
                        <p>Data początkowa</p>
                        <DatePicker
                            style={{ width: 122 , height:38}}
                            onChange={onChange}
                            format="DD.MM.YYYY"
                        />
                        <p>Data końcowa</p>
                        <DatePicker
                            style={{ width: 122 , height:38}}
                            onChange={onChange}
                            format="DD.MM.YYYY"
                        />
                    </DateBlock>
                </DateItem>
                {visible && (
                    <DateTitle>Porównaj</DateTitle>
                )}
                <DateItem>
                    {visible && (
                        <>
                            <DateBlock>
                                <p>Data początkowa</p>
                                <DatePicker
                                    style={{ width: 122 , height:38}}
                                    onChange={onChange}
                                    format="DD.MM.YYYY"
                                />
                                <p>Data końcowa</p>
                                <DatePicker
                                    style={{ width: 122 , height:38}}
                                    onChange={onChange}
                                    format="DD.MM.YYYY"
                                />
                            </DateBlock>
                        </>
                    )}
                </DateItem>
            </DateContainer>
            <DateButtonBlock>
                {!visible &&
                    <EditButton onClick={onClickHandler}>
                        <span>Porównaj</span>
                    </EditButton>
                }
                <QuestionSubmit type="submit">
                    <span>Zobacz</span>
                </QuestionSubmit>
            </DateButtonBlock>
        </CompareDatesWrapper>
    );
};




