import React from 'react';
import {
    BannerItem,
    BannersBlock, BannersItem,
    BannersPagesBlock,
    BannersPagesText,
    BannersPagesTitle,
    BannersPagesWrap
} from "./style-banners-pages";


import {
    CertificatesItem,
    CertificatesItemIcons,
    CertificatesItemIconsBlock,
    CertificatesItemTitle,
    ItemIconsText
} from "../certificates-pages/style-certificates-pages";
import {ReactComponent as DownloadIcon} from '../../../../assets/main/banners/download.svg';
import {ReactComponent as ArrowsIcon} from '../../../../assets/main/banners/arrows.svg';
import {ReactComponent as OneIcon} from '../../../../assets/main/banners/one.svg';
import {ReactComponent as TwoIcon} from '../../../../assets/main/banners/two.svg';


export const BannersPages = () => {
    return (
        <BannersPagesWrap>
            <BannersPagesTitle>Banery</BannersPagesTitle>
            <BannersPagesBlock>
                <BannersPagesText>Promujemy tylko najlepsze firmy świadczące usługi weselne na rynku. Bez wątpienia
                    należą
                    Państwo do takich firm, dlatego zachęcamy do umieszczenia banera GdzieWesele.pl na Państwa stronie
                    www,
                    który potwierdza jakość Państwa usług.</BannersPagesText>
                <BannersPagesText>Promujemy tylko najlepsze firmy świadczące usługi weselne na rynku. Bez wątpienia
                    należą
                    Państwo do takich firm, dlatego zachęcamy do umieszczenia banera GdzieWesele.pl na Państwa stronie
                    www,
                    który potwierdza jakość Państwa usług.</BannersPagesText>
            </BannersPagesBlock>

            <BannersBlock>
                <BannerItem>
                    <BannersItem>
                       <OneIcon/>
                    </BannersItem>
                    <CertificatesItemTitle>
                        Banery do pobrania 1
                    </CertificatesItemTitle>
                    <CertificatesItemIconsBlock>
                        <CertificatesItemIcons>
                            <DownloadIcon/>
                            <ItemIconsText>
                                Pobierz SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>
                        <CertificatesItemIcons>
                            <ArrowsIcon/>
                            <ItemIconsText>
                                Skopiuj kod SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>
                    </CertificatesItemIconsBlock>
                </BannerItem>


                <BannerItem>
                    <BannersItem>
                       <TwoIcon/>
                    </BannersItem>
                    <CertificatesItemTitle>
                        Banery do pobrania 2
                    </CertificatesItemTitle>
                    <CertificatesItemIconsBlock>
                        <CertificatesItemIcons>
                            <DownloadIcon/>
                            <ItemIconsText>
                                Pobierz SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                        <CertificatesItemIcons>
                            <ArrowsIcon/>
                            <ItemIconsText>
                                Skopiuj kod SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                    </CertificatesItemIconsBlock>
                </BannerItem>

                <BannerItem>
                    <BannersItem>
                        <OneIcon/>
                    </BannersItem>
                    <CertificatesItemTitle>
                        Banery do pobrania 3
                    </CertificatesItemTitle>
                    <CertificatesItemIconsBlock>
                        <CertificatesItemIcons>
                            <DownloadIcon/>
                            <ItemIconsText>
                                Pobierz SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                        <CertificatesItemIcons>
                            <ArrowsIcon/>
                            <ItemIconsText>
                                Skopiuj kod SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                    </CertificatesItemIconsBlock>
                </BannerItem>


                <BannerItem>
                    <BannersItem>
                        <TwoIcon/>
                    </BannersItem>
                    <CertificatesItemTitle>
                        Banery do pobrania 4
                    </CertificatesItemTitle>
                    <CertificatesItemIconsBlock>
                        <CertificatesItemIcons>
                            <DownloadIcon/>
                            <ItemIconsText>
                                Pobierz SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                        <CertificatesItemIcons>
                            <ArrowsIcon/>
                            <ItemIconsText>
                                Skopiuj kod SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                    </CertificatesItemIconsBlock>
                </BannerItem>


                <BannerItem>
                    <BannersItem>
                        <OneIcon/>
                    </BannersItem>
                    <CertificatesItemTitle>
                        Banery do pobrania 5
                    </CertificatesItemTitle>
                    <CertificatesItemIconsBlock>
                        <CertificatesItemIcons>
                            <DownloadIcon/>
                            <ItemIconsText>
                                Pobierz SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                        <CertificatesItemIcons>
                            <ArrowsIcon/>
                            <ItemIconsText>
                                Skopiuj kod SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                    </CertificatesItemIconsBlock>
                </BannerItem>

                <BannerItem>
                    <BannersItem>
                        <TwoIcon/>
                    </BannersItem>
                    <CertificatesItemTitle>
                        Banery do pobrania 6
                    </CertificatesItemTitle>
                    <CertificatesItemIconsBlock>
                        <CertificatesItemIcons>
                            <DownloadIcon/>
                            <ItemIconsText>
                                Pobierz SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                        <CertificatesItemIcons>
                            <ArrowsIcon/>
                            <ItemIconsText>
                                Skopiuj kod SVG
                            </ItemIconsText>
                        </CertificatesItemIcons>

                    </CertificatesItemIconsBlock>
                </BannerItem>
            </BannersBlock>

        </BannersPagesWrap>

    )
        ;
};

