import React from 'react';
import {ReactComponent as СrossIcon} from '../../../../../assets/main/form/cross.svg';
import {ReactComponent as BoxIcon} from '../../../../../assets/main/calendar/box.svg';
import {FormContainer, FormWrapper} from "../../login/account-form/form-login/style-form";

import {StyledTitleCenter, ViewButtonWrap, ViewWrapper} from "../form-view-archive/style-form-view-archive";
import {
    ButtonArchive,
    ButtonNewArchive
} from "../../../сalendar/calendar-block/calendar-header/style-calendar-header-block";
import {CloseButtonTwo} from "../../../feedback/feedback-block/feedback-information/delete-popup/form-delete/form";
import {ArchiveBlock, ArchiveItem, ArchiveWrap} from "./style-form-archive";


interface PropsType {
    handleClosePopups: () => void;
}


export const FormArchive: React.FC<PropsType> = ({handleClosePopups}) => {

    // const {
    //     showPopup: showCalendarPopup,
    //     onClickHandler: onClickCalendarHandler,
    //     handleClosePopup: handleCloseCalendarPopup,
    //     onClickClosest: onClickClosestCalendar,
    // } = usePopup();

    return (
        <FormWrapper onClick={(e) => e.stopPropagation()}>
            <ViewWrapper>
                <CloseButtonTwo type="button" onClick={handleClosePopups}>
                    <СrossIcon/>
                </CloseButtonTwo>
                <StyledTitleCenter>Archiwum na 26.03.2021</StyledTitleCenter>
                <FormContainer>
                    <ArchiveWrap>
                        <ArchiveItem>
                            <ArchiveBlock>
                                <p>Tytuł</p>
                                <span>Ślub Janusz & Lena Biernacka</span>
                            </ArchiveBlock>
                            <ArchiveBlock>
                                <p>Czas</p>
                                <span>od 12:00 do 18:00</span>
                            </ArchiveBlock>
                            <ArchiveBlock>
                                <p>Lokalizacja</p>
                                <span>Kraków, ul. Krakowskie Przedmieście 201, Zielonki Trojanowice, Małopolskie</span>
                            </ArchiveBlock>
                            <ArchiveBlock>
                                <p>Imię Klienta</p>
                                <span>Janusz & Lena Biernacka</span>
                            </ArchiveBlock>
                            <ArchiveBlock>
                                <p>Kontakt</p>
                                <span>+45 506 501 285</span>
                            </ArchiveBlock>
                            <ArchiveBlock>
                                <p>Email</p>
                                <span>janusz_biernacka@gmail.com</span>
                            </ArchiveBlock>
                            <ArchiveBlock>
                                <p>Notatka</p>
                                <span>Wystrój sali wykonają nowożeńcy</span>
                            </ArchiveBlock>
                        </ArchiveItem>
                    </ArchiveWrap>
                </FormContainer>
            </ViewWrapper>
            <ViewButtonWrap>
                <ButtonNewArchive>
                    <BoxIcon/>
                    <span>Zobacz archiwum</span>
                </ButtonNewArchive>
            </ViewButtonWrap>
            {/*<Popups*/}
            {/*show={showCalendarPopup}*/}
            {/*handleClosePopups={handleCloseCalendarPopup}*/}
            {/*onClickClosest={onClickClosestCalendar}*/}
            {/*formComponent={<FormViewArchive showCalendarPopup={showCalendarPopup} handleClosePopups={onClickClosestCalendar}/>}*/}
            {/*/>*/}
        </FormWrapper>
    );
};