import React, { useEffect, useState } from 'react';
import { FullWrapper } from '../../layout/style-layout';
import { Advertisement } from '../account/advertisement/advertisement';
import { Tabs } from './tabs/Tabs';
import { useAppSelector } from 'common/hook';
import { Charts } from './charts/Charts';
import { CompareDates } from "./сompare-dates/CompareDates";
import { StatisticsCover } from "./statistics-cover/StatisticsCover";
import axios from 'axios';

export const Statistics: React.FC = () => {
    const activeTab = useAppSelector((state) => state.tabs.activeTab);
    const [chartData, setChartData] = useState<any>([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.get(`http://localhost:5000/date`);
                // console.log(response.data);

                // setChartData(response.data);
            } catch (error) {

            }
        };
        fetchData();
    }, [activeTab]);

    return (
        <FullWrapper>
            <Tabs />
            <Charts data={chartData} activeTab={activeTab} />
            <Advertisement
                title='Masz wolny termin na wesele?'
                text='Wprowadź wolne terminy na przyjęcie do swojego kalendarza i sprzedaj je'
            />
        </FullWrapper>
    );
};

















// {
//     "date":
//     {
//         "tab_0": {
//         "start_date": [1, 11, 25, 100, 23, 1, 3, 6, 8],
//             "finish_date": [10, 100, 25, 13, 2, 1, 3, 6, 8]
//     },
//         "tab_1": {
//         "start_date": [15, 11, 25, 100, 23, 1, 3, 6, 8],
//             "finish_date": [10, 100, 25, 19, 2, 1, 3, 6, 8]
//     },
//         "tab_2": {
//         "start_date": [50, 11, 25, 100, 25, 1, 3, 6, 8],
//             "finish_date": [10, 100, 25, 50, 2, 1, 3, 6, 8]
//     },
//         "tab_3": {
//         "start_date": [35, 11, 25, 100, 20, 1, 3, 6, 8],
//             "finish_date": [10, 100, 25, 100, 2, 1, 3, 6, 8]
//     }
//     }
//
// }
//



// useEffect(() => {
//     axios({
//         method: 'get',
//         url: 'http://localhost:5000/date',
//     }).then((res) => {
//         console.log(res)
//     })
// }, [activeTab]);
