import React from 'react';
import {AdsCarts} from "./ads-carts/AdsCarts";
import {AdsButton, AdsContainer, CalendarButton, CalendarText, InfoContentBlock, InspirationsLink} from "./style-ads";

import {Link} from "react-router-dom";


import {ReactComponent as Expand} from '../../../assets/main/abs/expand_more.svg';

import {ReactComponent as Plus} from '../../../assets/main/abs/plus.svg';

import {FullWrapper} from "../../layout/style-layout";


export const Ads = () => {
    return (
        <FullWrapper>
            <AdsContainer>
                <InspirationsLink style={{paddingRight:'25px'}}>
                    <Link to="#">
                        <InfoContentBlock><span>Przejdź do zakup</span><Expand/></InfoContentBlock>
                    </Link>
                </InspirationsLink>
                <AdsCarts/>
                <Link to="/adsplace">
                    <AdsButton>
                        <CalendarButton>
                            <Plus/>
                            <CalendarText>
                                <InfoContentBlock>Umieść ogłoszenie</InfoContentBlock>
                            </CalendarText>
                        </CalendarButton>
                    </AdsButton>
                </Link>
            </AdsContainer>
        </FullWrapper>
    )
};


