import React, {useState} from 'react';
import {ReactComponent as DeleteIcon} from '../../../../../assets/main/feedback/Delete.svg';
import {ReactComponent as VectorIcon} from '../../../../../assets/main/feedback/vector.svg';
import {ReactComponent as ResponsIcon} from '../../../../../assets/main/feedback/respons.svg';
import {ReactComponent as CloseIcon} from '../../../../../assets/main/feedback/close.svg';
import {ReactComponent as IconlyIcon} from '../../../../../assets/main/feedback/Iconly.svg';

import {
    FeedbackInformationWrapper,
    FormBlock,
    FormBlockButton,
    FormResponse,
    InformationBlock,
    InformationFooter,
    InformationIcon,
    InformationInput,
    InformationRate,
    InformationText,
    InformationTitleBlock,
    MyResponse,
    MyResponseBlock,
    MyResponseText,
    ShowAll
} from "./style-feedback-information";
import avatar from '../../../../../assets/main/feedback/1.png'
import avatar2 from '../../../../../assets/main/feedback/2.png'
import avatar3 from '../../../../../assets/main/feedback/3.png'
import {StyledRate} from "../rating-information/style-rating-information";
import {AdvertisementButton} from "../../../account/advertisement/style-advertisement";
import usePopup from "../../../../../common/hook/use-popup";
import {Popups} from "../../../../../common/popups/Popups";
import {FormDelete} from "./delete-popup/form-delete/FormDelete";


const feedback = [
    {
        avatar: avatar,
        name: 'Justyna',
        date: '28.02.2022',
        defaultValue: '2.5',
        text: 'Wybraliśmy Siedlisko na miejscówkę weselną, zanim to było modne ;) Kiedy przyjechaliśmy rozejrzeć' +
            ' się po raz pierwszy po okolicy, drogę zastąpiła nam sarna, a w trakcie wizyty przehycał przez polanę' +
            ' zając. W trakcie weekendowej wizyty spędziliśmy godzinę przy ognisku z tutejszym lisem. Ale' +
            ' najważniejsze, że Asia i Kamil robią tu niesamowitą atmosferę, dzięki której mogliśmy czuć się jak u' +
            ' siebie - pomogą we wszystkim i dodadzą dużo uśmiechów! Sama stodoła po renowacji też jest' +
            ' niesamowitym miejscem, które zapamiętamy na zawsze. W dniu naszego ślubu pogoda była' +
            ' nieprzewidywalna, co trochę nam zmieniło plany, ale nie było to żadnym problemem. Magiczne miejsce!' +
            ' No i nie sposób pominąć genialnych pomidorówek, papryk i wszystkich innych pyszności :) Asiu',

    },
    {
        avatar: avatar2,
        name: 'Magda',
        date: '25.02.2022',
        defaultValue: '2.5',
        text: 'Wybraliśmy Siedlisko na miejscówkę weselną, zanim to było modne ;) Kiedy przyjechaliśmy rozejrzeć się po raz pierwszy po okolicy, drogę zastąpiła nam sarna, a w trakcie wizyty przehycał przez polanę zając. W trakcie weekendowej wizyty spędziliśmy godzinę przy ognisku z tutejszym lisem.',
    },
    {
        avatar: avatar3,
        name: 'Magda',
        date: '01.03.2022',
        defaultValue: '2.5',
        text: 'Wybraliśmy Siedlisko na miejscówkę weselną, zanim to było modne ;) Kiedy przyjechaliśmy rozejrzeć się po raz pierwszy po okolicy, drogę zastąpiła nam sarna, a w trakcie wizyty przehycał przez polanę zając. W trakcie weekendowej wizyty spędziliśmy godzinę przy ognisku z tutejszym lisem.',
        response: 'Nikt ponieważ wiedzielismy że rzadko bedziemy tam siedzieć więc nie chcieliśmy żeby nasi świadkowie zostawali sami i żeby siedzieli ze swoimi znajomymi.',
        responseAvatar: avatar,
        responseName: "Aneta",
        responseDate: "01.03.2022"
    },
    {
        avatar: avatar2,
        name: 'Justyna',
        date: '28.02.2022',
        defaultValue: '2.5',
        text: 'Wybraliśmy Siedlisko na miejscówkę weselną, zanim to było modne ;) Kiedy przyjechaliśmy rozejrzeć się po raz pierwszy po okolicy, drogę zastąpiła nam sarna, a w trakcie wizyty przehycał przez polanę zając. W trakcie weekendowej wizyty spędziliśmy godzinę przy ognisku z tutejszym lisem. Ale najważniejsze, że Asia i Kamil robią tu niesamowitą atmosferę, dzięki której mogliśmy czuć się jak u siebie - pomogą we wszystkim i dodadzą dużo uśmiechów! Sama stodoła po renowacji też jest niesamowitym miejscem, które zapamiętamy na zawsze. W dniu naszego ślubu pogoda była nieprzewidywalna, co trochę nam zmieniło plany, ale nie było to żadnym problemem. Magiczne miejsce! No i nie sposób pominąć genialnych pomidorówek, papryk i wszystkich innych pyszności :) Asiu, Kamilu, dziękujemy!',
    },
    {
        avatar: avatar,
        name: 'Magda',
        date: '25.02.2022',
        defaultValue: '2.5',
        text: 'Wybraliśmy Siedlisko na miejscówkę weselną, zanim to było modne ;) Kiedy przyjechaliśmy rozejrzeć się po raz pierwszy po okolicy, drogę zastąpiła nam sarna, a w trakcie wizyty przehycał przez polanę zając. W trakcie weekendowej wizyty spędziliśmy godzinę przy ognisku z tutejszym lisem. ',
    },
    {
        avatar: avatar,
        name: 'Magda',
        date: '25.02.2022',
        defaultValue: '2.5',
        text: 'Wybraliśmy Siedlisko na miejscówkę weselną, zanim to było modne ;) Kiedy przyjechaliśmy rozejrzeć się po raz pierwszy po okolicy, drogę zastąpiła nam sarna, a w trakcie wizyty przehycał przez polanę zając. W trakcie weekendowej wizyty spędziliśmy godzinę przy ognisku z tutejszym lisem. ',
    },
    {
        avatar: avatar,
        name: '1',
        date: '25.02.2022',
        defaultValue: '2.5',
        text: 'Wybraliśmy Siedlisko na miejscówkę weselną, zanim to było modne ;) Kiedy przyjechaliśmy rozejrzeć się po raz pierwszy po okolicy, drogę zastąpiła nam sarna, a w trakcie wizyty przehycał przez polanę zając. W trakcie weekendowej wizyty spędziliśmy godzinę przy ognisku z tutejszym lisem. ',
    },
    {
        avatar: avatar,
        name: '2',
        date: '25.02.2022',
        defaultValue: '2.5',
        text: 'Wybraliśmy Siedlisko na miejscówkę weselną, zanim to było modne ;) Kiedy przyjechaliśmy rozejrzeć się po raz pierwszy po okolicy, drogę zastąpiła nam sarna, a w trakcie wizyty przehycał przez polanę zając. W trakcie weekendowej wizyty spędziliśmy godzinę przy ognisku z tutejszym lisem. ',
    },
    {
        avatar: avatar,
        name: '3',
        date: '25.02.2022',
        defaultValue: '2.5',
        text: 'Wybraliśmy Siedlisko na miejscówkę weselną, zanim to było modne ;) Kiedy przyjechaliśmy rozejrzeć się po raz pierwszy po okolicy, drogę zastąpiła nam sarna, a w trakcie wizyty przehycał przez polanę zając. W trakcie weekendowej wizyty spędziliśmy godzinę przy ognisku z tutejszym lisem. ',
    },

];


export const FeedbackInformation = () => {
    const [isFormResponseVisible, setFormResponseVisible] = useState<boolean[]>([]);
    const [displayAllFeedbacks, setDisplayAllFeedbacks] = useState(false);
    // const isDelete = useAppSelector(state => state.popups.isDelete)
    // const dispatch = useAppDispatch()
    const {showPopup, onClickHandler, handleClosePopup, onClickClosest} = usePopup();


    const toggleFormResponseVisibility = (index: number) => {
        setFormResponseVisible((prevVisible) => {
            const updatedVisible = [...prevVisible];
            updatedVisible[index] = !prevVisible[index];
            return updatedVisible;
        });
    };

    const handleCloseFormResponse = (index: number) => {
        setFormResponseVisible((prevVisible) => {
            const updatedVisible = [...prevVisible];
            updatedVisible[index] = false;
            return updatedVisible;
        });
    };

    const maxVisibleFeedbacks = displayAllFeedbacks ? feedback.length : 5;

    return (
        <FeedbackInformationWrapper>
            {feedback.slice(0, maxVisibleFeedbacks).map((el, index) => {
                return (
                    <InformationBlock
                        key={index}
                        hide={!displayAllFeedbacks && index >= 5}
                    >
                        <InformationTitleBlock>
                            <InformationIcon>
                                <img src={el.avatar} alt="avatar"/>
                                <p>{el.name}</p>
                                <VectorIcon/>
                                <p>{el.date}</p>
                            </InformationIcon>
                            <InformationRate>
                                <StyledRate disabled allowHalf defaultValue={2.5}/>
                            </InformationRate>
                        </InformationTitleBlock>
                        <InformationText>
                            {el.text}
                        </InformationText>
                        {el.response && (
                            <MyResponseBlock>
                                <MyResponse>
                                    <ResponsIcon/>
                                    <p>Moja odpowiedź</p>
                                </MyResponse>
                                <MyResponseText>
                                    {el.response}
                                </MyResponseText>
                                <InformationIcon>
                                    <img src={el.responseAvatar} alt="avatar"/>
                                    <p>{el.responseName}</p>
                                    <VectorIcon/>
                                    <p>{el.responseDate}</p>
                                </InformationIcon>
                            </MyResponseBlock>
                        )}
                        <InformationFooter>
                            {!isFormResponseVisible[index] && (
                                <p onClick={() => toggleFormResponseVisibility(index)}>
                                    Odpowiedz
                                </p>
                            )}
                            <DeleteIcon onClick={onClickHandler}/>
                        </InformationFooter>
                        {isFormResponseVisible[index] && (
                            <FormResponse>
                                <InformationTitleBlock>
                                    <InformationIcon>
                                        <img src={avatar3} alt="avatar3"/>
                                        <p>Justyna</p>
                                        <VectorIcon/>
                                        <p>28.02.2022</p>
                                    </InformationIcon>
                                    <InformationRate>
                                        <CloseIcon onClick={() => handleCloseFormResponse(index)}/>
                                    </InformationRate>
                                </InformationTitleBlock>
                                <InformationInput>
                                    <form action="#">
                                        <FormBlock>
                                            <p>Mój komentarz</p>
                                            <input type="text"/>
                                        </FormBlock>
                                        <FormBlockButton>
                                            <AdvertisementButton>Wyślij</AdvertisementButton>
                                        </FormBlockButton>
                                    </form>
                                </InformationInput>
                            </FormResponse>
                        )}
                    </InformationBlock>
                )
            })}
            {feedback.length > 5 && (
                <ShowAll onClick={() => setDisplayAllFeedbacks(!displayAllFeedbacks)}>
                    <p>{displayAllFeedbacks ? 'Ukryj opinie' : 'Pokaż wszystkie opinie'}</p>
                    <IconlyIcon style={{ transform: displayAllFeedbacks ? 'rotate(180deg)' : 'rotate(0deg)' }}></IconlyIcon>
                </ShowAll>
            )}

            <Popups
                show={showPopup}
                handleClosePopups={handleClosePopup}
                onClickClosest={onClickClosest}
                formComponent={<FormDelete handleClosePopups={handleClosePopup}/>}
            />
        </FeedbackInformationWrapper>
    );
};

