import styled from "styled-components";

export const StatisticsCoverWrap = styled.div`
  margin-top: 50px;
  border-radius: 5px;
  background: #F5F9FF;
  margin-bottom: 120px;
  @media (max-width: 768.98px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const StatisticsCoverContainer = styled.div`
  padding: 40px 0 50px 50px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media (max-width: 718.98px) {
    padding: 20px;
  }
  @media (max-width: 570.98px) {
    overflow-y: auto ;
  }
`;

export const StatisticsCoverTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
`;

export const StatisticsCoverBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 100px;
  @media (max-width: 718.98px) {
    gap: 15px;
  }

`;

export const StatisticsCoverItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ItemTitle = styled.p`
  color: rgba(12, 18, 21, 0.40);
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
`;

export const ItemSub = styled.p`
  color: #0C1215;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space:nowrap;
`;
export const ItemDate = styled.p`

`;
