import React, {FC} from 'react';
import {AddFreeDate} from "./add-free-date/AddFreeDate";
import {EditReservedDate} from "./edit-reserved-date/EditReservedDate";
import {DateWorkWrap} from "./style-date-work";

interface PropsType {
    handleCalendarClosePopups: () => void;
    showCalendarPopup: boolean;
}

export const DateWork: FC<PropsType> = ({ handleCalendarClosePopups, showCalendarPopup }) => {
    return (
        <DateWorkWrap>
            <AddFreeDate handleCalendarClosePopups={handleCalendarClosePopups} showCalendarPopup={showCalendarPopup} />
            <EditReservedDate handleCalendarClosePopups={handleCalendarClosePopups} showCalendarPopup={showCalendarPopup}/>
        </DateWorkWrap>
    );
};

