import React from 'react';
import {
    ItemDate,
    ItemSub,
    ItemTitle,
    StatisticsCoverBlock,
    StatisticsCoverContainer, StatisticsCoverItem,
    StatisticsCoverTitle,
    StatisticsCoverWrap
} from "./style-statistics-cover";

export const StatisticsCover = () => {
    return (
        <StatisticsCoverWrap>
            <StatisticsCoverContainer>
                <StatisticsCoverTitle>
                    Statystyki dotyczą poniższego okresu
                </StatisticsCoverTitle>
                <StatisticsCoverBlock>
                  <StatisticsCoverItem>
                      <ItemTitle>Data</ItemTitle>
                      <ItemSub>08.08.2022</ItemSub>
                      <ItemSub>14.08.2022</ItemSub>
                  </StatisticsCoverItem>
                    <StatisticsCoverItem>
                        <ItemTitle>Nazwa</ItemTitle>
                        <ItemSub>Dworek w Masłowie</ItemSub>
                        <ItemSub>Polecamy</ItemSub>
                    </StatisticsCoverItem>
                    <StatisticsCoverItem>
                        <ItemTitle>Okres</ItemTitle>
                        <ItemSub>od 08.08.2022 do 03.01.2023</ItemSub>
                        <ItemSub>od 14.08.2022 do 03.01.2023</ItemSub>
                    </StatisticsCoverItem>
                </StatisticsCoverBlock>
            </StatisticsCoverContainer>
        </StatisticsCoverWrap>
    );
};

