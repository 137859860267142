import React from 'react';
import {BannersWrap} from "./style-banners";
import {BannersPages} from "./banners-pages/BannersPages";
import {CertificatesPages} from "./certificates-pages/CertificatesPages";

export const Banners = () => {
    return (
        <BannersWrap>
            <BannersPages/>
            <CertificatesPages/>
        </BannersWrap>
    );
};

