import React, {useEffect} from 'react';
import {AutocompleteWrap} from "./style-autocomplete";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

interface IAutocomplete {
    isLoaded: boolean
    onSelect: (lat: number, lng: number) => void;
    setAddress: (value: string) => void;
    address:string;
}

export const Autocomplete = ({isLoaded, onSelect,setAddress,address}: IAutocomplete) => {
    const {
        ready,
        value,
        suggestions: {status, data},
        setValue,
        init,
        clearSuggestions,
    } = usePlacesAutocomplete({
        callbackName: "YOUR_CALLBACK_NAME",
        initOnMount: false,
        debounce: 300,
    });
    const ref = useOnclickOutside(() => {
        clearSuggestions();
    });

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Update the keyword of the input element
        setValue(e.target.value);
        setAddress(e.target.value)
    };

    const handleSelect = ({description}: { description: string }) => () => {
        // When user elements a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({address: description})
            .then((results) => {
                const {lat, lng} = getLatLng(results[0]);
                onSelect(lat,lng)
                console.log("📍 Coordinates: ", {lat, lng});
            });
    };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: {main_text, secondary_text},
            } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    useEffect(() => {
        if (isLoaded) {
            init()
        }
    }, [isLoaded, init])


    return (
        <AutocompleteWrap ref={ref}>
            <input
                type="text"
                value={address}
                onChange={handleInput}
                disabled={!ready}
                placeholder="Where are you going?"
            />
            {status === "OK" && <ul>{renderSuggestions()}</ul>}
        </AutocompleteWrap>
    );
};

//
// import React, {useEffect} from 'react';
// import {AutocompleteWrap} from "./style-autocomplete";
// import usePlacesAutocomplete, {
//     getGeocode,
//     getLatLng,
// } from "use-places-autocomplete";
// import useOnclickOutside from "react-cool-onclickoutside";
// import {InputBlock, InputItem} from "components/pages/presentation/form-presentation/style-form-presentation";
// import {StyledInput, StyledLabel} from "components/pages/account/login/account-form/form-login/style-form";
//
// interface IAutocomplete {
//     isLoaded: boolean;
//     onSelect: (lat: number, lng: number) => void;
//     register: any;
// }
//
// export const Autocomplete = ({ isLoaded, onSelect, register }: IAutocomplete) => {
//     const {
//         ready,
//         value,
//         suggestions: { status, data },
//         setValue,
//         init,
//         clearSuggestions,
//     } = usePlacesAutocomplete({
//         callbackName: "YOUR_CALLBACK_NAME",
//         initOnMount: false,
//         debounce: 300,
//     });
//
//     const ref = useOnclickOutside(() => {
//         clearSuggestions();
//     });
//
//     const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
//         setValue(e.target.value);
//
//     };
//
//     const handleSelect = ({ description }: { description: string }) => () => {
//         setValue(description, false);
//         clearSuggestions();
//
//         getGeocode({ address: description })
//             .then((results) => {
//                 const { lat, lng } = getLatLng(results[0]);
//                 onSelect(lat, lng);
//             });
//     };
//
//     const renderSuggestions = () =>
//         data.map((suggestion) => {
//             const {
//                 place_id,
//                 structured_formatting: { main_text, secondary_text },
//             } = suggestion;
//
//             return (
//                 <li key={place_id} onClick={handleSelect(suggestion)}>
//                     <strong>{main_text}</strong> <small>{secondary_text}</small>
//                 </li>
//             );
//         });
//
//     // useEffect(() => {
//     //     if (address) {
//     //         setValue(address);
//     //     }
//     // }, [address, setValue]);
//
//     useEffect(() => {
//         if (isLoaded) {
//             init();
//         }
//     }, [isLoaded, init]);
//
//     return (
//         <AutocompleteWrap ref={ref}>
//             <InputItem style={{ maxWidth: "100%", marginBottom: '18px' }}>
//                 <StyledLabel>Adres *</StyledLabel>
//                 <StyledInput
//                     type="text"
//                     disabled={!ready}
//                     placeholder="Dokąd idziesz?"
//                     {...register('address')}
//                 />
//             </InputItem>
//             {status === "OK" && <ul>{renderSuggestions()}</ul>}
//         </AutocompleteWrap>
//     );
// };
