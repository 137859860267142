import styled from "styled-components";



export const NameWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: end;
  cursor: pointer;
    
    p{
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: var(--white);
      @media (max-width: 1000.98px) {
        color: var(--black);
      }
    }
    
`