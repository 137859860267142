import styled from "styled-components";

export const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  @media (max-width: 498.98px) {
    flex-direction: column-reverse;
  gap: 30px;
  }
`


export const ButtonYear = styled.div`
  width: 292px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  color: var(--blue-main, #1056B2);
  text-align: center;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.22px;
  transition: all 0.2s;
  
  button {
    &:hover {
      opacity: 0.5;
    } 
  }
  @media (max-width: 552px) {
    width: 252px;
    align-self: center;
    gap: 30px;
  }
`;

export const ArchiveButtonWrap = styled.div`
width: 200px;
`
export const AddFreeDateWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px 30px;
  grid-auto-flow: dense;
  justify-content: center;
  padding-top: 30px;
  position: relative;
`

export const ButtonArchive = styled.div`
  display: flex;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 200px 100px 100px 0;
  border: 1px solid var(--border, #D2DAE3);
  opacity: 0.8;
  background: var(--white, #FFF);
  transition: opacity 0.2s ease-in-out;
  width: 490px;

  @media (max-width: 535.98px) {
    width: 350px;
  }
  

  span {
    color: var(--black, #0C1215);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

export const ButtonNewArchive = styled.div`
  display: flex;
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 200px 100px 100px 0;
  border: 1px solid var(--border, #D2DAE3);
  opacity: 0.8;
  background: var(--white, #FFF);
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  

  span {
    color: var(--black, #0C1215);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

