import styled, {keyframes} from "styled-components";

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

interface WeddingProps {
    show: boolean;
}

export const Popup = styled.div<WeddingProps>`
  display: ${(props) => (props.show ? "flex" : "none")};
  width: 100%;
  background: rgba(12, 18, 21, 0.4);
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  height: 100vh;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  animation: ${({show}) => (show ? fadeIn : fadeOut)} 0.3s forwards;
`;
