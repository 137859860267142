import styled from "styled-components";


export const ForgotContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 18px;
`;


export const ForgotLink = styled.div`
    padding-top: 18px;
`;


export const ForgotTextBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 416px;
`;

export const ForgotTitle = styled.h1`
    font-family: 'Gilroy', sans-serif;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #0c1215;
`;
export const ForgetText = styled.p`
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #0c1215;
    opacity: 0.5;
    line-height: 17px;
`;
