import React from 'react';
import {
    ButtonBlock,
    Container,
    LeftBlock,
    LeftButton,
    RightBlock,
    RightButton,
    Subtitle,
    Title
} from "./style-hall";


export const Hall = () => {
    return (
        <Container>
            <LeftBlock>
                <Title>Dworek w Masłowie</Title>
                <Subtitle>Sala weselna</Subtitle>
                <ButtonBlock>
                <LeftButton>Obejrzyj prezentację</LeftButton>
                </ButtonBlock>
            </LeftBlock>
            <RightBlock>
                <Title isLeft={true}>Ważność konta</Title>
                <Subtitle isLeft={true}>Twoje konto ważne jest do: 2023-01-31</Subtitle>
                <ButtonBlock>
                    <LeftButton>Odnów subskrypcję</LeftButton>
                    <RightButton>Zwiększ zasięg reklamy</RightButton>
                </ButtonBlock>
            </RightBlock>
        </Container>
    );
};