import React, {useEffect, useState} from 'react';
import {
    BlockWrap,
    FormBlock,
    FormPresentationWrap,
    FormTitle,
    InputBlockWrap,
    InputItem,
    QuestionSubmit,
    SubmitBlock,
    SubmitWrap
} from "components/pages/presentation/form-presentation/style-form-presentation";
import {
    ErrorText,
    Loader,
    StyledInput,
    StyledLabel
} from "components/pages/account/login/account-form/form-login/style-form";
import {
    ContactPersonWrap
} from "components/pages/informations/informations-forms/contact-person/contact-person-form/style-contact-person-form";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {ContactFormValues, contactPersonSchema} from "common/validation/information/contactPersonShema";
import {ReactComponent as CheckIcon} from '../../../../../../assets/main/presentation/check.svg';
import {
    MultimediaContact
} from "components/pages/informations/informations-forms/contact-person/multimedia-contact/multimediaContact";
import {message} from "antd";


interface ContactPersonProps {
    onSubmit: (handlerPerson: any) => void;
    data?: any;
    isLoading: boolean;
    contactEditError: any;
    contactEditIsError: any;
    contactEdit: any;
    contactLoading:boolean;
}

export const ContactPersonForm = (
    {
        onSubmit,
        data,
        isLoading,
        contactEditError,
        contactEditIsError,
        contactEdit,
        contactLoading
    }: ContactPersonProps) => {
    const [formData, setFormData] = useState<any | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState<any>({})

    const loader = isLoading || contactLoading;



    useEffect(() => {
        if (contactEditIsError) {
            const err = contactEditError as any;
            setErrorMessage(err);
            message.error(`Proszę poprawić błędy w formularzu`)
        } else {
            setErrorMessage({});
        }
    }, [contactEditError, contactEditIsError, contactEdit]);


    const {register, handleSubmit, setValue, formState: {errors}} = useForm<ContactFormValues>({
        resolver: zodResolver(contactPersonSchema),
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            profile_picture: null,
        },
    });

    useEffect(() => {
        if (data) {
            setFormData({
                first_name: data?.data.first_name,
                last_name: data?.data.last_name,
                email: data?.data.email,
                phone: data?.data.phone,
            });
        }
    }, [data]);

    useEffect(() => {
        if (formData) {
            setValue('first_name', formData.first_name);
            setValue('last_name', formData.last_name);
            setValue('email', formData.email);
            setValue('phone', formData.phone);
        }
    }, [formData, setValue]);

    useEffect(() => {
        if (data) {
            setValue('first_name', data.first_name);
            setValue('last_name', data.last_name);
            setValue('email', data.email);
            setValue('phone', data.phone);
            setValue('profile_picture', data.profile_picture);
        }
    }, [data, setValue]);

    const handleFormSubmit = (formValues: ContactFormValues) => {
        const formData = new FormData();
        formData.append('first_name', formValues.first_name);
        formData.append('last_name', formValues.last_name);
        formData.append('email', formValues.email);
        formData.append('phone', formValues.phone);

        if (imageFile) {
            formData.append('profile_picture', imageFile);
        }

        onSubmit(formData);
    };

    const handleImageChange = (file: File) => {
        setImageFile(file);
    };

    return (
        <FormPresentationWrap onSubmit={handleSubmit(handleFormSubmit)}>
            <FormBlock style={{paddingTop: '0'}}>
                <BlockWrap>
                    <FormTitle>Osoba do kontaktu</FormTitle>
                    <ContactPersonWrap>
                        <InputBlockWrap>
                            <InputItem>
                                <StyledLabel
                                    error={errors.first_name?.message || errorMessage?.data?.first_name}
                                    htmlFor="first_name"
                                >
                                    Imię*
                                </StyledLabel>
                                <StyledInput
                                    error={errors.first_name?.message || errorMessage?.data?.first_name}
                                    type="text"
                                    id="first_name"
                                    {...register('first_name')}
                                />
                                {(errors.first_name?.message || errorMessage?.data?.first_name) && (
                                    <ErrorText>{errors.first_name?.message || errorMessage?.data?.first_name}</ErrorText>
                                )}
                            </InputItem>
                            <InputItem>
                                <StyledLabel
                                    error={errors.first_name?.message || errorMessage?.data?.last_name}
                                    htmlFor="last_name"
                                >
                                    Nazwisko*
                                </StyledLabel>
                                <StyledInput
                                    error={errors.last_name?.message || errorMessage?.data?.last_name}
                                    type="text"
                                    id="last_name"
                                    {...register('last_name')}
                                />
                                {(errors.last_name?.message || errorMessage?.data?.last_name) && (
                                    <ErrorText>{errors.last_name?.message || errorMessage?.data?.last_name}</ErrorText>
                                )}
                            </InputItem>
                            <InputItem>
                                <StyledLabel
                                    error={errors.email?.message || errorMessage?.data?.email}
                                    htmlFor="email"
                                >
                                    E-mail kontaktowy*
                                </StyledLabel>
                                <StyledInput
                                    error={errors.email?.message || errorMessage?.data?.email}
                                    type="text"
                                    id="email"
                                    {...register('email')}
                                />
                                {(errors.email?.message || errorMessage?.data?.email) && (
                                    <ErrorText>{errors.email?.message || errorMessage?.data?.email}</ErrorText>
                                )}
                            </InputItem>
                            <InputItem>
                                <StyledLabel
                                    error={errors.phone?.message || errorMessage?.data?.phone}
                                    htmlFor="phone"
                                >
                                    Telefon*
                                </StyledLabel>
                                <StyledInput
                                    error={errors.phone?.message || errorMessage?.data?.phone}
                                    type="text"
                                    id="phone"
                                    placeholder="+48 XXX XXX XXX"
                                    {...register('phone')}
                                />
                                {(errors.phone?.message || errorMessage?.data?.phone) && (
                                    <ErrorText>{errors.phone?.message || errorMessage?.data?.phone}</ErrorText>
                                )}
                            </InputItem>
                        </InputBlockWrap>

                        <MultimediaContact loader={loader} data={data?.data} onImageChange={handleImageChange}/>

                    </ContactPersonWrap>
                </BlockWrap>
                <SubmitWrap>
                    <SubmitBlock>
                        <QuestionSubmit style={{marginLeft: 'auto'}} type="submit">
                            {loader ? <Loader/> : <CheckIcon/>}
                            <span>Zapisz</span>
                        </QuestionSubmit>
                    </SubmitBlock>
                </SubmitWrap>
            </FormBlock>
        </FormPresentationWrap>
    );
};
