import React, {useState} from 'react';
import {PasswordChangeType} from "services/auth/auth.types";
import {Password} from "components/pages/password-change/password/password";
import {usePasswordChangeMutation} from "services/auth/auth.service";
import {FullWrapper} from "components/layout/style-layout";
import {useMessageHandler} from "common/hook/useMessageHandler";
import {useNavigate} from "react-router-dom";

export interface MyFormValues {
    oldPassword: string;
    password: string;
    retypePassword: string;
}

export const PasswordChange: React.FC = () => {
    const navigate = useNavigate();

    const [passwordChange, {data, isLoading}] = usePasswordChangeMutation();


    const [showPassword, setShowPassword] = useState<{
        oldPassword: boolean;
        password: boolean;
        retypePassword: boolean;
    }>({
        oldPassword: false,
        password: false,
        retypePassword: false,
    });

    const togglePasswordVisibility = (field: keyof MyFormValues) => {
        setShowPassword((prevShowPassword) => ({
            ...prevShowPassword,
            [field]: !prevShowPassword[field],
        }));
    };

    if(data?.success) {
        navigate('/confirmation');
    }


    const handlerPassword = async (args: PasswordChangeType) => {
        try {
            await passwordChange(args)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <FullWrapper>
            <Password data={data}
                      onSubmit={handlerPassword}
                      showPassword={showPassword}
                      togglePasswordVisibility={togglePasswordVisibility}
                      isLoading={isLoading}/>
        </FullWrapper>
    )
};


// useMessageHandler(data, 'Hasło zostało pomyślnie zmienione', data?.error);
