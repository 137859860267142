import styled from "styled-components";
import {CertificateBlock} from "../certificates-pages/style-certificates-pages";

export const BannersPagesWrap = styled.section`
  display: flex;
  flex-direction: column;
`;

export const BannersPagesTitle = styled.p`
  color: #0C1215;
  font-family: Gilroy, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
`;

export const BannerItem = styled.div`
  max-width: 374px;
  display: flex;
  flex-direction: column;
`;

export const BannersPagesBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  max-width: 557px;
  margin-bottom: 30px;
 
`;

export const BannersPagesText = styled.p`
  color: #2E3B52;
  font-family: Gilroy,sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; 
`;

export const BannersBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(374px, 1fr));
  gap: 40px 20px;


  @media (max-width: 834.98px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 454.98px) {
    grid-template-columns: 1fr;
    margin: 0 auto;
  }
`;





export const BannersItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  max-width: 374px;
  height: 235px; 
  border-radius: 5px;
  border: 1px solid #D2DAE3;
  width: 100%;

  @media (max-width: 454.98px) {
    width: 350px;
  } 
  @media (max-width: 378.98px) {
    width: 330px;
  }
  

  img {
    width: 100%;
    height: 100%; 
    object-fit: cover; /* Maintain aspect ratio and cover the container */
  }
`;
