import {message} from "antd";

export const successUploadMessage = () => {
    message.success(`Plik  został pomyślnie dodany`);
};

export const errorUploadMessage = (errorMessage?: string) => {
    const messageText = errorMessage ? `Nie udało się dodać pliku: ${errorMessage}` : `Nie udało się dodać pliku`;
    message.error(messageText);
};


export const successDeleteMessage = () => {
    message.success(`Plik został pomyślnie usunięty`);
};

export const errorDeleteMessage = (isErrorDelete:any) => {
    message.error(`Nie udało się usunąć pliku: ${isErrorDelete}`);
};
