

import img2 from '../../../assets/main/abs/cart/two.png'
import img3 from '../../../assets/main/abs/cart/three.png'
import img4 from '../../../assets/main/abs/cart/one.png'
import img5 from '../../../assets/main/abs/cart/three.png'

import {v1} from "uuid";


interface IProduct {
    id: string;
    name: string;
    priceLocal: string;
    img: string;
    type: string;
    status: string;
    byStatus?: string;
}


export const AdsData: IProduct[] = [{
    id: v1(),
    name: 'Buty ślubne CINDRELLA',
    priceLocal: '399,00 zł',
    img: img3,
    type: 'buty',
    status: 'Zatwierdzone przez kierownika',

},
    {
        id: v1(),
        name: 'Elagancka suknia firmy Agnes LO 253',
        priceLocal: '600,00 zł',
        img: img2,
        type: 'suknie',
        status: 'Zatwierdzone przez kierownika',
        byStatus:"Sprzedany"
    },
    {
        id: v1(),
        name: 'Kwiatowa dekoracja',
        priceLocal: '106,00 zł',
        img: img3,
        type: 'dekoracje',
        status: 'Zatwierdzone przez kierownika',
        byStatus:"Zarezerwowane"
    },
    {
        id: v1(),
        name: 'Suknia firmy Agnes LO 115',
        priceLocal: '399,00 zł',
        img: img4,
        type: 'suknie',
        status: 'Zatwierdzone przez kierownika'
    },
    {
        id: v1(),
        name: 'Koło ścianka stelaż',
        priceLocal: '99,00 zł',
        img: img5,
        type: 'dekoracje',
        status: 'Zatwierdzone przez kierownika',
        byStatus:"Zarezerwowane"
    },
]

export const AdsDataThree: IProduct[] = [{
    id: v1(),
    name: 'Kwiatowa dekoracja',
    priceLocal: '600,00 zł',
    img: img3,
    type: 'buty',
    status: 'Zatwierdzone przez kierownika',

},
    {
        id: v1(),
        name: 'Buty ślubne CINDRELLA',
        priceLocal: '399,00 zł',
        img: img2,
        type: 'suknie',
        status: 'Zatwierdzone przez kierownika',
        byStatus:"Sprzedany"
    },
    {
        id: v1(),
        name: 'Elagancka suknia firmy Agnes LO 253',
        priceLocal: '499,00 zł',
        img: img3,
        type: 'dekoracje',
        status: 'Zatwierdzone przez kierownika',
        byStatus:"Zarezerwowane"
    },
]