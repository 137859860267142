
import Cookies from "universal-cookie";
import {baseApi} from "services/base-api";



const cookies = new Cookies();

const getToken = (): string => {
    const token = cookies.get('jwt_authorization');
    if (!token) {
        throw new Error('Токен авторизации не найден в куки');
    }
    return token;
}

export const getCompanyId = (): string => {
    const companyId = cookies.get('company_id');
    if (!companyId) {
        throw new Error('companyId не найден в куки');
    }
    return companyId;
}

export const multimediaPictureService = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        multimediaPicture: builder.query<any, void>({
            providesTags: ['MultimediaPicture'],
            query: () => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    method: 'GET',
                    url: `/company/${companyId}/picture/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        multimediaPictureAdd: builder.mutation<any, any>({
            invalidatesTags: ['MultimediaPicture'],
            query: (body) => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    body,
                    method: 'POST',
                    url: `/company/${companyId}/picture/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
        multimediaPictureDelete: builder.mutation<any, any>({
            invalidatesTags: ['MultimediaPicture'],
            query: () => {
                const token = getToken();
                const companyId = getCompanyId();
                return {
                    method: 'DELETE',
                    url: `/company/${companyId}/picture/`,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
            },
        }),
    }),
});

export const {
    useMultimediaPictureQuery,
    useMultimediaPictureAddMutation,
    useMultimediaPictureDeleteMutation,
} = multimediaPictureService;

