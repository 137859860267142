import styled from "styled-components";

export const ViewWrapper = styled.div`
  border-radius: 5px;
  background: #F5F9FF;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-flow: dense;
  justify-content: center;
  margin-top: 30px;
  position: relative;
  padding: 15px 20px 30px 20px;
`

export const StyledTitleCenter = styled.p`
  padding-top: 15px;
  color: var(--black, #0C1215);
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
`;


export const TableWrapper = styled.div`
  border-radius: 5px;
  background: #F5F9FF;
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  
  tr {
    border-bottom: 1px solid #ddd;
  }
  th,
  td {
    padding: 20px 10px;
    text-align: left;
  }

  @media screen and (max-width: 600px) {
    th,
    td {
      padding: 4px;
    }
  }
`;



export const StyledTableRow = styled.tr`
border: none;
`;

export const StyledTableHeader = styled.th`
  color: rgba(12, 18, 21, 0.50);
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;


export const TdContact = styled.td`
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 180px;
  color: #0C1215;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  span {
    opacity: 0.5;
  }
  div {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--blue-main, #1056B2);
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;


export const DateCell = styled.td`
  color: #0C1215;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px; 
  width: 63px;
  align-self: end;
  vertical-align: top;
`;

export const NameCell = styled.td`
  color: #0C1215;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
  width: 215px;
  vertical-align: top;
`;
export const ViewButtonWrap = styled.div`
  width: 100%;
  padding-top: 32px;
`
