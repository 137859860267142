import React from 'react';

import {ReactComponent as СrossIcon} from '../../../../../assets/main/form/cross.svg';
import {ReactComponent as CalendarTodayIcon} from '../../../../../assets/main/form/calendarToday.svg';
import {ReactComponent as Checkbox} from '../../../../../assets/main/form/checkbox.svg';
import {ReactComponent as CloseIcon} from '../../../../../assets/main/form/close.svg';
import {ReactComponent as TooltipsIcon} from '../../../../../assets/main/form/tooltips.svg';
import {
    CalendarTodayIconWrapper,
    DatePickerCustom,
    DatePickerCustomContainer,
    FormBlock,
    FormBlockTooltips,
    FormContainer,
    FormWrapper,
    StyledInput,
    StyledLabel,
    StyledTitle,
    StyleLabelTooltips
} from "../../login/account-form/form-login/style-form";

import {DatePickerProps, Input} from "antd";
import {
    DeleteBlock,
    FreeDateButtonBlock,
    FreeDateIcon,
    FreeDateLeftButton,
    FreeDateRightButton
} from "./style-add-free-date-form";
import {Tooltips} from "../../../../../common/tooltips/Tooltips";
import {CloseButton} from "../../../feedback/feedback-block/feedback-information/delete-popup/form-delete/form";


const {TextArea} = Input;

interface PropsType {
    handleClosePopups: () => void;
}


export const AddFreeDateForm: React.FC<PropsType> = ({handleClosePopups}) => {

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };


    return (
        <FormWrapper onClick={(e) => e.stopPropagation()}>
            <form>
                <CloseButton type="button" onClick={handleClosePopups}>
                    <СrossIcon/>
                </CloseButton>
                <StyledTitle>Dodaj wolny termin</StyledTitle>
                <FormContainer>
                    <FormBlock>
                        <StyledLabel>Data</StyledLabel>
                        <DatePickerCustomContainer>
                            <DatePickerCustom
                                // onChange={onChange}
                            />
                            <CalendarTodayIconWrapper>
                                <CalendarTodayIcon/>
                            </CalendarTodayIconWrapper>
                        </DatePickerCustomContainer>
                    </FormBlock>
                    <FormBlockTooltips>
                        <StyleLabelTooltips>
                            <StyledLabel htmlFor="advance">Zaliczka za termin last minute</StyledLabel>
                            <Tooltips title='Jeśli chcesz wyłączyć możliwość rezerwacji, ustaw kwotę zaliczki na 0zł'>
                                <TooltipsIcon/>
                            </Tooltips>
                        </StyleLabelTooltips>
                        <StyledInput
                            type="text"
                            name="advance"
                        />
                    </FormBlockTooltips>
                    <FormBlockTooltips>
                        <StyleLabelTooltips>
                            <StyledLabel htmlFor="advance">Dodaj notatkę</StyledLabel>
                            <Tooltips title='Dodaj notatkę'>
                                <TooltipsIcon/>
                            </Tooltips>
                        </StyleLabelTooltips>
                        <TextArea
                            autoSize={{minRows: 5, maxRows: 10}}
                        />
                    </FormBlockTooltips>

                    <FreeDateButtonBlock>
                        <FreeDateLeftButton>
                            <FreeDateIcon>
                                <CloseIcon/>
                                <span>Anuluj</span>
                            </FreeDateIcon>
                        </FreeDateLeftButton>
                        <FreeDateRightButton>
                            <FreeDateIcon>
                                <Checkbox/>
                                <span>Zapisz</span>
                            </FreeDateIcon>
                        </FreeDateRightButton>
                    </FreeDateButtonBlock>
                    <DeleteBlock>
                        <p>Usuń</p>
                    </DeleteBlock>
                </FormContainer>
            </form>
            {/*<Popups*/}
            {/*    show={showPopup}*/}
            {/*    handleClosePopups={handleClosePopup}*/}
            {/*    onClickClosest={onClickClosest}*/}
            {/*    formComponent={<DeleteBookedTerm handleClosePopups={handleClosePopup}/>}*/}
            {/*/>*/}

        </FormWrapper>
    );
};
